//import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import SccTranslate from './scc-translate.js';
import noUiSlider from 'nouislider/dist/nouislider.min.js';
import TomSelect from 'tom-select';
import flatpickr from 'flatpickr';
import { createPopper } from '@popperjs/core';
import phoneFormat from '@ararif/phone-format-js';

// "use strict";
// Base64 script - helps encoding unicode characters
( function( global, factory ) {
	typeof exports === 'object' && typeof module !== 'undefined'
		? ( module.exports = factory( global ) )
		: typeof define === 'function' && define.amd
			? define( factory )
			: factory( global );
}(
	typeof self !== 'undefined'
		? self
		: typeof window !== 'undefined'
			? window
			: typeof global !== 'undefined'
				? global
				: this,
	function( global ) {
		'use strict';
		global = global || {};
		const _Base64 = global.Base64;
		const version = '2.5.2';
		let buffer;
		if ( typeof module !== 'undefined' && module.exports ) {
			try {
				const requireFunc = typeof __non_webpack_require__ === 'function' ? __non_webpack_require__ : require;
				buffer = requireFunc( 'buffer' ).Buffer;
			} catch ( err ) {
				buffer = undefined;
			}
		}
		const b64chars =
			'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
		const b64tab = ( function( bin ) {
			const t = {};
			for ( let i = 0, l = bin.length; i < l; i++ ) {
				t[ bin.charAt( i ) ] = i;
			}
			return t;
		}( b64chars ) );
		const fromCharCode = String.fromCharCode;
		const cb_utob = function( c ) {
			if ( c.length < 2 ) {
				var cc = c.charCodeAt( 0 );
				return cc < 128
					? c
					: cc < 2048
						? fromCharCode( 192 | ( cc >>> 6 ) ) + fromCharCode( 128 | ( cc & 63 ) )
						: fromCharCode( 224 | ( ( cc >>> 12 ) & 15 ) ) +
						fromCharCode( 128 | ( ( cc >>> 6 ) & 63 ) ) +
						fromCharCode( 128 | ( cc & 63 ) );
			}
			var cc =
					65536 + ( c.charCodeAt( 0 ) - 55296 ) * 1024 + ( c.charCodeAt( 1 ) - 56320 );
			return (
				fromCharCode( 240 | ( ( cc >>> 18 ) & 7 ) ) +
					fromCharCode( 128 | ( ( cc >>> 12 ) & 63 ) ) +
					fromCharCode( 128 | ( ( cc >>> 6 ) & 63 ) ) +
					fromCharCode( 128 | ( cc & 63 ) )
			);
		};
		const re_utob = /[\uD800-\uDBFF][\uDC00-\uDFFFF]|[^\x00-\x7F]/g;
		const utob = function( u ) {
			return u.replace( re_utob, cb_utob );
		};
		const cb_encode = function( ccc ) {
			const padlen = [ 0, 2, 1 ][ ccc.length % 3 ],
				ord =
					( ccc.charCodeAt( 0 ) << 16 ) |
					( ( ccc.length > 1 ? ccc.charCodeAt( 1 ) : 0 ) << 8 ) |
					( ccc.length > 2 ? ccc.charCodeAt( 2 ) : 0 ),
				chars = [
					b64chars.charAt( ord >>> 18 ),
					b64chars.charAt( ( ord >>> 12 ) & 63 ),
					padlen >= 2 ? '=' : b64chars.charAt( ( ord >>> 6 ) & 63 ),
					padlen >= 1 ? '=' : b64chars.charAt( ord & 63 ),
				];
			return chars.join( '' );
		};
		const btoa = global.btoa
			? function( b ) {
				return global.btoa( b );
			}
			: function( b ) {
				return b.replace( /[\s\S]{1,3}/g, cb_encode );
			};
		const _encode = function( u ) {
			const isUint8Array =
				Object.prototype.toString.call( u ) === '[object Uint8Array]';
			return isUint8Array ? u.toString( 'base64' ) : btoa( utob( String( u ) ) );
		};
		const encode = function( u, urisafe ) {
			return ! urisafe
				? _encode( u )
				: _encode( String( u ) )
					.replace( /[+\/]/g, function( m0 ) {
						return m0 == '+' ? '-' : '_';
					} )
					.replace( /=/g, '' );
		};
		const encodeURI = function( u ) {
			return encode( u, true );
		};
		const re_btou =
			/[\xC0-\xDF][\x80-\xBF]|[\xE0-\xEF][\x80-\xBF]{2}|[\xF0-\xF7][\x80-\xBF]{3}/g;
		const cb_btou = function( cccc ) {
			switch ( cccc.length ) {
				case 4:
					var cp =
						( ( 7 & cccc.charCodeAt( 0 ) ) << 18 ) |
						( ( 63 & cccc.charCodeAt( 1 ) ) << 12 ) |
						( ( 63 & cccc.charCodeAt( 2 ) ) << 6 ) |
						( 63 & cccc.charCodeAt( 3 ) ),
						offset = cp - 65536;
					return (
						fromCharCode( ( offset >>> 10 ) + 55296 ) +
						fromCharCode( ( offset & 1023 ) + 56320 )
					);
				case 3:
					return fromCharCode(
						( ( 15 & cccc.charCodeAt( 0 ) ) << 12 ) |
						( ( 63 & cccc.charCodeAt( 1 ) ) << 6 ) |
						( 63 & cccc.charCodeAt( 2 ) ),
					);
				default:
					return fromCharCode(
						( ( 31 & cccc.charCodeAt( 0 ) ) << 6 ) | ( 63 & cccc.charCodeAt( 1 ) ),
					);
			}
		};
		const btou = function( b ) {
			return b.replace( re_btou, cb_btou );
		};
		const cb_decode = function( cccc ) {
			const len = cccc.length,
				padlen = len % 4,
				n =
					( len > 0 ? b64tab[ cccc.charAt( 0 ) ] << 18 : 0 ) |
					( len > 1 ? b64tab[ cccc.charAt( 1 ) ] << 12 : 0 ) |
					( len > 2 ? b64tab[ cccc.charAt( 2 ) ] << 6 : 0 ) |
					( len > 3 ? b64tab[ cccc.charAt( 3 ) ] : 0 ),
				chars = [
					fromCharCode( n >>> 16 ),
					fromCharCode( ( n >>> 8 ) & 255 ),
					fromCharCode( n & 255 ),
				];
			chars.length -= [ 0, 0, 2, 1 ][ padlen ];
			return chars.join( '' );
		};
		const _atob = global.atob
			? function( a ) {
				return global.atob( a );
			}
			: function( a ) {
				return a.replace( /\S{1,4}/g, cb_decode );
			};
		const atob = function( a ) {
			return _atob( String( a ).replace( /[^A-Za-z0-9\+\/]/g, '' ) );
		};
		const _decode = buffer
			? buffer.from && Uint8Array && buffer.from !== Uint8Array.from
				? function( a ) {
					return (
						a.constructor === buffer.constructor
							? a
							: buffer.from( a, 'base64' )
					).toString();
				}
				: function( a ) {
					return (
						a.constructor === buffer.constructor ? a : new buffer( a, 'base64' )
					).toString();
				}
			: function( a ) {
				return btou( _atob( a ) );
			};
		const decode = function( a ) {
			return _decode(
				String( a )
					.replace( /[-_]/g, function( m0 ) {
						return m0 == '-' ? '+' : '/';
					} )
					.replace( /[^A-Za-z0-9\+\/]/g, '' ),
			);
		};
		const noConflict = function() {
			const Base64 = global.Base64;
			global.Base64 = _Base64;
			return Base64;
		};
		global.Base64 = {
			VERSION: version,
			atob,
			btoa,
			fromBase64: decode,
			toBase64: encode,
			utob,
			encode,
			encodeURI,
			btou,
			decode,
			noConflict,
			__buffer__: buffer,
		};
		if ( typeof Object.defineProperty === 'function' ) {
			const noEnum = function( v ) {
				return {
					value: v,
					enumerable: false,
					writable: true,
					configurable: true,
				};
			};
			global.Base64.extendString = function() {
				Object.defineProperty(
					String.prototype,
					'fromBase64',
					noEnum( function() {
						return decode( this );
					} ),
				);
				Object.defineProperty(
					String.prototype,
					'toBase64',
					noEnum( function( urisafe ) {
						return encode( this, urisafe );
					} ),
				);
				Object.defineProperty(
					String.prototype,
					'toBase64URI',
					noEnum( function() {
						return encode( this, true );
					} ),
				);
			};
		}
		if ( global.Meteor ) {
			Base64 = global.Base64;
		}
		if ( typeof module !== 'undefined' && module.exports ) {
			module.exports.Base64 = global.Base64;
		} else if ( typeof define === 'function' && define.amd ) {
			define( [], function() {
				return global.Base64;
			} );
		}
		return { Base64: global.Base64 };
	},
) );
const itemsTotal = [];
const itemsTotal2 = [];
const elementDate = [];
const elementDistance = [];
const elementsTotal = [];
const elementsComment = [];
const variableMathElement = [];
const customMath = [];
const Sliders = [];
const itemsArray = []; //for detail
const sortedItemsArray = [];
let mandatoryElements = [];
const globalMathArray = [];

const customJs = [];
const sccFrontendAssets = {
	svgs: {
		spinner: `<div>
		<svg aria-hidden="true" style="width: 1em" focusable="false" data-prefix="fas" data-icon="spinner" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-spinner fa-w-16 fa-spin"><path fill="currentColor" d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z" class=""></path></svg>
		</div>`,
	},
};

async function initializeScc( updateConditionalLogicSetupFields = false ) {
	// The spinner will show maximum two seconds
	setTimeout( () => {
		sccShowCalculator();
	}, 2000 );
	return new Promise( async ( resolve, reject ) => {
		try {
			const calcWrappers = document.querySelectorAll( '.calc-wrapper' );

			for ( const element of calcWrappers ) {
				const formId = element.getAttribute( 'id' ).replace( 'scc_form_', '' );
				const sccConfig = JSON.parse(
					document.getElementById( 'scc-config-' + formId ).textContent,
				);
				await initializeFuncScc( updateConditionalLogicSetupFields, sccConfig, formId, element );
			}

			const event = new Event( 'sccPostInit' );
			document.body.dispatchEvent( event );
			resolve();
		} catch ( error ) {
			reject( error );
		}
	} );
}
window.initializeScc = initializeScc;

function initializeFuncScc( updateConditionalLogicSetupFields = false, sccConfig, formId, element ) {
	if ( typeof window.sccData === 'undefined' ) {
		window.sccData = [];
	}
	if ( typeof sccData[ formId ] === 'undefined' ) {
		sccData[ formId ] = {};
	}
	itemsTotal[ formId ] = [];
	itemsTotal2[ formId ] = [];
	elementDate[ formId ] = [];
	elementDistance[ formId ] = [];
	elementsTotal[ formId ] = [];
	elementsComment[ formId ] = [];
	variableMathElement[ formId ] = [];
	customMath[ formId ] = [];
	Sliders[ formId ] = [];
	itemsArray[ formId ] = [];
	sortedItemsArray[ formId ] = [];
	globalMathArray[ formId ] = [];
	// parse and store config to data object

	sccData[ formId ].config = sccConfig;

	if ( updateConditionalLogicSetupFields ) {
		cal = getElmtsNCndns( sccData[ formId ].config.sections );
	}
	if ( ! updateConditionalLogicSetupFields && ( typeof isInsideEditingPage !== 'undefined' && isInsideEditingPage() ) ) {
		sccBackendStore.config = sccConfig;
		sccBackendStore.config.sccAvailableElements = sccBackendStore.config.sections
			.map( ( e, i ) =>
				sccBackendStore.config.sections[ i ].subsection.map( ( e ) => {
					e.element.forEach( ( eq ) => {
						eq.sectionName = sccBackendStore.config.sections[ i ].name;
						eq.parentSectionId = sccBackendStore.config.sections[ i ].id;
						if (
							sccBackendStore.config.sections.findIndex(
								( ez ) => ez.sectionId == eq.sectionId,
							) < 0
						) {
							sccBackendStore.config.sections.push( {
								sectionName: eq.sectionName,
								sectionId: eq.parentSectionId,
							} );
						}
					} );
					return e.element;
				} ),
			)
			.flat()
			.flat()
			.filter( ( e ) => ( ! [ 'math', 'custom-math' ].includes( e.type ) ) );
		const checkboxesAvailable = sccBackendStore.config.sccAvailableElements.filter(
			( e ) => e.type == 'checkbox',
		);
		checkboxesAvailable.forEach( ( checkboxItem, index ) => {
			/* Including the checkbox item from the available elements search,
				* as the ID can match against non-checkbox items
				* due to the elements are stored in a separate tables and the ID is sequential
				*/
			const targetIndex = sccBackendStore.config.sccAvailableElements.findIndex(
				( e ) => ( e.id == checkboxItem.id ) && ( e.type === 'checkbox' ),
			);
			const element = sccBackendStore.config.sccAvailableElements[ targetIndex ];
			element.elementitems.forEach( ( item ) => {
				item.parentSectionId = element.parentSectionId;
				item.subSectionIndex = element.subSectionIndex;
				item.sectionName = element.sectionName;
				item.type = 'checkbox-child';
			} );
			sccBackendStore.config.sccAvailableElements = [
				...sccBackendStore.config.sccAvailableElements.slice( 0, targetIndex ),
				...element.elementitems,
				...sccBackendStore.config.sccAvailableElements.slice( targetIndex + 1 ),
			];
		} );
	}
	if ( sccData[ formId ].config?.enableStripe ) {
		const stripeScript = document.createElement( 'script' );
		stripeScript.src = 'https://js.stripe.com/v3/';
		document.body.appendChild( stripeScript );
		stripeScript.onload = function() {
			window.stripe = Stripe( sccData[ formId ].config?.stripePubKey );
		};
	}
	if ( parseInt( sccData[ formId ].config.showSearchBar ) ) {
		sccData[ formId ].sections = [];
		sccData[ formId ].sccAvailableElements = sccData[ formId ].config.sections
			.map( ( e, i ) =>
				sccData[ formId ].config.sections[ i ].subsection.map( ( e ) => {
					e.element.forEach( ( eq ) => {
						eq.sectionName = sccData[ formId ].config.sections[ i ].name;
						eq.parentSectionId = sccData[ formId ].config.sections[ i ].id;
						if (
							sccData[ formId ].sections.findIndex(
								( ez ) => ez.sectionId == eq.sectionId,
							) < 0
						) {
							sccData[ formId ].sections.push( {
								sectionName: eq.sectionName,
								sectionId: eq.parentSectionId,
							} );
						}
					} );
					return e.element;
				} ),
			)
			.flat()
			.flat();
		const checkboxesAvailable = sccData[ formId ].sccAvailableElements.filter(
			( e ) => e.type == 'checkbox',
		);
		checkboxesAvailable.forEach( ( checkboxItem, index ) => {
			/* Including the checkbox item from the available elements search,
			* as the ID can match against non-checkbox items
			* due to the elements are stored in a separate tables and the ID is sequential
			*/
			const targetIndex = sccData[ formId ].sccAvailableElements.findIndex(
				( e ) => ( e.id == checkboxItem.id ) && ( e.type === 'checkbox' ),
			);
			const element = sccData[ formId ].sccAvailableElements[ targetIndex ];
			element.elementitems.forEach( ( item ) => {
				item.parentSectionId = element.parentSectionId;
				item.subSectionIndex = element.subSectionIndex;
				item.sectionName = element.sectionName;
				item.type = 'checkbox-child';
			} );
			sccData[ formId ].sccAvailableElements = [
				...sccData[ formId ].sccAvailableElements.slice( 0, targetIndex ),
				...element.elementitems,
				...sccData[ formId ].sccAvailableElements.slice( targetIndex + 1 ),
			];
		} );
		const searchBar = element.querySelector( '#nav-to-element' );
		const tomSelectInstance = new TomSelect( searchBar, {
			allowEmptyOption: true,
			create: false,
			maxOptions: 'null',
			options: sccData[ formId ].sccAvailableElements.map( ( e, i ) => {
				return {
					name: e.name ? e.name : e.titleElement,
					parentSectionId: e.parentSectionId,
					sectionName: e.sectionName,
					elementId: e.type == 'checkbox-child' ? 'chkbx-' + e.id : e.id,
					type: e.type,
					value: e.type == 'checkbox-child' ? 'chkbx-' + e.id : e.id,
				};
			} ),
			optgroupField: 'parentSectionId',
			optgroups: sccData[ formId ].sections.map( ( e ) => ( {
				value: e.sectionId,
				label: e.sectionName,
			} ) ),
			render: {
				optgroup_header( data, escape ) {
					return (
						'<div class="optgroup-header" style="color: blue">' +
							escape( data.label ) +
							'</div>'
					);
				},
			},
			labelField: 'name',
			valueField: 'elementId',
			onChange: ( data ) => {
				const calcWrapper = document.getElementById( `scc_form_${ formId }` );
				let isCheckbox = false;
				if ( data.search( 'chkbx-' ) === 0 ) {
					isCheckbox = true;
				}
				if ( ! isCheckbox ) {
					const targetNodeId = `ssc-elmt-${ data }`;
					const scrollTarget = calcWrapper.querySelector(
						`[id="${ targetNodeId }"]`,
					);
					window.scrollTo( 0, sccGetOffset( scrollTarget ).top - 100 );
					if ( window.self !== window.top ) {
						window.parent.postMessage( { action: 'scroll', top: ( sccGetOffset( scrollTarget ).top - 100 ) }, '*' );
					}
				}
				if ( isCheckbox ) {
					const targetNodeId = data.replace( 'chkbx-', '' );
					const scrollTarget = calcWrapper
						.querySelector( `[data-itemid="${ targetNodeId }"]` )
						.closest( '.scc-form-field-item' );
					window.scrollTo( 0, sccGetOffset( scrollTarget ).top - 100 );
					if ( window.self !== window.top ) {
						window.parent.postMessage( { action: 'scroll', top: ( sccGetOffset( scrollTarget ).top - 100 ) }, '*' );
					}
				}
			},
		} );
	}
	sccData[ formId ].totalConditions = sccUtils.getTotalConditions( formId );
	sccData[ formId ].sectionsWithPageBreaks =
			sccUtils.getSectionsWithPageBreaks( formId );
	sccData[ formId ].variableMathElements =
			sccUtils.getVariableMathWithElements( formId );
	    // Replacing eval with a safer method
	const funcName = 'sccPostInit' + formId;
	const func = window[ funcName ];
	if ( func && typeof func === 'function' ) {
		func();
	} else {
		console.warn( funcName + ' is not defined' ); // This will log if the function is not defined
	}

	// Handles currency conversion rate
	Object.keys( sccData ).forEach( ( calcId ) => {
		sccUtils.updateCurrencyRates( calcId );
	} );

	// handle minimum total
	getMinimumTotal( formId );

	// Translates what is in the tanslate column in database
	Object.keys( sccData ).forEach( ( calcId ) => {
		getTranslation_( calcId );
	} );

	// Handles mandatory feature of elements
	mandatoryElements = mandatory_elements();

	window.addEventListener( 'load', () => sccShowCalculator() );

	/* 	const sccGoogleApiLoaded = document.querySelector( '.scc-google-api-loaded-' + formId );
	if ( sccGoogleApiLoaded ) {
		sccInitializeGoogleMaps();
	} */
}

const sccUtils = {
	getTotalConditions: ( calcId ) => {
		const availableConditions = sccUtils.getAllConditions( calcId );
		const toalConditions = availableConditions.filter( ( e ) => {
			let parsed = false;
			try {
				parsed = JSON.parse( e.value );
			} catch ( error ) {
				return false;
			}
			if ( parsed?.calctotal ) {
				e.parsedValue = parsed.calctotal;
				return true;
			}
			return false;
		} );
		return toalConditions;
	},
	multiplyBasedOnConfig: ( number, config ) => {
		let result = number;
		const configSets = [];
		for ( let i = 0; i < config.length; i += 3 ) {
			configSets.push( config.slice( i, i + 3 ) );
		}
		const configIndex = configSets.findIndex( ( z ) => ( number >= z[ 0 ] && number <= z[ 1 ] ) );
		if ( configIndex > 0 ) {
			// strip the further indexed items from the configSets
			configSets.splice( configIndex + 1 );
			// calculation returned from the configsets excluding the current one, by the upper limit of the sets
			const calculationResults = configSets.map( ( e, i ) => {
				const upperRange = e[ 1 ];
				const lowerRange = e[ 0 ];
				const previousConfig = configSets[ i - 1 ];
				const previousConfigUpperLimit = previousConfig ? previousConfig[ 1 ] : 0;
				const currentNumber = ( number >= lowerRange && number <= upperRange ) ? number : upperRange;
				return ( currentNumber - previousConfigUpperLimit ) * e[ 2 ];
			} );
			const sum = calculationResults.reduce( ( acc, curr ) => acc + curr, 0 );
			result = sum;
			return result;
		}
		if ( configIndex === 0 ) {
			const factor = configSets[ configIndex ][ 2 ];
			result *= factor;
		}
		return result;
	},
	countExcludedDaysInDatePicker: ( start, end, datesExcluded, excludedDays = [] ) => {
		// prevent mutation of the start and end dates
		start = new Date( start );
		end = new Date( end );
		const dayToNumber = {
			Sun: 0,
			Mon: 1,
			Tue: 2,
			Wed: 3,
			Thu: 4,
			Fri: 5,
			Sat: 6,
		};
		let numberOfExcludedDays = 0;
		const datesExcludedUnix = datesExcluded.map( ( date ) => new Date( date ).getTime() );
		// Loop through each date in the range
		while ( start <= end ) {
			if ( datesExcludedUnix.includes( start.getTime() ) ) {
				numberOfExcludedDays++;
			}
			// increase the count of the day of the week
			switch ( start.getDay() ) {
				case ( dayToNumber.Sun ):
					if ( excludedDays.includes( 'Sun' ) ) {
						numberOfExcludedDays++;
					}
					break;
				case dayToNumber.Mon:
					if ( excludedDays.includes( 'Mon' ) ) {
						numberOfExcludedDays++;
					}
					break;
				case dayToNumber.Tue:
					if ( excludedDays.includes( 'Tue' ) ) {
						numberOfExcludedDays++;
					}
					break;
				case dayToNumber.Wed:
					if ( excludedDays.includes( 'Wed' ) ) {
						numberOfExcludedDays++;
					}
					break;
				case dayToNumber.Thu:
					if ( excludedDays.includes( 'Thu' ) ) {
						numberOfExcludedDays++;
					}
					break;
				case dayToNumber.Fri:
					if ( excludedDays.includes( 'Fri' ) ) {
						numberOfExcludedDays++;
					}
					break;
				case dayToNumber.Sat:
					if ( excludedDays.includes( 'Sat' ) ) {
						numberOfExcludedDays++;
					}
					break;
			}
			// Move to the next day
			start.setDate( start.getDate() + 1 );
		}
		return {
			numberOfExcludedDays,
		};
	},
	analyzeConditions: ( data, commonDependency, calcId ) => {
		// Group data by targetElementId and conditionSet
		const groupedBySet = data.reduce( ( acc, curr ) => {
			const key = `${ curr.targetElementId }_${ curr.conditionSet }`;
			if ( ! acc[ key ] ) {
				acc[ key ] = [];
			}
			acc[ key ].push( curr );
			return acc;
		}, {} );

		// Group data just by targetElementId
		const groupedById = data.reduce( ( acc, curr ) => {
			if ( ! acc[ curr.targetElementId ] ) {
				acc[ curr.targetElementId ] = [];
			}
			acc[ curr.targetElementId ].push( curr );
			return acc;
		}, {} );

		const conditionsMet = [];
		const conditionsNotMet = [];

		for ( const id in groupedById ) {
			const setsMet = Object.keys( groupedBySet ).filter( ( key ) => key.startsWith( id + '_' ) ).map( ( key ) => groupedBySet[ key ].every( ( cond ) => cond.conditionMet ) );
			if ( setsMet.includes( true ) ) {
				conditionsMet.push( id );
			} else {
				conditionsNotMet.push( id );
			}
		}

		const postConditionalLogicSectionState = sccUtils.getAllSectionsAndChildItems( calcId ).map( ( { sectionId, itemIds } ) => {
			// Diff the itemIds with conditionsNotMet, if they are the same, then all items in the section are hidden
			const diff = itemIds.filter( ( x ) => ! conditionsNotMet.includes( x ) );
			return { sectionId, action: diff.length === 0 ? 'hide' : 'show' };
		} );
		return { conditionsMet, conditionsNotMet, postConditionalLogicSectionState };
	},
	getAllSectionsAndChildItems: ( calcId ) => {
		return sccData[ calcId ].config.sections.map( ( section ) => ( { sectionId: section.id, itemIds: section.subsection.map( ( subsection ) => subsection.element.map( ( element ) => element.id ) ).flat() } ) );
	},
	getAllConditions: ( calcId ) => {
		const availableConditions = sccData[ calcId ].config.sections
			.map( ( e ) =>
				e.subsection.map( ( ee ) => ee.element.map( ( eee ) => eee.conditions ) ),
			)
			.flat()
			.flat()
			.flat();
		return availableConditions;
	},
	getTypeAndQualifierValue: ( item, operator = null ) => {
		const conditionType = operator ? sccUtils.detectConditionType( operator ) : '';
		let detectedType;
		if ( item.hasOwnProperty( 'type' ) ) {
			detectedType = item.type;
		}
		if ( item.hasOwnProperty( 'dropdownTitle' ) ) {
			detectedType = 'dropdown';
		}
		let qualifierValue;
		switch ( detectedType ) {
			case 'slider':
			case 'quantityInput':
			case 'distance':
			case 'qtn-image':
				qualifierValue = item.qtn;
				if ( conditionType === 'cost' ) {
					qualifierValue = item.item_total_value;
				}
				break;
			case 'dropdown':
				qualifierValue = item.id_elementItem;
				break;
			case 'date range':
				qualifierValue = item.qtn;
				if ( conditionType === 'cost' ) {
					qualifierValue = item.item_total_value;
				}
				if ( conditionType === 'date' ) {
					const description = item.description;
					const dateRegex = /(\d{2}-\d{2}-\d{4}|\d{4}-\d{2}-\d{2}|\d{2}-\d{2}-\d{2}).*?(\d{2}-\d{2}-\d{4}|\d{4}-\d{2}-\d{2}|\d{2}-\d{2}-\d{2})/;
					const matches = description.match( dateRegex );
					let dates = null;
					if ( matches ) {
						dates = {
							startDate: matches[ 1 ] || null,
							endDate: matches[ 2 ] || null,
						};
					} else {
					}
					qualifierValue = dates;
				}
				break;
			case 'date':
				qualifierValue = item.description;
				break;
			default:
				qualifierValue = item.item_total_value;
				break;
		}
		return { qualifierValue, detectedType };
	},
	getComparisonValue: ( condition, detectedType ) => {
		let comparisonValue;
		switch ( detectedType ) {
			case 'slider':
			case 'quantityInput':
			case 'date range':
			case 'date':
			case 'distance':
			case 'qtn-image':
				comparisonValue = condition.value;
				break;
			case 'dropdown':
				comparisonValue = condition.elementitem_id;
				break;
			default:
				comparisonValue = condition.item_total_value;
				break;
		}
		return comparisonValue;
	},
	getConditionDependencyElementOrElementItemId: ( condition, isCheckBoxCondition = false, isQtnImageBtn = false ) => {
		if ( isQtnImageBtn ) {
			return 'qtn-image-btn-' + condition.elementitem_id;
		}
		return isCheckBoxCondition ? 'chkbx-' + condition.elementitem_id : condition.condition_element_id;
	},
	findChainDependency: ( start, dataArray ) => {
		let chain = [];

		for ( const obj of dataArray ) {
			if ( obj[ start ] ) {
				chain.push( obj[ start ] );
				chain = chain.concat( sccUtils.findChainDependency( obj[ start ], dataArray ) );
				break; // As we are only considering one match for each key
			}
		}

		return chain;
	},
	getComparisonElementIdByItem: ( item ) => {
		return item.hasOwnProperty( 'element_id' ) ? 'element_id' : 'id_element';
	},
	evaluateConditions: ( calcId, items, totalValueBeforeConditionCheck ) => {
		const allConditions = sccUtils.getAllConditions( calcId );
		const formatDate = sccData[ calcId ]?.pdf?.dateFormat ?? 'yyyy-mm-dd';
		const compareValues = ( a, b, operator, elementType = null ) => {
			//Validate if the operator is a date condition
			let aDate = '';
			let bDate = '';
			let bDateStartRange = '';
			let bDateEndRange = '';
			if ( elementType === 'date' ) {
				const conditionType = sccUtils.detectConditionType( operator );
				if ( conditionType === 'date' ) {
					aDate = new Date( a ).getTime();
					bDate = new Date( sccStandardDateFormat( b, formatDate ) ).getTime();
				}
			}
			if ( elementType === 'date range' ) {
				const conditionType = sccUtils.detectConditionType( operator );
				if ( conditionType === 'date' ) {
					aDate = new Date( a ).getTime();
					bDateStartRange = new Date( sccStandardDateFormat( b.startDate, formatDate ) ).getTime();
					bDateEndRange = new Date( sccStandardDateFormat( b.endDate, formatDate ) ).getTime();
				}
			}
			a = parseFloat( a );
			b = parseFloat( b );

			switch ( operator ) {
				case 'eq':
				case 'eq_cost':
					return a == b;
				case 'ne':
				case 'ne_cost':
					return a !== b;
				case 'gr':
				case 'gr_cost':
					return a < b;
				case 'le':
				case 'le_cost':
				case 'les':
					return a > b;
				case 'eq_date':
					let eqDate = false;
					if ( elementType === 'date range' ) {
						eqDate = aDate >= bDateStartRange && aDate <= bDateEndRange;
					}
					if ( elementType === 'date' ) {
						eqDate = aDate === bDate;
					}
					return eqDate;
				case 'ne_date':
					let neDate = false;
					if ( elementType === 'date range' ) {
						neDate = aDate < bDateStartRange || aDate > bDateEndRange;
					}
					if ( elementType === 'date' ) {
						neDate = aDate !== bDate;
					}
					return neDate;
				case 'gr_date':
					let grDate = false;
					if ( elementType === 'date range' ) {
						grDate = aDate < bDateEndRange;
					}
					if ( elementType === 'date' ) {
						grDate = aDate > bDate;
					}
					return grDate;
				case 'le_date':
					let leDate = false;
					if ( elementType === 'date range' ) {
						leDate = aDate > bDateStartRange;
					}
					if ( elementType === 'date' ) {
						leDate = aDate < bDate;
					}
					return leDate;
				case 'any':
					return true;
				default:
					return false;
			}
		};
		const elementsAvailable = items.filter( ( x ) => [ 'element_id', 'id_element' ].some( ( y ) => x.hasOwnProperty( y ) ) );
		const conditionEvaluationResults = [];
		const commonDependency = [];
		allConditions.forEach( ( condition ) => {
			const isCheckboxCondition = condition.elementitem_id && condition.condition_element_id === null;
			const processCheckboxCondition = isCheckboxCondition && [ 'chec', 'unc' ].some( ( x ) => condition.op?.includes( x ) );
			const processQtnImageCondition = isCheckboxCondition && ! [ 'chec', 'unc' ].some( ( x ) => condition.op?.includes( x ) );
			const processDateCondition = condition.op?.includes( 'date' );
			let isTotalCondition = false;
			try {
				const parsedValue = JSON.parse( condition.value );
				isTotalCondition = parsedValue.calctotal ? true : false;
				if ( isTotalCondition ) {
					condition.parsedValue = parsedValue.calctotal;
				}
			} catch ( error ) {
				isTotalCondition = false;
			}
			const conditionDependencyElementOrElementItemId = sccUtils.getConditionDependencyElementOrElementItemId( condition, processCheckboxCondition, processQtnImageCondition );
			if ( typeof ( commonDependency[ conditionDependencyElementOrElementItemId ] ) === 'undefined' ) {
				commonDependency[ conditionDependencyElementOrElementItemId ] = [];
			}
			// Only push the condition.element_id if it is not already in the array
			if ( ! commonDependency[ conditionDependencyElementOrElementItemId ].includes( condition.element_id ) ) {
				commonDependency[ conditionDependencyElementOrElementItemId ].push( condition.element_id );
			}
			let dependencyFoundInElementsAvailable = false;

			if ( isTotalCondition ) {
				const elementAvailableInItems = items.findIndex( ( item ) => item[ sccUtils.getComparisonElementIdByItem( item ) ] === condition.element_id );
				let compareAgainstTotal = totalValueBeforeConditionCheck;
				if ( elementAvailableInItems > -1 && items[ elementAvailableInItems ].type !== 'customMath' ) {
					// Deducting the condition element value from the total value
					compareAgainstTotal -= parseFloat( items[ elementAvailableInItems ].item_total_value );
				}
				const conditionMet = compareValues( condition.parsedValue, compareAgainstTotal, condition.op );
				const action = conditionMet ? 'show' : 'hide';
				dependencyFoundInElementsAvailable = true;
				conditionEvaluationResults.push( {
					conditionId: condition.id,
					conditionMet: action === 'show',
					conditionSet: condition.condition_set,
					targetElementId: condition.element_id,
				} );
			}
			if ( processCheckboxCondition ) {
				const checkboxesAvailable = elementsAvailable.filter( ( z ) => z.type === 'checkbox' );
				const checkboxIds = checkboxesAvailable.map( ( z ) => z.id_elementItem );
				const checkboxFound = checkboxIds.includes( conditionDependencyElementOrElementItemId.replace( 'chkbx-', '' ) );
				dependencyFoundInElementsAvailable = true;
				const conditionMet = ( condition.op === 'chec' && checkboxFound ) || ( condition.op === 'unc' && ! checkboxFound );
				const action = conditionMet ? 'show' : 'hide';
				conditionEvaluationResults.push( {
					conditionId: condition.id,
					conditionMet: action === 'show',
					conditionSet: condition.condition_set,
					targetElementId: condition.element_id,
				} );
			}
			if ( processQtnImageCondition ) {
				const qtnImagesAvailable = elementsAvailable.filter( ( z ) => z.type === 'qtn-image' );
				if ( qtnImagesAvailable.length > 0 ) {
					const qtnImageIds = qtnImagesAvailable.map( ( z ) => z.id_elementItem );
					const qtnImageFound = qtnImageIds.includes( conditionDependencyElementOrElementItemId.replace( 'qtn-image-btn-', '' ) );
					dependencyFoundInElementsAvailable = true;
					const { qualifierValue, detectedType } = sccUtils.getTypeAndQualifierValue( qtnImagesAvailable[ 0 ] );
					const comparisonValue = sccUtils.getComparisonValue( condition, detectedType );
					const conditionMet = compareValues( comparisonValue, qualifierValue, condition.op );

					const action = conditionMet ? 'show' : 'hide';
					conditionEvaluationResults.push( {
						conditionId: condition.id,
						conditionMet: action === 'show',
						conditionSet: condition.condition_set,
						targetElementId: condition.element_id,
						comparisonValue,
						qualifierValue,
						conditionOp: condition.op,
					} );
				}
			}
			if ( ! processCheckboxCondition && ! isTotalCondition && ! processQtnImageCondition ) {
				elementsAvailable.forEach( ( item ) => {
					const comparisonByKey = sccUtils.getComparisonElementIdByItem( item );
					if ( Number( item[ comparisonByKey ] ) === Number( conditionDependencyElementOrElementItemId ) ) {
						dependencyFoundInElementsAvailable = true;
						const { qualifierValue, detectedType } = sccUtils.getTypeAndQualifierValue( item, condition.op );
						const comparisonValue = sccUtils.getComparisonValue( condition, detectedType );
						const action = compareValues( comparisonValue, qualifierValue, condition.op, detectedType ) ? 'show' : 'hide';
						conditionEvaluationResults.push( {
							conditionId: condition.id,
							conditionMet: action === 'show',
							conditionSet: condition.condition_set,
							targetElementId: condition.element_id,
						} );
					}
				} );
			}
			if ( ! dependencyFoundInElementsAvailable ) {
				conditionEvaluationResults.push( {
					conditionId: condition.id,
					conditionMet: false,
					conditionSet: condition.condition_set,
					targetElementId: condition.element_id,
				} );
			}
		} );
		const { conditionsMet, conditionsNotMet, postConditionalLogicSectionState } = sccUtils.analyzeConditions( conditionEvaluationResults, commonDependency, calcId );
		conditionsMet.forEach( ( elementId ) => {
			sccUtils.showOrHideElementById( calcId, elementId, 'show' );
		} );
		conditionsNotMet.forEach( ( elementId ) => {
			sccUtils.showOrHideElementById( calcId, elementId, 'hide' );
			const chain = commonDependency[ elementId ];
			if ( chain && chain.length > 0 ) {
				chain.forEach( ( elementId2 ) => {
					sccUtils.showOrHideElementById( calcId, elementId2, 'hide' );
				} );
			}
		} );
		postConditionalLogicSectionState.forEach( ( { sectionId, action } ) => {
			sccUtils.showOrHideSectionById( calcId, sectionId, action );
		} );
		// join conditionsNotMet and
		elementsAvailable.forEach( ( item ) => {
			if ( [ 'element_id', 'id_element' ].some( ( y ) => conditionsNotMet.includes( item[ y ] ) ) ) {
				item.hidden = true;
			} else {
				item.hidden = false;
			}
		} );
	},
	detectConditionType: ( condition ) => {
		switch ( true ) {
			case condition.includes( 'date' ):
				return 'date';
			case condition.includes( 'chec' ):
			case condition.includes( 'unc' ):
				return 'checkbox';
			case condition.includes( 'cost' ):
				return 'cost';
			default:
				return 'quantity';
		}
	},
	showOrHideElementById: ( calcId, elementId, state ) => {
		const element = sccUtils.getCalcWrapper( calcId ).querySelector( '#ssc-elmt-' + elementId );
		// wrapper
		if ( state === 'hide' ) {
			element.classList.add( 'scc-conditional-hidden' );
		}
		if ( state === 'show' ) {
			element.classList.remove( 'scc-conditional-hidden' );
			if ( element.getAttribute( 'data-typeelement' ) === 'signature box' ) {
				const paintCanvas = element.querySelector( 'canvas.signature-box' );
				// Save the canvas data before resizing
				const savedData = paintCanvas.toDataURL();
				paintCanvas.width = paintCanvas.clientWidth;
				paintCanvas.height = paintCanvas.clientHeight;
				// Restore the canvas data after resizing
				const context = paintCanvas.getContext( '2d' );
				const image = new Image();
				image.onload = () => {
					context.drawImage( image, 0, 0 );
				};
				image.src = savedData;
			}
		}
	},
	registerTooltips: ( helpIconNode, tooltipNode, calcId, placement = false ) => {
		const button = helpIconNode;
		const tooltip = tooltipNode;
		const dropdownImageTooltip = helpIconNode.classList.contains( 'dropdown-image-tooltip' );
		const checkboxTooltip = helpIconNode.classList.contains( 'scc-checkbox-tooltip' );
		const tooltipBottom = helpIconNode.classList.contains( 'scc-tooltip-bottom' );
		let tooltipConfig = button.getAttribute( 'tooltip' );
		const tooltipContent = button.getAttribute( 'tooltip' );
		try {
			tooltipConfig = JSON.parse( tooltipContent );
		} catch ( error ) {
			tooltipConfig = {
				title: tooltipContent,
				content: '',
				image: '',
				btn_label: '',
				btn_link: '',
			};
		}
		if ( checkboxTooltip ) {
			tooltipConfig = {
				title: '',
				content: tooltipContent,
				image: '',
				btn_label: '',
				btn_link: '',
			};
		}
		const tooltipTitleNode = tooltip.querySelector( '.scc-tooltip-title-text' );
		const tooltipContentNode = tooltip.querySelector( '.scc-tooltip-content-text' );
		const tooltipButtonTextNode = tooltip.querySelector( '.scc-tooltip-button-text' );
		const tooltipImageNode = tooltip.querySelector( '.scc-tooltip-image' );
		const tooltipButtonWrapper = tooltip.querySelector( '.scc-tooltip-button-wrapper' );

		let popperPlacement = 'right';
		if ( dropdownImageTooltip ) {
			popperPlacement = 'left';
		}
		if ( tooltipBottom ) {
			popperPlacement = 'bottom';
		}

		const popperInstance = createPopper( button, tooltip, {
			placement: popperPlacement,
			modifiers: [
				{
					name: 'offset',
					options: {
						offset: [ 0, 8 ],
					},
				},
			],
		} );

		function show() {
			// Make the tooltip visible
			tooltip.setAttribute( 'data-show', '' );
			tooltip.setAttribute( 'data-calc-id', calcId );
			tooltipTitleNode.textContent = tooltipConfig.title;
			if ( tooltipConfig.content.length > 0 ) {
				tooltipContentNode.textContent = tooltipConfig.content;
				tooltipContentNode.classList.remove( 'scc-d-none' );
			} else {
				tooltipContentNode.classList.add( 'scc-d-none' );
			}
			if ( tooltipConfig.image.length > 0 ) {
				tooltipImageNode.src = tooltipConfig.image;
				tooltipImageNode.classList.remove( 'scc-d-none' );
			} else {
				tooltipImageNode.classList.add( 'scc-d-none' );
			}
			if ( tooltipConfig.btn_link.length > 0 ) {
				tooltipButtonTextNode.href = tooltipConfig.btn_link;
			} else {
			// tooltipButtonTextNode.classList.add("scc-d-none");
			}
			if ( tooltipConfig.btn_label.length > 0 ) {
				tooltipButtonTextNode.textContent = tooltipConfig.btn_label;
				tooltipButtonWrapper.classList.remove( 'scc-d-none' );
			} else {
				tooltipButtonWrapper.classList.add( 'scc-d-none' );
			}

			// Enable the event listeners
			popperInstance.setOptions( ( options ) => ( {
				...options,
				modifiers: [
					...options.modifiers,
					{ name: 'eventListeners', enabled: true },
				],
			} ) );

			// Update its position
			// popperInstance.update();
		}

	  function isOutside( tooltipBounds, clientX, clientY ) {
			clientX = clientX + 8;
			// clientY = clientY - 3;
			return (
				clientX < tooltipBounds.left ||
			clientX > tooltipBounds.left + tooltipBounds.width ||
			clientY < ( tooltipBounds.top + 3 ) ||
			clientY > ( tooltipBounds.top + tooltipBounds.height ) - 3
			);
	  }

		function hide( { clientX, clientY } ) {
			// Hide the tooltip
			const tooltipBounds = tooltip.getBoundingClientRect();
			if ( isOutside( tooltipBounds, clientX, clientY ) ) {
				tooltip.removeAttribute( 'data-show' );
				// Disable the event listeners
				popperInstance.setOptions( ( options ) => ( {
					...options,
					modifiers: [
						...options.modifiers,
						{ name: 'eventListeners', enabled: false },
					],
				} ) );
			} else if ( ! tooltip.getAttribute( 'data-attached-scc-tooltip-event' ) ) {
				tooltip.addEventListener( 'mousemove', function( { clientX, clientY } ) {
					if ( isOutside( tooltipBounds, clientX, clientY ) ) {
						hide( { clientX, clientY } );
					}
			  } );
				tooltip.setAttribute( 'data-attached-scc-tooltip-event', 'mousemove' );
			}
		}

		const showEvents = [ 'mouseenter', 'focus' ];
		const hideEvents = [ 'mouseleave', 'blur' ];

		showEvents.forEach( ( event ) => {
			button.addEventListener( event, show );
		} );

		hideEvents.forEach( ( event ) => {
			button.addEventListener( event, hide );
		} );
	},
	minimumTotalPriceAnimation( calcId ) {
		const calcWrapper = sccUtils.getCalcWrapper( calcId );
		const minimumMsg = calcWrapper.querySelector( '.scc-minimum-msg' );
		sccUtils.vibrationAnimation( minimumMsg );
	},
	vibrationAnimation( node ) {
		if ( node ) {
			node.classList.add( 'scc-vibrate' );
			setTimeout( function() {
				node.classList.remove( 'scc-vibrate' );
			}, 500 );
		}
	},
	getSectionsWithPageBreaks: ( calcId ) => {
		const availableSectionsWithPageBreaks = sccData[
			calcId
		].config.sections.filter( ( e ) => e.section_in_page == 1 );
		const calcWrapper = sccUtils.getCalcWrapper( calcId );
		return availableSectionsWithPageBreaks;
	},
	getCalcWrapper: ( calcId ) => {
		return document.querySelector( `#scc_form_${ calcId }` );
	},
	getAllNodes: ( calcId ) => {
		const calcWrapper = sccUtils.getCalcWrapper( calcId );
		return [ ...calcWrapper.children ];
	},
	getSectionTitleAndDescriptionNodes: ( calcId ) => {
		const calcWrapper = sccUtils.getCalcWrapper( calcId );
		const availableSections = sccData[ calcId ].config.sections;
		return availableSections.map( ( e, i ) => {
			const sectionTitleNode = calcWrapper.querySelector(
				`[data-section-index="${ i }-${ calcId }"]`,
			);
			return sectionTitleNode;
		} );
	},
	parseValueFromElementInteraction: ( { type, dom, element, item, calcId } ) => {
		switch ( type ) {
			case 1:
				return { type, value: dom.checked, element, item, calcId };
			case 2:
				return { type, value: dom?.tomselect.getValue(), element: dom.getAttribute( 'data-elementid' ), item, calcId };
			case 5:
				return { type, value: Number( element.split( '/' )[ 1 ] ), element, item, calcId };
			default:
				return { type, value: dom?.value, element, item, calcId };
		}
	},
	showOrHideSectionById: ( calcId, sectionId, state ) => {
		const sectionTitleElements = sccUtils.getSectionTitleAndDescriptionNodes( calcId );
		const targetSectionTitleElement = sectionTitleElements.find( ( nodes ) => Number( nodes.getAttribute( 'data-section-id' ) ) === Number( sectionId ) );
		if ( ! targetSectionTitleElement ) {
			return;
		}
		const calcWrapper = sccUtils.getCalcWrapper( calcId );
		const targetSectionTitleElementIndex = targetSectionTitleElement.getAttribute( 'data-section-index' );
		const targetSectionHorizontalRule = targetSectionTitleElement.nextElementSibling;
		const targetSectionDescriptionElement = calcWrapper.querySelector( `[id^=section_desc_][data-section-index="${ targetSectionTitleElementIndex }"]` );
		if ( state === 'hide' ) {
			targetSectionTitleElement.classList.add( 'scc-conditional-hidden' );
			targetSectionHorizontalRule.classList.add( 'scc-conditional-hidden' );
			targetSectionDescriptionElement.classList.add( 'scc-conditional-hidden' );
		}
		if ( state === 'show' ) {
			targetSectionTitleElement.classList.remove( 'scc-conditional-hidden' );
			targetSectionHorizontalRule.classList.remove( 'scc-conditional-hidden' );
			targetSectionDescriptionElement.classList.remove( 'scc-conditional-hidden' );
		}
	},
	getSectionRelativeNodes: ( calcId ) => {
		const allElements = sccUtils.getAllNodes( calcId );
		const sectionTitleElements = sccUtils.getSectionTitleAndDescriptionNodes( calcId );
		const sectionIndexes = sectionTitleElements.map( ( e ) =>
			allElements.findIndex( ( ee ) => ee == e ),
		);
		const sectionsWithRelativeNodes = [];
		sectionIndexes.forEach( ( idx, key ) => {
			const nextIndexKey = sectionIndexes[ key + 1 ];
			const isLastIndex = key + 1 == sectionIndexes.length;
			if ( isLastIndex ) {
				const targetNodeIndexes = [];
				for ( let i = idx; i < allElements.length; i++ ) {
					if ( ! [ 'scc-glass-filter', 'scc-total-section' ].some( ( z ) => allElements[ i ]?.classList.contains( z ) ) ) {
						targetNodeIndexes.push( i );
					}
				}
				const nodes = targetNodeIndexes.map( ( e ) => allElements[ e ] );
				sectionsWithRelativeNodes[ idx ] = nodes;
				return;
			}
			const targetNodeIndexes = Object.keys( allElements ).slice( idx, nextIndexKey );
			const nodes = targetNodeIndexes.map( ( e ) => allElements[ e ] );
			sectionsWithRelativeNodes[ idx ] = nodes;
		} );
		return sectionsWithRelativeNodes;
	},
	getSectionPaginators: ( calcId ) => {
		const calcWrapper = sccUtils.getCalcWrapper( calcId );
		return calcWrapper.querySelectorAll( '[data-parent-section]' );
	},
	getProgressBar: ( calcId ) => {
		const { progress_indicator_style } = sccData[ calcId ].config;
		let selector = '';
		let progress_indicator_style_name = '';
		switch ( progress_indicator_style ) {
			case '0':
				selector = `.df-scc-progress-d-none[data-calc-id="${ calcId }"]`;
				progress_indicator_style_name = 'none';
				break;
			case '1':
				selector = `.df-scc-progress-bar[data-calc-id="${ calcId }"]`;
				progress_indicator_style_name = 'Progress Bar';
				break;
			case '2':
				selector = `.df-scc-progress-v2[data-calc-id="${ calcId }"]`;
				progress_indicator_style_name = 'Progress Dots With Title';
				break;
			case '3':
				selector = `.df-scc-bar-v3[data-calc-id="${ calcId }"]`;
				progress_indicator_style_name = 'Progress Bar Steps With Title';
				break;
			case '4':
				selector = `.df-scc-bar-v4[data-calc-id="${ calcId }"]`;
				progress_indicator_style_name = 'Progress Dots with Numerical Steps';
				break;

			default:
				selector = `.df-scc-progress-bar[data-calc-id="${ calcId }"]`;
				progress_indicator_style_name = 'Progress Bar';
				break;
		}
		return {
			progressBarType: progress_indicator_style_name,
			progressBar: document.querySelector( selector ),
		};
	},
	applyRangeTotal: ( total, totalRangeSettings ) => {
		const { rangePercent } = totalRangeSettings;
		return total == 0 ? 0 : total * ( 1 + ( Number( rangePercent ) / 100 ) );
	},
	/**
	 * Calculates where to break the page and create a next page section
	 * @param calcId
	 */
	handlePageBreak: ( calcId ) => {
		const relativeNodes = sccUtils.getSectionRelativeNodes( calcId );
		const allNodes = sccUtils.getAllNodes( calcId );
		const calcWrapper = sccUtils.getCalcWrapper( calcId );
		sccUtils.getSectionsWithPageBreaks( calcId ).forEach( ( section, brkIndex ) => {
			if ( brkIndex > 0 ) {
				return;
			}
			const targetIndex = allNodes.findIndex(
				( e ) => e.getAttribute( 'data-section-id' ) == section.id,
			);
			const relativeNodesKeys = Object.keys( relativeNodes );
			const sectionIndex = relativeNodesKeys.findIndex( ( e ) => e == targetIndex );
			const remainingSectionIndexes =
				relativeNodesKeys.length > sectionIndex + 1
					? relativeNodesKeys.slice( sectionIndex + 1 )
					: [];
			const sectionsEvaluatedForHiding = [
				targetIndex,
				...remainingSectionIndexes,
			];
			const progressSteps = calcWrapper.querySelectorAll(
				'.scc-pagination-nav',
			)?.length;
			const unitProgress = ( 100 / progressSteps ).toFixed( 0 );
			const { progressBar, progressBarType } = sccUtils.getProgressBar( calcId );
			if ( progressBar && progressBarType == 'Progress Bar' ) {
				progressBar.setAttribute( 'data-progress-steps', progressSteps );
				progressBar.setAttribute( 'data-progress-current-step', 1 );
				progressBar.style.width = unitProgress + '%';
			}
			if ( progressBar && progressBarType == 'Progress Dots With Title' ) {
				progressBar.setAttribute( 'data-progress-steps', progressSteps );
				progressBar.setAttribute( 'data-progress-current-step', 1 );
			}
			if ( progressBar && progressBarType == 'Progress Bar Steps With Title' ) {
				progressBar.setAttribute( 'data-progress-steps', progressSteps );
				progressBar.setAttribute( 'data-progress-current-step', 1 );
				const steps = progressBar.querySelectorAll( '.df-scc-bar-v3-item[data-complete=true]' );
				const mobileViewActiveStep = steps[ steps.length - 1 ];
				mobileViewActiveStep.style.setProperty( '--df-scc-bar-v3-item-mobile-width', unitProgress + '%' );
			}
			if ( progressBar && progressBarType === 'Progress Dots with Numerical Steps' ) {
				progressBar.setAttribute( 'data-progress-steps', progressSteps );
				progressBar.setAttribute( 'data-progress-current-step', 1 );
				const firstStepBubble = progressBar.querySelector( '.df-scc-progress-v4-indicator li' );
				if ( firstStepBubble ) {
					firstStepBubble.setAttribute( 'data-active-step', 'true' );
				}
			}
			sectionsEvaluatedForHiding.forEach( ( index ) => {
				relativeNodes[ index ].forEach( ( e ) => {
					e.classList.add( 'scc-section-hidden' );
				} );
			} );
		} );
	},
	getVariableMathWithElements: ( calcId ) => {
		const calcWrapper = sccUtils.getCalcWrapper( calcId );
		const elementMathNodes = calcWrapper.querySelectorAll(
			'[data-name="scc-vmath-element"]',
		);
		return [ ...elementMathNodes ].map( ( elementMathNode ) => {
			const vmathId = elementMathNode.getAttribute( 'data-elementid' );
			const vmathVarId = elementMathNode.getAttribute( 'data-itemid' );
			const vmathLinkedElement = elementMathNode.getAttribute(
				'data-attached-element',
			);
			const vmathLinkedElementItem = elementMathNode.getAttribute(
				'data-attached-element-item',
			);
			const vmathLinkedElementType = elementMathNode.getAttribute(
				'data-linked-element-type',
			) || 'unset';
			const vmathIsDropdown = vmathLinkedElementItem != 0;
			return {
				vmathId,
				vmathVarId,
				vmathLinkedElement,
				vmathLinkedElementItem,
				vmathLinkedElementType,
				vmathIsDropdown,
			};
		} );
	},
	hideSection: ( calcId, sectionId ) => {
		const relativeNodes = sccUtils.getSectionRelativeNodes( calcId );
		const allNodes = sccUtils.getAllNodes( calcId );
		const targetIndex = allNodes.findIndex(
			( e ) => e.getAttribute( 'data-section-id' ) == sectionId,
		);
		relativeNodes[ targetIndex ].forEach( ( e ) => {
			e.classList.add( 'scc-section-hidden' );
		} );
	},
	simulateClick: ( element ) => {
		const evt = new MouseEvent( 'click', {
			bubbles: true,
			cancelable: true,
			view: window,
		} );
		element.dispatchEvent( evt );
	},
	triggerChangeEvent: ( element ) => {
		const event = new Event( 'change' );
		element.dispatchEvent( event );
	},
	handleVariableMath: ( calcId, variableMath, allItems ) => {
		const variableMathElements = sccData[ calcId ].variableMathElements;
		if ( variableMathElements.length <= 0 ) {
			return;
		}
		const extraCalculationTasks = variableMath.map( ( e ) => {
			const vmathVarId = e.id;
			allItems;
			variableMathElements;
			return variableMathElements.find( ( e ) => e.vmathVarId == vmathVarId );
		} );
		return extraCalculationTasks;
	},
	showSection: ( calcId, sectionId ) => {
		const relativeNodes = sccUtils.getSectionRelativeNodes( calcId );
		const allNodes = sccUtils.getAllNodes( calcId );
		const targetIndex = allNodes.findIndex(
			( e ) => e.getAttribute( 'data-section-id' ) == sectionId,
		);
		relativeNodes[ targetIndex ].forEach( ( e ) => {
			e.classList.remove( 'scc-section-hidden' );
		} );
	},
	isMinimumTotalMet: ( calcId ) => {
		const minimumTotal = sccData[ calcId ].config.minimumTotal;
		const calcTotal = sccData[ calcId ].total;
		const result = calcTotal >= minimumTotal;
		if ( ! result ) {
			sccUtils.minimumTotalPriceAnimation( calcId );
		}
		return result;
	},
	/**
	 *
	 * @param calcId
	 */
	getProgressStepsSelectors: ( calcId ) => {
		const progressStepsSelectors = {
			'Progress Dots With Title': { progressStepsSelector: '.df-scc-progress-v2-dot', progressStepsData: 'progressStep' },
			'Progress Dots with Numerical Steps': { progressStepsSelector: 'li', progressStepsData: 'step' },
			'Progress Bar Steps With Title': { progressStepsSelector: '.df-scc-bar-v3-step .trn, .df-scc-bar-v3-step-text', progressStepsData: 'step' },
		};
		const { progressBar, progressBarType } = sccUtils.getProgressBar( calcId );
		const progressStepsSelectorsData = progressStepsSelectors[ progressBarType ] || {};
		if ( progressBarType === 'Progress Bar Steps With Title' ) {
			if ( progressBar ) {
				progressBar.querySelectorAll( '.df-scc-bar-v3-item' )?.forEach( ( e, index ) =>
					e.querySelectorAll( progressStepsSelectorsData.progressStepsSelector )
						?.forEach( ( dot ) => dot.setAttribute( 'data-step', index + 1 ) ),
				);
			}
		}

		return progressStepsSelectorsData;
	},
	/**
	 * Registers event listeners on the 'next' and 'previous' buttons
	 * @param calcId
	 */
	registerPagination: ( calcId ) => {
		const calcWrapper = sccUtils.getCalcWrapper( calcId );
		const { progressBar, progressBarType } = sccUtils.getProgressBar( calcId );
		const { progressStepsSelector, progressStepsData } = sccUtils.getProgressStepsSelectors( calcId );
		const progressSteps = calcWrapper.querySelectorAll(
			'.scc-pagination-nav',
		)?.length;

		const goDotsStepHandler = ( evt ) => {
			const progressStepId = +evt.target.dataset[ progressStepsData ];
			const progressBarCurrentStep = +progressBar.getAttribute( 'data-progress-current-step' );

			if ( ! progressStepId || ! progressBarCurrentStep ) {
				return;
			}

			const stepDifference = Math.abs( progressBarCurrentStep - progressStepId );
			const direction = progressBarCurrentStep > progressStepId ? -1 : 1;

			for ( let i = 0; i < stepDifference; i++ ) {
				const step = progressBarCurrentStep + ( i * direction );
				const isLastIndex = step === progressSteps;
				const currentPaginationNav = calcWrapper.querySelector( isLastIndex ? `.scc-last-prev-btn` : `[data-paginator-key="${ step }"]` );
				const paginationBtns = currentPaginationNav?.querySelectorAll( '.scc-paginate-btn' );

				if ( paginationBtns?.length ) {
					paginationBtns[ direction === -1 ? 0 : 1 ].click();
				}
			}
		};

		if ( progressBar && progressStepsSelector ) {
			const progressDots = progressBar.querySelectorAll( progressStepsSelector );
			progressDots?.forEach( ( dot ) => {
				dot.style.cursor = `pointer`;
				dot.addEventListener( 'click', goDotsStepHandler );
			} );
		}

		calcWrapper.querySelectorAll( '.scc-pagination-nav' ).forEach( ( e, index ) => {
			let isLastIndex = index + 1 == progressSteps;
			// If there are only 2 steps
			if ( progressSteps == 2 ) {
				isLastIndex = index == 1;
			}
			const paginationBtns = e.querySelectorAll( '.scc-paginate-btn' );
			const prevBtn = paginationBtns[ 0 ];
			const nextBtn = paginationBtns[ 1 ];
			const progressBarMobileTitle = progressBar?.querySelector( '.df-scc-progress-v2-mobile-title' );
			const processBarMobileSubTitle = progressBar?.querySelector( '.df-scc-progress-v2-mobile-subtitle' );
			const totalBar = calcWrapper.querySelector( '.scc-total-section' );
			const isMultiStepHidden = totalBar.getAttribute( 'data-multi-step-active' ) == 1;
			const totalBarLastPrevBtn = totalBar.querySelector( '.scc-last-prev-btn .scc-paginate-btn' );

			if ( progressBar ) {
				/* In style 2, getting height of the section titles,
				* and adjusting the margins of the title and progress bar */
				const progressSectionTitles = progressBar.querySelectorAll( '.df-scc-progress-v2-title' );
				const handleProgressBarV2Style = () => {
					progressSectionTitles.forEach( ( titleNode ) => {
						const height = titleNode.offsetHeight;
						if ( height > 24 ) {
							titleNode.parentNode.style.top = '-' + height + 'px';
							progressBar.style.marginTop = ( height - 24 ) + 'px';
						}
					} );
				};
				let resizeTimeout;
				window.addEventListener( 'resize', function() {
    					clearTimeout( resizeTimeout );

    					resizeTimeout = setTimeout( function() {
        					handleProgressBarV2Style();
    					}, 100 );
				} );
				handleProgressBarV2Style();
				/**
				 * In Style 3, adding `aria-selected="true"` attribute to the first step, so it shows up
				 * on mobile view
				 */
				if ( progressBarType == 'Progress Bar Steps With Title' ) {
					const firstStep = progressBar.querySelector( '.df-scc-bar-v3-item:nth-child(1)' );
					firstStep.setAttribute( 'aria-selected', 'true' );
				}
			}

			const goPreviousStepHandler = ( evt ) => {
				if ( ( index + 1 !== progressSteps - 1 ) ) {
					if ( isMultiStepHidden ) {
						totalBar.classList.add( 'scc-multi-page-total-hidden' );
					}

					const sccBlurButton = totalBar.querySelector( '.scc-btn-blur-tp' );
					if ( sccBlurButton ) {
						sccBlurButton.classList.add( 'scc-hidden' );
					}
				}

				const showTargets = prevBtn.getAttribute( 'data-prev-sections' ).split( ',' );
				const hideTargets = prevBtn
					.getAttribute( 'data-section-hide-targets' )
					.split( ',' );
				if ( progressBar && progressBarType == 'Progress Bar' ) {
					const progressBarSteps = Number(
						progressBar.getAttribute( 'data-progress-steps' ),
					);
					const progressBarCurrentStep = Number(
						progressBar.getAttribute( 'data-progress-current-step' ),
					);
					const unitProgress =
						( ( progressBarCurrentStep - 1 ) / progressBarSteps ) * 100;
					progressBar.style.width = unitProgress + '%';
					progressBar.setAttribute(
						'data-progress-current-step',
						progressBarCurrentStep - 1,
					);
				}

				if ( progressBar && progressBarType == 'Progress Dots With Title' ) {
					const progressDots = progressBar.querySelectorAll( '.df-scc-progress-v2-dot' );
					const progressBarSteps = Number(
						progressBar.getAttribute( 'data-progress-steps' ),
					);
					const progressBarCurrentStep = Number(
						progressBar.getAttribute( 'data-progress-current-step' ),
					);
					const navigatedToStep = progressBarCurrentStep - 1;
					const progressDotIndex = navigatedToStep - 1;
					const navigatedToStepDot = progressDots[ progressDotIndex ];
					const navigatedToStepText = navigatedToStepDot.textContent.trim();
					const activeProgressDot = progressBar.querySelector( '.df-scc-progress-v2-dot.df-scc-progress-v2-current' );
					const activeProgressBarLeft = activeProgressDot.previousElementSibling;

					activeProgressDot.classList.remove( 'df-scc-progress-v2-current' );
					activeProgressDot.classList.remove( 'df-scc-progress-v2-done' );
					activeProgressBarLeft.classList.remove( 'df-scc-progress-v2-done' );
					navigatedToStepDot.classList.add( 'df-scc-progress-v2-current' );
					progressBarMobileTitle.textContent = navigatedToStepText;
					processBarMobileSubTitle.textContent = 'Step' + ' ' + navigatedToStep + '/' + progressBarSteps;
					progressBar.setAttribute(
						'data-progress-current-step',
						navigatedToStep,
					);
				}
				if ( progressBar && progressBarType === 'Progress Dots with Numerical Steps' ) {
					const progressDots = progressBar.querySelectorAll( 'li' );
					const progressBarSteps = Number(
						progressBar.getAttribute( 'data-progress-steps' ),
					);
					const progressBarCurrentStep = Number(
						progressBar.getAttribute( 'data-progress-current-step' ),
					);
					const navigatedToStep = progressBarCurrentStep - 1;
					const progressDotIndex = navigatedToStep - 1;
					const navigatedToStepDot = progressDots[ progressDotIndex ];
					navigatedToStepDot.classList.remove( 'df-scc-progress-v4-completed' );
					const activeProgressBarLeft = navigatedToStepDot.nextElementSibling;
					activeProgressBarLeft.classList.remove( 'df-scc-progress-v4-connecting-bar-done' );
					navigatedToStepDot.setAttribute( 'data-active-step', 'true' );
					const otherStepDots = [ ...progressDots ].filter( ( dot ) => dot !== navigatedToStepDot );
					otherStepDots.forEach( ( dot ) => {
						dot.setAttribute( 'data-active-step', 'false' );
					} );
					progressBar.setAttribute(
						'data-progress-current-step',
						navigatedToStep,
					);
				}

				if ( progressBar && progressBarType == 'Progress Bar Steps With Title' ) {
					const progressBarSteps = Number(
						progressBar.getAttribute( 'data-progress-steps' ),
					);
					const progressBarCurrentStep = Number(
						progressBar.getAttribute( 'data-progress-current-step' ),
					);
					const unitProgress =
						( ( progressBarCurrentStep - 1 ) / progressBarSteps ) * 100;
					progressBar.setAttribute(
						'data-progress-current-step',
						progressBarCurrentStep - 1,
					);

					const progressBarAllSteps = [ ...progressBar.querySelectorAll( '.df-scc-bar-v3-item' ) ];
					const progressBarDoneSteps = progressBarAllSteps.filter( ( z ) => z.getAttribute( 'data-complete' ) == 'true' );
					const progressBarPreviousStep = progressBarDoneSteps[ progressBarDoneSteps.length - 1 ];
					progressBarPreviousStep.setAttribute( 'data-complete', 'false' );
					const currentDoneSteps = progressBar.querySelectorAll( '.df-scc-bar-v3-item[data-complete="true"]' );
					const currentActiveStep = currentDoneSteps[ currentDoneSteps.length - 1 ];
					currentActiveStep.setAttribute( 'aria-selected', 'true' );
					progressBarAllSteps.forEach( ( step, index ) => {
						if ( currentActiveStep !== step ) {
							step.setAttribute( 'aria-selected', 'false' );
						}
					} );
					currentActiveStep.style.setProperty( '--df-scc-bar-v3-item-mobile-width', unitProgress + '%' );
				}

				showTargets.forEach( ( sectionId ) => {
					sccUtils.showSection( calcId, sectionId );
				} );
				hideTargets.forEach( ( sectionId ) => {
					sccUtils.hideSection( calcId, sectionId );
				} );
				if ( progressBar ) {
					if ( progressBar.parentElement.getBoundingClientRect().top < 0 ) {
						window.scrollTo( 0, sccGetOffset( progressBar.parentElement ).top - 120 );
						if ( window.self !== window.top ) {
							window.parent.postMessage( { action: 'scroll', top: ( sccGetOffset( progressBar.parentElement ).top - 120 ) }, '*' );
						}
					}
				}

				// Hide the last previous button
				if ( totalBarLastPrevBtn ) {
					totalBarLastPrevBtn.classList.add( 'scc-multi-page-last-prev-hidden' );
				}
			};

			prevBtn.onclick = goPreviousStepHandler;
			if ( isLastIndex && totalBarLastPrevBtn ) {
				totalBarLastPrevBtn.classList.add( 'scc-multi-page-last-prev-hidden' );
				totalBarLastPrevBtn.onclick = goPreviousStepHandler;
			}

			const goNextStepHandler = ( evt ) => {
				const button = evt.target.closest( '.scc-paginate-next-btn' );
				if ( ! verifiedMandatoryItems( calcId, button ) ) {
					return;
				}
				if ( ( index + 1 == progressSteps - 1 ) ) {
					if ( isMultiStepHidden ) {
						const totalBar = calcWrapper.querySelector( '.scc-total-section' );
						totalBar.classList.remove( 'scc-multi-page-total-hidden' );
						totalBar.querySelector( '.scc-paginate-btn' ).classList.remove( 'scc-v-hidden' );
					}
					if ( totalBarLastPrevBtn ) {
						totalBarLastPrevBtn.classList.remove( 'scc-multi-page-last-prev-hidden' );
						totalBarLastPrevBtn.classList.remove( 'scc-v-hidden' );
					}

					const sccBlurButton = totalBar.querySelector( '.scc-btn-blur-tp' );
					if ( sccBlurButton ) {
						sccBlurButton.classList.remove( 'scc-hidden' );
					}
				}

				const showTargets = nextBtn.getAttribute( 'data-next-sections' ).split( ',' );
				const hideTargets = nextBtn
					.getAttribute( 'data-section-hide-targets' )
					.split( ',' );
				if ( progressBar && progressBarType == 'Progress Bar' ) {
					const progressBarSteps = Number(
						progressBar.getAttribute( 'data-progress-steps' ),
					);
					const progressBarCurrentStep = Number(
						progressBar.getAttribute( 'data-progress-current-step' ),
					);
					const unitProgress =
						( ( progressBarCurrentStep + 1 ) / progressBarSteps ) * 100;
					progressBar.style.width = unitProgress + '%';
					progressBar.setAttribute(
						'data-progress-current-step',
						progressBarCurrentStep + 1,
					);
				}
				if ( progressBar && progressBarType == 'Progress Dots With Title' ) {
					const progressDots = progressBar.querySelectorAll( '.df-scc-progress-v2-dot' );
					const progressBarSteps = Number(
						progressBar.getAttribute( 'data-progress-steps' ),
					);
					const progressBarCurrentStep = Number(
						progressBar.getAttribute( 'data-progress-current-step' ),
					);
					const navigatedToStep = progressBarCurrentStep + 1;
					const progressDotIndex = navigatedToStep - 1;
					const navigatedToStepDot = progressDots[ progressDotIndex ];
					const navigatedToStepText = navigatedToStepDot.textContent.trim();
					const activeProgressDot = progressBar.querySelector( '.df-scc-progress-v2-dot.df-scc-progress-v2-current' );
					const activeProgressBarRight = activeProgressDot.nextElementSibling;

					activeProgressDot.classList.remove( 'df-scc-progress-v2-current' );
					activeProgressDot.classList.add( 'df-scc-progress-v2-done' );
					activeProgressBarRight.classList.add( 'df-scc-progress-v2-done' );
					navigatedToStepDot.classList.add( 'df-scc-progress-v2-current' );
					progressBarMobileTitle.textContent = navigatedToStepText;
					processBarMobileSubTitle.textContent = 'Step' + ' ' + navigatedToStep + '/' + progressBarSteps;
					progressBar.setAttribute(
						'data-progress-current-step',
						navigatedToStep,
					);
				}

				if ( progressBar && progressBarType == 'Progress Bar Steps With Title' ) {
					const progressBarSteps = Number(
						progressBar.getAttribute( 'data-progress-steps' ),
					);
					const progressBarCurrentStep = Number(
						progressBar.getAttribute( 'data-progress-current-step' ),
					);
					const unitProgress =
						( ( progressBarCurrentStep + 1 ) / progressBarSteps ) * 100;
					progressBar.setAttribute(
						'data-progress-current-step',
						progressBarCurrentStep + 1,
					);

					const progressBarAllSteps = [ ...progressBar.querySelectorAll( '.df-scc-bar-v3-item' ) ];
					const progressBarNextSteps = progressBarAllSteps.filter( ( z ) => z.getAttribute( 'data-complete' ) == 'false' );
					const progressBarNextStep = progressBarNextSteps[ 0 ];
					progressBarNextStep.setAttribute( 'data-complete', 'true' );

					const currentDoneSteps = progressBar.querySelectorAll( '.df-scc-bar-v3-item[data-complete="true"]' );
					const currentActiveStep = currentDoneSteps[ currentDoneSteps.length - 1 ];
					currentActiveStep.setAttribute( 'aria-selected', 'true' );
					progressBarAllSteps.forEach( ( step, index ) => {
						if ( currentActiveStep !== step ) {
							step.setAttribute( 'aria-selected', 'false' );
						}
					} );
					currentActiveStep.style.setProperty( '--df-scc-bar-v3-item-mobile-width', unitProgress + '%' );
				}
				if ( progressBar && progressBarType === 'Progress Dots with Numerical Steps' ) {
					const progressDots = progressBar.querySelectorAll( 'li' );
					const progressBarSteps = Number(
						progressBar.getAttribute( 'data-progress-steps' ),
					);
					const progressBarCurrentStep = Number(
						progressBar.getAttribute( 'data-progress-current-step' ),
					);
					const navigatedToStep = progressBarCurrentStep + 1;
					const progressDotIndex = navigatedToStep - 1;
					const navigatedToStepDot = progressDots[ progressDotIndex ];
					const activeProgressBarRight = navigatedToStepDot.previousElementSibling;
					const previousStepBubble = activeProgressBarRight.previousElementSibling;
					previousStepBubble.classList.add( 'df-scc-progress-v4-completed' );
					activeProgressBarRight.classList.add( 'df-scc-progress-v4-connecting-bar-done' );
					navigatedToStepDot.setAttribute( 'data-active-step', 'true' );
					const otherStepDots = [ ...progressDots ].filter( ( dot ) => dot !== navigatedToStepDot );
					otherStepDots.forEach( ( dot ) => {
						dot.setAttribute( 'data-active-step', 'false' );
					} );
					progressBar.setAttribute(
						'data-progress-current-step',
						navigatedToStep,
					);
				}

				showTargets.forEach( ( sectionId ) => {
					sccUtils.showSection( calcId, sectionId );
				} );
				hideTargets.forEach( ( sectionId ) => {
					sccUtils.hideSection( calcId, sectionId );
				} );
				if ( progressBar ) {
					if ( progressBar.parentElement.getBoundingClientRect().top < 0 ) {
						window.scrollTo( 0, sccGetOffset( progressBar.parentElement ).top - 120 );
						if ( window.self !== window.top ) {
							window.parent.postMessage( { action: 'scroll', top: ( sccGetOffset( progressBar.parentElement ).top - 120 ) }, '*' );
						}
					}
				}
			};

			nextBtn.onclick = goNextStepHandler;

			// Writing the first step title and the subtitle for mobile
			if ( progressBar && progressBarType == 'Progress Dots With Title' ) {
				const progressDots = progressBar.querySelectorAll( '.df-scc-progress-v2-dot' );
				const progressBarSteps = Number(
					progressBar.getAttribute( 'data-progress-steps' ),
				);
				const progressBarCurrentStep = Number(
					progressBar.getAttribute( 'data-progress-current-step' ),
				);
				const navigatedToStep = progressBarCurrentStep;
				const progressDotIndex = navigatedToStep - 1;
				const navigatedToStepDot = progressDots[ progressDotIndex ];
				const navigatedToStepText = navigatedToStepDot.textContent.trim();
				progressBarMobileTitle.textContent = navigatedToStepText;
				processBarMobileSubTitle.textContent = 'Step' + ' ' + navigatedToStep + '/' + progressBarSteps;
			}
		} );
	},
	findSectionTotalById: ( calcId, sectionId ) => {
		const sectionValue = sccUtils.getTotalValue( calcId ).sectionTotalValues.find( ( section ) => Number( Object.keys( section )[ 0 ] ) === Number( sectionId ) );
		if ( sectionValue ) {
			return sectionValue[ sectionId ];
		}
		return 0;
	},
	calculateVariableMathsWithLinkedItems: ( calcId, type, arraySectItems, element ) => {
		const variableMathsWithElementsAttached = itemsArray[ calcId ].filter(
			( e ) => e.type === 'math' && e.extraCalculationTasks?.length > 0,
		);
		const availableMathElements = itemsArray[ calcId ].filter(
			( e ) => e.type === 'math',
		);
		let valueAddedUpByElementsVariableMath = 0;
		availableMathElements.map( ( e, index ) => {
			const calcWrapper = sccUtils.getCalcWrapper( calcId );
			if ( calcWrapper.querySelector( '#ssc-elmt-' + e.id_element ).classList.contains( 'scc-conditional-hidden' ) ) {
				// emptying out the hidden variable math
				e.item_total_value = 0;
				e.price = [ 0, '' ];
				e.items_math = [];
				e.description = '';
				e.name = '';
				e.qtn = 0;
				valueAddedUpByElementsVariableMath = 0;
				return;
			}
			const vmathInputNode = null;
			const calculationData = [];
			const vmathRoot = sccUtils
				.getCalcWrapper( calcId )
				.querySelector( `#ssc-elmt-${ e.id_element }` );
			const showResultOnFrontend = vmathRoot.classList.contains( 'scc-show-result-front' );
			const hideCurrencySymbol = vmathRoot.classList.contains( 'scc-hide-currency-symbol' );
			const vmathSubsectionId = vmathRoot.getAttribute( 'data-subsection-id' );
			const calculationResultNode = vmathRoot
				.querySelector( `.vmath-result` );
			const useZeroAsFallback = vmathRoot.classList.contains( 'scc-use-zero-as-fallback' );
			let Stotal = vmathRoot
				.querySelector( '.totalmath' )
				.getAttribute( 'data-total' );
			const a = [];
			let des = '';
			let elm_qtn = '';
			const itemMathArray = [];
			vmathRoot
				.querySelectorAll( '.slider-styled, input.scc-vmath-input, input.scc-vmath-checkbox, input.scc-vmath-hidden' )
				.forEach( function( e, i ) {
					let price = e.getAttribute( 'data-price' );
					let qtn = e.value;
					let name = e.getAttribute( 'data-name' );
					const sperator = Number( i ) === 0 ? '' : ',';
					const isCheckbox = e.getAttribute( 'type' ) === 'checkbox';
					let processElement = true;
					if ( isCheckbox ) {
						qtn = e.checked ? 1 : 0;
					}
					if ( name === 'scc-vmath-element' ) {
						const attachedElementId = e.getAttribute( 'data-attached-element' );
						const attachedItemId = e.getAttribute( 'data-attached-element-item' );
						const vmathIsDropdown = Number( attachedItemId ) !== 0;
						const vmathLinkedElementType = e.getAttribute(
							'data-linked-element-type',
						) || 'unset';

						let linkedItem =
								itemsArray[ calcId ].find(
									( eq ) => String( eq.id_elementItem ) === attachedElementId,
								) ||
								itemsArray[ calcId ].find(
									( eq ) => String( eq.id_element ) === attachedElementId,
								);
						if ( vmathLinkedElementType === 'quantity box' ) {
							linkedItem = itemsArray[ calcId ].find(
								( eq ) => String( eq.id_element ) === attachedElementId,
							);
						}
						if ( vmathLinkedElementType === 'Dropdown Menu' ) {
							linkedItem = itemsArray[ calcId ].find(
								( eq ) => String( eq.element_id ) === attachedElementId,
							);
						}

						if ( linkedItem ) {
							const linkedItemValue = linkedItem.item_total_value;
							const linkedItemName = linkedItem.name;
							name = linkedItemName;
							price = linkedItemValue;
							qtn = 1;
							elm_qtn = linkedItem.qtn;
						} else {
							processElement = false;
						}
					}
					if ( name === 'scc-section-total' ) {
						const linkedSectionId = e.getAttribute( 'data-linked-section-id' );
						const linkedSectionObject = arraySectItems[ calcId ].find( ( sectionsTotal ) => String( sectionsTotal.id ) === linkedSectionId );
						if ( linkedSectionObject ) {
							qtn = 1;
							price = sccUtils.findSectionTotalById( calcId, linkedSectionId );
							name = linkedSectionObject?.name;
						} else {
							processElement = false;
						}
					}

					if ( processElement === true ) {
						des = `${ des } ${ sperator } ${ name } x ${ qtn }`;
						itemMathArray.push( { name, qtn, elm_qtn, price } );
						const placeholder = new RegExp( `\\bInput${ i + 1 }\\b`, 'g' );
						if ( Stotal.match( placeholder ) ) {
							Stotal = Stotal.replace( placeholder, qtn * price );
						}
						if ( Number( qtn ) == 0 && ! useZeroAsFallback ) {
							a.push( true );
						}
					}
				} );
			if ( a.includes( true ) ) {
				return 0;
			}
			try {
				// Handling slider in the same subsection as the variable math
				let vmathSubSectionSliderUnits = 1;
				Sliders[ calcId ].forEach( ( scs ) => {
					const g = vmathSubsectionId == scs[ 4 ];
					if ( g ) {
						vmathSubSectionSliderUnits = scs[ 1 ];
					}
				} );
				elementDate[ calcId ].forEach( ( scs ) => {
					const g = vmathSubsectionId == scs[ 5 ];
					if ( g && [ 'quantity_modifier_and_unit_price', 'quantity_mod' ].includes( scs[ 4 ] ) ) {
						vmathSubSectionSliderUnits = scs[ 3 ];
					}
				} );

				const newVariableMathValue = [ sccEvalMathExpression( Stotal ), des, 0 ];
				newVariableMathValue[ 3 ] = newVariableMathValue[ 0 ] * vmathSubSectionSliderUnits;

				e.item_total_value = newVariableMathValue[ 0 ];
				e.price = newVariableMathValue;
				e.items_math = itemMathArray;

				const parentSectionIndex = arraySectItems[ calcId ].findIndex(
					( q ) => q.id == e.parentSectionId,
				);
				if ( showResultOnFrontend ) {
					e.description = newVariableMathValue[ 1 ];
				}
			} catch ( e ) {
				//console.error( 'The total formula in variable math is invalid ' );
				return 0;
			}
			if ( calculationResultNode && ( showResultOnFrontend && hideCurrencySymbol ) ) {
				calculationResultNode.textContent = e.price[ 0 ];
				calculationResultNode.classList.remove( 'scc-d-none' );
				return 0;
			}
			if ( calculationResultNode && showResultOnFrontend ) {
				calculationResultNode.textContent = getCurrencyText(
					calcId,
					e.price[ 0 ],
				);
				calculationResultNode.classList.remove( 'scc-d-none' );
			}
		} );

		if (
			variableMathsWithElementsAttached.length === 0 &&
			Number( type ) === 8 &&
			availableMathElements.length === 0
		) {
			sccUtils
				.getCalcWrapper( calcId )
				.querySelector( `#ssc-elmt-${ element } span.vmath-result` )
				?.classList.add( 'scc-d-none' );
		}
	},
	getTotalValue: ( calcId = null, calculateGlobalCustomMaths = false ) => {
		const itemsSelected = itemsArray[ calcId ].filter( ( z ) => ! z.hasOwnProperty( 'sectionId' ) );
		const sectionsAvailable = itemsArray[ calcId ].filter( ( z ) => z.hasOwnProperty( 'sectionId' ) );
		// group the itemsSelected by sectionId and the subsectionIndex
		const groupedItems = itemsSelected.reduce( ( acc, item ) => {
			const { parentSectionId, subSectionIndex } = item;
			const key = `${ parentSectionId }-${ subSectionIndex }`;
			if ( ! acc[ key ] ) {
				acc[ key ] = [];
			}
			acc[ key ].push( item );
			return acc;
		}, {} );
		// for each group, add up the value in `item_total_value`, skip the hidden items, and later apply custom math on the total

		Object.keys( groupedItems ).forEach( ( key ) => {
			const items = groupedItems[ key ];
			const itemsExcludingCustomMath = items.filter( ( item ) => item.type !== 'customMath' );
			const customMathItems = items.filter( ( item ) => {
				if ( globalMathArray[ calcId ].findIndex( ( m ) => m.customMathId === item.id_element ) > -1 ) {
					return false;
				}
				return item.type === 'customMath';
			} );
			let total = itemsExcludingCustomMath.filter( ( item ) => item.type !== 'customMath' ).reduce( ( acc, item ) => {
				const { item_total_value, hidden } = item;
				if ( hidden ) {
					return acc;
				}
				return acc + sccUtils.roundNumber( Number( item_total_value || 0 ), calcId );
			}, 0 );
			// apply custom math on the total
			const allCustomMathTotal = customMathItems.reduce( ( acc, item ) => {
				const { item_total_value, hidden, description: action } = item;
				let customMathTotal = 0;
				if ( hidden ) {
					return acc;
				}
				switch ( action ) {
					case '+':
						customMathTotal = total + Number( item.price || 0 );
						break;
					case '-':
						customMathTotal = total - Number( item.price || 0 );
						break;
					case 'x':
						customMathTotal = total * Number( item.price || 0 );
						break;
					case '/':
						customMathTotal = total / Number( item.price || 0 );
						item.item_total_value = customMathTotal;
						break;
					case '%':
						customMathTotal = total * ( Number( item.price || 0 ) / 100 );
						item.item_total_value = customMathTotal;
						break;
					default:
						break;
				}
				if ( ! Boolean( item.item_total_value ) ) {
					item.item_total_value = customMathTotal - total;
				}
				return acc + Number( customMathTotal || 0 );
			}, 0 );
			total = total + allCustomMathTotal;
			return ( { [ key ]: total } );
		} );
		// using reduce, add up the value in `item_total_value` in itemsSelected
		let total = itemsSelected.reduce( ( acc, item ) => {
			const { item_total_value, hidden } = item;
			if ( hidden ) {
				return acc;
			}
			return acc + sccUtils.roundNumber( Number( item_total_value || 0 ), calcId );
		}, 0 );
		if ( calculateGlobalCustomMaths ) {
			globalMathArray[ calcId ].forEach( ( m ) => {
				const matchedItemObject = itemsArray[ calcId ].find( ( e ) => e.id_element == m.customMathId );
				if ( ! matchedItemObject || total <= 0 || matchedItemObject.hidden ) {
					return;
				}
				if ( Number( ( itemsArray[ calcId ].find(
					( e ) => e.id_element == m.customMathId,
				).item_total_value ) || 0 ) > 0 ) {
					return;
				}
				if ( m.value == '' ) {
					m.value = 0;
				}
				const preMathTotal = total;
				let mathResult = 0;
				switch ( m.action ) {
					case '+':
						mathResult = total + Number( m.value );
						total = mathResult;
						itemsArray[ calcId ].find(
							( e ) => e.id_element == m.customMathId,
						).item_total_value = Number( m.value );
						break;
					case '-':
						mathResult = preMathTotal - Number( m.value );
						total = mathResult;
						itemsArray[ calcId ].find(
							( e ) => e.id_element == m.customMathId,
						).item_total_value = Number( m.value ) * -1;
						break;
					case 'x':
						mathResult = preMathTotal * Number( m.value );
						total = preMathTotal + mathResult;
						itemsArray[ calcId ].find(
							( e ) => e.id_element == m.customMathId,
						).item_total_value = mathResult;
						break;
					case '/':
						mathResult = preMathTotal / Number( m.value );
						total = preMathTotal + mathResult;
						itemsArray[ calcId ].find(
							( e ) => e.id_element == m.customMathId,
						).item_total_value = mathResult;
						break;
					case '%':
						mathResult = preMathTotal * ( Number( m.value ) / 100 );
						total = preMathTotal + mathResult;
						itemsArray[ calcId ].find(
							( e ) => e.id_element == m.customMathId,
						).item_total_value = mathResult;
						break;
					default:
						break;
				}
			} );
		}
		const sectionTotalValues = sectionsAvailable.map( ( z ) => {
			const sectionItems = itemsSelected.filter( ( x ) => Number( x.parentSectionId ) === Number( z.sectionId ) );
			const sectionTotal = sectionItems.reduce( ( acc, item ) => {
				const { item_total_value, hidden } = item;
				if ( hidden ) {
					return acc;
				}
				return acc + sccUtils.roundNumber( Number( item_total_value || 0 ), calcId );
			}, 0 );
			return ( { [ z.sectionId ]: sectionTotal } );
		} );

		// round the total to the calculator config's rounding type
		total = sccUtils.roundNumber( total, calcId );
		return { total, sectionTotalValues, globalMaths: globalMathArray[ calcId ] };
	},
	roundNumber( numberToRound, calcId ) {
		if ( Number( numberToRound ) === .001 ) {
			return 0.001;
		}
		try {
			const { config: sccConfig } = sccData[ calcId ];
			const roundingConfig = sccConfig.price_rounding_config;
			if ( roundingConfig ) {
				const roundingType = roundingConfig.rounding_type;

				if ( typeof numberToRound === 'undefined' || numberToRound === null || isNaN( numberToRound ) ) {
					return numberToRound;
				}
				switch ( roundingType ) {
					case 'nearest_tenth':
						numberToRound = Math.round( numberToRound * 10 ) / 10;
						break;
					case 'nearest_whole_number':
						numberToRound = Math.round( numberToRound );
						break;
					case 'nearest_tens':
						numberToRound = Math.round( numberToRound / 10 ) * 10;
						break;
					case 'nearest_hundreds':
						numberToRound = Math.round( numberToRound / 100 ) * 100;
						break;
					case 'nearest_thousands':
						numberToRound = Math.round( numberToRound / 1000 ) * 1000;
						break;
					default:
						break;
				}
			}
			return numberToRound;
		} catch ( error ) {
			console.error( 'An error occurred: ', error );
		}
	},
	detectStripePaymentMethod: ( calcId ) => {
		return sccData[ calcId ].config.enableStripe;
	},
	validatePhoneNumberInput: ( phoneInputBox, calcId ) => {
		phoneInputBox.addEventListener( 'input', ( evt ) => {
			// checking if twilio based texting is enabled
			const isTextingEnabled = sccData[ calcId ].config?.isTextingEnabled || false;
			if ( isTextingEnabled ) {
				// ensure there is a country code in the input box, checking for a plus sign
				const hasCountryCode = evt.target.value.includes( '+' );
				if ( ! hasCountryCode ) {
					// if there is no country code, add it
					evt.target.value = `+${ evt.target.value }`;
				}
			}
			// https://regexr.com/38pvb
			const phoneRegex = new RegExp(
				/^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm,
			);
			const inputData = evt.target.value;
			let maxAllowedDigits =
				phoneInputBox.getAttribute( 'data-max-allowed-digits' ) || 0;
			if ( maxAllowedDigits && maxAllowedDigits > 0 && isTextingEnabled ) {
				maxAllowedDigits = Number( maxAllowedDigits );
				maxAllowedDigits += 4; // to account for the country code
			}
			const inputBoxName = evt.target.getAttribute( 'name' );
			const inputBoxWarning = evt.target
				.closest( '.df-scc-euiFormRow' )
				.querySelector( `.text-danger[data-for="${ inputBoxName }"]` );
			let isMatch = phoneRegex.test( inputData );
			if ( isMatch && maxAllowedDigits > 0 ) {
				isMatch =
					[ ...inputData ].filter(
						( e ) => e.charCodeAt( 0 ) >= 48 && e.charCodeAt( 0 ) <= 57,
					).length <= maxAllowedDigits;
			}
			if ( isMatch || inputData.length === 0 ) {
				inputBoxWarning.classList.add( 'scc-d-none' );
				inputBoxWarning.removeAttribute( 'data-has-errors' );
				return;
			}
			inputBoxWarning.textContent = 'Invalid Phone Number';
			inputBoxWarning.classList.remove( 'scc-d-none' );
			inputBoxWarning.setAttribute( 'data-has-errors', 1 );
		} );
	},
	handleUserCurrencySwitch: ( calcId, chosenCurrency ) => {
		sccUtils.updateCurrencyRates( calcId, chosenCurrency );
	},
	updateCurrencyRates: ( calcId, userCurrencySelection = null ) => {
		let {
			currencyCode,
			currency_conversion_mode,
			currency_conversion_selection,
		} = sccData[ calcId ].config;

		if ( userCurrencySelection ) {
			currency_conversion_selection = userCurrencySelection;
			currency_conversion_mode = 'manual_selection';
		}

		if ( currency_conversion_mode != 'off' ) {
			const handleResponse = ( res ) => {
				const o = res.result + '/' + ( userCurrencySelection || res.converted_to_currency );
				sccData[ calcId ].config.scc_rate_label_convertion = o;
				sccUtils.updateConvertedCurrencyText( calcId );

				if ( ! userCurrencySelection ) {
					const currencyDropdown = sccUtils.getCalcWrapper( calcId ).querySelector( '.scc-choose-currency-dd' );
					if ( currencyDropdown ) {
						currencyDropdown.value = currency_conversion_selection;
					}
				}
			};

			if ( currency_conversion_mode == 'manual_selection' ) {
				const apiUrl = `https://api.stylishcostcalculator.com/rest/currency-convert?from=${ currencyCode }&to=${ currency_conversion_selection }`;

				fetch( apiUrl )
					.then( ( response ) => response.json() )
					.then( handleResponse )
					.catch( ( err ) => console.error( err ) );
			} else if ( currency_conversion_mode == 'auto_detect' ) {
				const apiUrl = `https://api.stylishcostcalculator.com/rest/currency-convert?from=${ currencyCode }&to=DETECT`;

				fetch( apiUrl )
					.then( ( response ) => response.json() )
					.then( handleResponse )
					.catch( ( err ) => console.error( err ) );
			}
		}
	},
	updateConvertedCurrencyText: ( calcId, totalAmount = null ) => {
		if ( typeof ( sccData[ calcId ].pdf ) === 'undefined' ) {
			return;
		}
		if ( totalAmount === null ) {
			totalAmount = sccData[ calcId ].pdf.total;
		}
		const { scc_rate_label_convertion, priceRangeTotalSettings, currencyCode, allowCurrencySwitching, enable_floating_totalbar } = sccData[ calcId ].config;
		const container = sccUtils.getCalcWrapper( calcId );
		let curr_convert_text = container.querySelector( '.df_scc_cc_span' );
		if ( enable_floating_totalbar ) {
			curr_convert_text = document.querySelector( `#scc-total-bar-${ calcId } .df-scc-tp5-conv-total` );
		}
		const rate_label = scc_rate_label_convertion.split( '/' );
		const rate = rate_label[ 0 ];
		const label = rate_label[ 1 ];
		const total_converted = parseFloat( rate ) * totalAmount;
		const isPriceRangeActive = 0 !== Number( priceRangeTotalSettings.rangePercent );
		// Detecting if the currency is same as the one currently being used
		if ( label != currencyCode && curr_convert_text ) {
			if ( ! isPriceRangeActive ) {
				curr_convert_text.innerHTML =
					getCurrencyText( calcId, total_converted, label );
			}
			if ( isPriceRangeActive ) {
				const rangeAppliedConvertedTotal = sccUtils.applyRangeTotal( parseFloat( total_converted ), priceRangeTotalSettings );
				const originalConvertedTotalText = getCurrencyText( calcId, parseFloat( total_converted ), label );
				const rangeAppliedConvertedTotalText = getCurrencyText( calcId, rangeAppliedConvertedTotal, label );
				curr_convert_text.innerHTML = Number( total_converted ) == 0 ? originalConvertedTotalText : originalConvertedTotalText + ' - ' + rangeAppliedConvertedTotalText;
			}
		}
		if ( label == currencyCode && allowCurrencySwitching != 1 ) {
			if ( curr_convert_text ) {
				curr_convert_text.classList.add( 'scc-d-none' );
			}
			if ( container.querySelector( '._convertion' ) ) {
				container.querySelector( '._convertion' ).classList.add( 'scc-d-none' );
			}
		}
		return total_converted;
	},
	Mailcheck: {
		domainThreshold: 2,
		secondLevelThreshold: 2,
		topLevelThreshold: 2,

		defaultDomains: [ 'msn.com', 'bellsouth.net',
			'telus.net', 'comcast.net', 'optusnet.com.au',
			'earthlink.net', 'qq.com', 'sky.com', 'icloud.com',
			'mac.com', 'sympatico.ca', 'googlemail.com',
			'att.net', 'xtra.co.nz', 'web.de',
			'cox.net', 'gmail.com', 'ymail.com',
			'aim.com', 'rogers.com', 'verizon.net',
			'rocketmail.com', 'google.com', 'optonline.net',
			'sbcglobal.net', 'aol.com', 'me.com', 'btinternet.com',
			'charter.net', 'shaw.ca' ],

		defaultSecondLevelDomains: [ 'yahoo', 'hotmail', 'mail', 'live', 'outlook', 'gmx' ],

		defaultTopLevelDomains: [ 'com', 'com.au', 'com.tw', 'ca', 'co.nz', 'co.uk', 'de',
			'fr', 'it', 'ru', 'net', 'org', 'edu', 'gov', 'jp', 'nl', 'kr', 'se', 'eu',
			'ie', 'co.il', 'us', 'at', 'be', 'dk', 'hk', 'es', 'gr', 'ch', 'no', 'cz',
			'in', 'net', 'net.au', 'info', 'biz', 'mil', 'co.jp', 'sg', 'hu' ],

		run( opts ) {
			opts.domains = opts.domains || sccUtils.Mailcheck.defaultDomains;
			opts.secondLevelDomains = opts.secondLevelDomains || sccUtils.Mailcheck.defaultSecondLevelDomains;
			opts.topLevelDomains = opts.topLevelDomains || sccUtils.Mailcheck.defaultTopLevelDomains;
			opts.distanceFunction = opts.distanceFunction || sccUtils.Mailcheck.sift3Distance;

			const defaultCallback = function( result ) {
				return result;
			};
			const suggestedCallback = opts.suggested || defaultCallback;
			const emptyCallback = opts.empty || defaultCallback;

			const result = sccUtils.Mailcheck.suggest( sccUtils.Mailcheck.encodeEmail( opts.email ), opts.domains, opts.secondLevelDomains, opts.topLevelDomains, opts.distanceFunction );

			return result ? suggestedCallback( result ) : emptyCallback();
		},

		suggest( email, domains, secondLevelDomains, topLevelDomains, distanceFunction ) {
			email = email.toLowerCase();

			const emailParts = this.splitEmail( email );

			if ( secondLevelDomains && topLevelDomains ) {
				// If the email is a valid 2nd-level + top-level, do not suggest anything.
				if ( secondLevelDomains.indexOf( emailParts.secondLevelDomain ) !== -1 && topLevelDomains.indexOf( emailParts.topLevelDomain ) !== -1 ) {
					return false;
				}
			}

			var closestDomain = this.findClosestDomain( emailParts.domain, domains, distanceFunction, this.domainThreshold );

			if ( closestDomain ) {
				if ( closestDomain == emailParts.domain ) {
				// The email address exactly matches one of the supplied domains; do not return a suggestion.
					return false;
				}
				// The email address closely matches one of the supplied domains; return a suggestion
				return { address: emailParts.address, domain: closestDomain, full: emailParts.address + '@' + closestDomain };
			}

			// The email address does not closely match one of the supplied domains
			const closestSecondLevelDomain = this.findClosestDomain( emailParts.secondLevelDomain, secondLevelDomains, distanceFunction, this.secondLevelThreshold );
			const closestTopLevelDomain = this.findClosestDomain( emailParts.topLevelDomain, topLevelDomains, distanceFunction, this.topLevelThreshold );

			if ( emailParts.domain ) {
				var closestDomain = emailParts.domain;
				let rtrn = false;

				if ( closestSecondLevelDomain && closestSecondLevelDomain != emailParts.secondLevelDomain ) {
				// The email address may have a mispelled second-level domain; return a suggestion
					closestDomain = closestDomain.replace( emailParts.secondLevelDomain, closestSecondLevelDomain );
					rtrn = true;
				}

				if ( closestTopLevelDomain && closestTopLevelDomain != emailParts.topLevelDomain ) {
				// The email address may have a mispelled top-level domain; return a suggestion
					closestDomain = closestDomain.replace( emailParts.topLevelDomain, closestTopLevelDomain );
					rtrn = true;
				}

				if ( rtrn == true ) {
					return { address: emailParts.address, domain: closestDomain, full: emailParts.address + '@' + closestDomain };
				}
			}

			/* The email address exactly matches one of the supplied domains, does not closely
			* match any domain and does not appear to simply have a mispelled top-level domain,
			* or is an invalid email address; do not return a suggestion.
			*/
			return false;
		},

		findClosestDomain( domain, domains, distanceFunction, threshold ) {
			threshold = threshold || this.topLevelThreshold;
			let dist;
			let minDist = 99;
			let closestDomain = null;

			if ( ! domain || ! domains ) {
				return false;
			}
			if ( ! distanceFunction ) {
				distanceFunction = this.sift3Distance;
			}

			for ( let i = 0; i < domains.length; i++ ) {
				if ( domain === domains[ i ] ) {
					return domain;
				}
				dist = distanceFunction( domain, domains[ i ] );
				if ( dist < minDist ) {
					minDist = dist;
					closestDomain = domains[ i ];
				}
			}

			if ( minDist <= threshold && closestDomain !== null ) {
				return closestDomain;
			}
			return false;
		},

		sift3Distance( s1, s2 ) {
			// sift3: http://siderite.blogspot.com/2007/04/super-fast-and-accurate-string-distance.html
			if ( s1 == null || s1.length === 0 ) {
				if ( s2 == null || s2.length === 0 ) {
					return 0;
				}
				return s2.length;
			}

			if ( s2 == null || s2.length === 0 ) {
				return s1.length;
			}

			let c = 0;
			let offset1 = 0;
			let offset2 = 0;
			let lcs = 0;
			const maxOffset = 5;

			while ( ( c + offset1 < s1.length ) && ( c + offset2 < s2.length ) ) {
				if ( s1.charAt( c + offset1 ) == s2.charAt( c + offset2 ) ) {
					lcs++;
				} else {
					offset1 = 0;
					offset2 = 0;
					for ( let i = 0; i < maxOffset; i++ ) {
						if ( ( c + i < s1.length ) && ( s1.charAt( c + i ) == s2.charAt( c ) ) ) {
							offset1 = i;
							break;
						}
						if ( ( c + i < s2.length ) && ( s1.charAt( c ) == s2.charAt( c + i ) ) ) {
							offset2 = i;
							break;
						}
					}
				}
				c++;
			}
			return ( s1.length + s2.length ) / 2 - lcs;
		},

		splitEmail( email ) {
			const parts = email.trim().split( '@' );

			if ( parts.length < 2 ) {
				return false;
			}

			for ( var i = 0; i < parts.length; i++ ) {
				if ( parts[ i ] === '' ) {
					return false;
				}
			}

			const domain = parts.pop();
			const domainParts = domain.split( '.' );
			let sld = '';
			let tld = '';

			if ( domainParts.length == 0 ) {
			// The address does not have a top-level domain
				return false;
			} else if ( domainParts.length == 1 ) {
			// The address has only a top-level domain (valid under RFC)
				tld = domainParts[ 0 ];
			} else {
			// The address has a domain and a top-level domain
				sld = domainParts[ 0 ];
				for ( var i = 1; i < domainParts.length; i++ ) {
					tld += domainParts[ i ] + '.';
				}
				tld = tld.substring( 0, tld.length - 1 );
			}

			return {
				topLevelDomain: tld,
				secondLevelDomain: sld,
				domain,
				address: parts.join( '@' ),
			};
		},

		// Encode the email address to prevent XSS but leave in valid
		// characters, following this official spec:
		// http://en.wikipedia.org/wiki/Email_address#Syntax
		encodeEmail( email ) {
			let result = encodeURI( email );
			result = result.replace( '%20', ' ' ).replace( '%25', '%' ).replace( '%5E', '^' )
				.replace( '%60', '`' ).replace( '%7B', '{' ).replace( '%7C', '|' )
				.replace( '%7D', '}' );
			return result;
		},
	},
	detectMisSpelledEmail: ( emailInputField ) => {
		const email = emailInputField.value;
		const emailWarning = emailInputField.form?.querySelector( "p[data-for='email']" );
		if ( emailWarning && email.length > 0 ) {
			if ( email.indexOf( '@' ) == -1 || email.indexOf( '.' ) == -1 ) {
				emailWarning.classList.add( 'scc-d-none' );
			} else {
				sccUtils.Mailcheck.run( {
					email,
					suggested( suggestion ) {
					  emailWarning.innerHTML = `Did you mean <span>${ suggestion.full }</span>?`;
					  emailWarning.addEventListener( 'click', function() {
							emailInputField.value = suggestion.full;
							emailWarning.classList.add( 'scc-d-none' );
					  }, { once: true } );
					  emailWarning.classList.remove( 'scc-d-none' );
					},
					empty() {
					  emailWarning.classList.add( 'scc-d-none' );
					},
				} );
			}
		}
	},
	validateEmail: ( event, detectFreeEmail = false ) => {
		const emailInputField = event.target;
		const { emailCalcId } = emailInputField.dataset;
		// If the email input field has no errors, then validate the email
		const email = emailInputField.value;
		const emailWarning = emailInputField.form?.querySelector( "p[data-for='email']" );
		if ( emailWarning && email.length > 0 ) {
			const validatorRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
			const emailEvaluation = validatorRegex.test( email );
			let freeEmailEvaluation = true;
			if ( detectFreeEmail ) {
				freeEmailEvaluation = sccUtils.isFreeEmail( email );
			}
			if ( ! emailEvaluation || ! freeEmailEvaluation ) {
				if ( ! freeEmailEvaluation ) {
					emailWarning.innerHTML = sccGetTranslationByKey( emailCalcId, `Please use a business email address` );
				}
				if ( ! emailEvaluation ) {
					emailWarning.innerHTML = sccGetTranslationByKey( emailCalcId, `Please use a valid email address` );
				}
				emailWarning.classList.remove( 'scc-d-none' );
				emailInputField.form?.setAttribute( 'data-has-errors', 1 );
			} else {
				emailWarning.classList.add( 'scc-d-none' );
				emailInputField.form?.setAttribute( 'data-has-errors', 0 );
			}
		}
	},
	isFreeEmail: ( email ) => {
		if ( email.length > 0 ) {
			const freeEmailRootDomains = [
				'gmail',
				'yahoo',
				'hotmail',
				'aol',
				'msn',
				'wanadoo',
				'orange',
				'comcast',
				'live',
				'rediffmail',
				'free',
				'gmx',
				'web',
				'yandex',
				'ymail',
				'libero',
				'outlook',
				'uol',
				'bol',
				'mail',
				'cox',
				'sbcglobal',
				'sfr',
				'verizon',
				'googlemail',
				'ig',
				'bigpond',
				'terra',
				'neuf',
				'alice',
				'rocketmail',
				'att',
				'laposte',
				'facebook',
				'bellsouth',
				'charter',
				'rambler',
				'tiscali',
				'shaw',
				'protonmail',
				'inbox',
				'zoho',
				'icloud',
				'mailinator',
				'hushmail',
				'fastmail',
				'tutanota',
				'guerrillamail',
				'10minutemail',
				'yopmail',
				'gawab',
				'shortmail',
				'myway',
				'lycos',
				'excite',
				'mozillamail',
			  ];
			  const emailDomain = email.includes( '@' ) && email.includes( '.' ) ? email.split( '@' )[ 1 ].split( '.' )[ 0 ] : null; // Extract root domain
			if ( emailDomain && freeEmailRootDomains.includes( emailDomain ) ) {
				return false;
			}
			return true;
		}
	},
	handleQuantityInput: ( quantityInputBox, event ) => {
		const elementId = quantityInputBox.getAttribute( 'data-element-id' );
		const calcId = quantityInputBox.getAttribute( 'data-calc-id' );
		const addComma = quantityInputBox.getAttribute( 'data-add-comma' ) == '1';
		// After the input is changed, check if the value is within the min and max range
		if ( [ 'change', 'blur' ].includes( event.type ) ) {
			const maxValue = Number( quantityInputBox.getAttribute( 'max' ) );
			const minValue = Number( quantityInputBox.getAttribute( 'min' ) );
			const inputValueSanitized = quantityInputBox.value.replace( /,/g, '' );
			const currentValue = Number( inputValueSanitized );
			if ( maxValue && currentValue > maxValue ) {
				quantityInputBox.value = maxValue;
			}
			if ( minValue && currentValue < minValue ) {
				quantityInputBox.value = minValue;
			}
		}
		addComma && dfSccInputBoxAddCommas( quantityInputBox );
		triggerSubmit( 3, quantityInputBox, elementId, 0, calcId );
	},

	handleMinimumTotal: ( container, hideMinimumTotalWarning, mode, calcId ) => {
		function toggleElementsDisplay( selectors, displayClass, useDocumentSelector = false ) {
			selectors.forEach( ( selector ) => {
				const elem = useDocumentSelector ? document.querySelector( selector ) : container.querySelector( selector );
				if ( elem ) {
					if ( displayClass ) {
						// If a class is provided, add it
						elem.classList.add( displayClass );
						// Also, we should handle the possible conflicting classes
						if ( displayClass === 'scc-hidden' ) {
							elem.classList.remove( 'scc-show-inline-flex' );
						} else if ( displayClass === 'scc-show-inline-flex' ) {
							elem.classList.remove( 'scc-hidden' );
						}
					} else {
						// If no class is provided, remove both possible classes
						elem.classList.remove( 'scc-hidden', 'scc-show-inline-flex' );
					}
				}
			} );
		}

		if ( hideMinimumTotalWarning ) {
			toggleElementsDisplay( [ 'span.scc-minimum-msg' ], 'scc-hidden' );
			toggleElementsDisplay( [ '.scc-btn-hndle:not(.scc-pagination-nav)', '.totalPrice', '.total1', '.total12', '.total2', '.total22' ], '' );
			toggleElementsDisplay( [ `#scc-total-bar-${ calcId }.df-scc-tp5-root` ], '', true );
		} else {
			if ( mode === 'show-notice' ) {
				toggleElementsDisplay( [ 'span.scc-minimum-msg' ], 'scc-show-inline-flex' );
			}
			if ( mode === 'silent-hide' ) {
				toggleElementsDisplay( [ '.totalPrice', '.total1', '.total12', '.total2', '.total22', '.scc-btn-hndle:not(.scc-pagination-nav)' ], 'scc-hidden' );
				toggleElementsDisplay( [ `#scc-total-bar-${ calcId }.df-scc-tp5-root` ], 'scc-hidden', true );
				toggleElementsDisplay( [ 'span.scc-minimum-msg' ], 'scc-show-inline-flex' );
			}
			if ( mode === 'notice-no-uab' ) {
				toggleElementsDisplay( [ 'span.scc-minimum-msg' ], 'scc-show-inline-flex' );
				toggleElementsDisplay( [ '.totalPrice', '.total1', '.total12', `#scc-total-bar-${ calcId }.df-scc-tp5-root`, '.total2', '.total22' ], 'scc-d-initial' );
				toggleElementsDisplay( [ `#scc-total-bar-${ calcId }.df-scc-tp5-root` ], 'scc-d-initial', true );
				toggleElementsDisplay( [ '.scc-btn-hndle:not(.scc-pagination-nav)' ], 'scc-hidden' );
			}
		}
	},
};
window.sccUtils = sccUtils;

function sccDebounce( func, wait ) {
	let timeout;
	return function( ...args ) {
		const context = this;
		clearTimeout( timeout );
		timeout = setTimeout( () => func.apply( context, args ), wait );
	};
}
window.sccDebounce = sccDebounce;

const detectMisSpelledEmailWithDebounce = sccDebounce( sccUtils.detectMisSpelledEmail, 3000 );
window.detectMisSpelledEmailWithDebounce = detectMisSpelledEmailWithDebounce;

function sccGetOffset( el ) {
	const rect = el.getBoundingClientRect();
	return {
		left: rect.left + window.scrollX,
		top: rect.top + window.scrollY,
	};
}
window.sccGetOffset = sccGetOffset;
function sccWhenAvailable( name, callback ) {
	const interval = 10; // ms
	window.setTimeout( function() {
		if ( window[ name ] ) {
			callback();
		} else {
			sccWhenAvailable( name, callback );
		}
	}, interval );
}
window.sccWhenAvailable = sccWhenAvailable;

function initiateQuoteForm( calcId, postSubmitCallbackFn = null, preSubmitCallbackFn = null, quoteFormTitle = 'Email Quote Form' ) {
	if ( ! sccUtils.isMinimumTotalMet( calcId ) ) {
		return;
	}
	if ( ! verifiedMandatoryItems( calcId ) ) {
		return;
	}

	const config = sccData[ calcId ].config;
	if ( ! sccData[ calcId ].pdf ) {
		const warningNode = document.querySelector(
			`#scc_form_${ calcId } .scc-warning.no-items-selected`,
		);
		warningNode.classList.remove( 'scc-d-none' );
		//console.log("step#1");
		setTimeout( () => {
			warningNode.classList.add( 'scc-d-none' );
		}, 5000 );
		return;
	}
	const { objectColor, formname: calcName, quoteFormFields, quoteRecipient } = config;

	const { isCaptchaEnabled, recaptchaSiteKey } = config.captcha;
	const { disableUnitColumn, disableQtyColumn, showPriceColumn, showPdfNameOnQuoteForm } = config.pdf;
	/**
	 * Apply css
	 */
	const fontName = config.fontConfig.serviceFont;
	let titleColor = config.section_title.color;
	const showFormInDetail = config.showFormInDetail;
	let serviceColor = config.service.color;
	let objectColors = config.objectColor;
	if ( titleColor === '#ffffff' || titleColor === '#fff' || titleColor === 'white' ) {
		titleColor = '#000000';
	}
	if ( serviceColor === '#ffffff' || serviceColor === '#fff' || serviceColor === 'white' ) {
		serviceColor = '#000000';
	}
	if ( objectColors === '#ffffff' || objectColors === '#fff' || objectColors === 'white' ) {
		objectColors = '#000000';
	}

	const css = `.df-scc-euiModalHeader__title,
	.df-scc-euiFormLabel.df-scc-euiFormRow__label,
	.modal .df-scc-euiText,
	.modal .df-scc-euiModal,
	.df-scc-euiButtonContent.df-scc-euiButtonEmpty__content,
	.df-scc-euiButton.df-scc-euiButton--primary.df-scc-euiButton--fill {
		font-family : ${ fontName };
	}
	.df-scc-euiModalHeader__title,
	.scc-stripe-sub-price {
		color:${ titleColor };
	}
	.df-scc-euiFormRow__label,
	.df-scc-quote-pdf-name {
		color:${ serviceColor };
	}
	.df-scc-euiButtonEmpty__text{
		color:${ objectColors };
	}
	.df-scc-euiButtonContent.df-scc-euiButton__content{
		background-color:${ objectColors };
		border-color:${ objectColors };
		border-radius:3px;
	}
	.label-cbx-45 input:checked+.checkbox {
		border-color: ${ objectColors }
	}
	.label-cbx-45 input:checked+.checkbox svg path {
		fill: ${ objectColors }
	}
	.label-cbx-45 .checkbox svg path {
		stroke: ${ objectColors }
	}
	input.df-scc-euiFieldText:focus, .scc-stripe-element-focus {
		background-image: linear-gradient(to top, ${ objectColors }, ${ objectColors } 2px, transparent 2px, transparent 100%);
		color: ${ objectColors };
	}`;

	const head = document.head || document.getElementsByTagName( 'head' )[ 0 ];
	const styleJS = document.createElement( 'style' );
	styleJS.setAttribute( 'id', 'scc-quotemodal' );
	styleJS.type = 'text/css';
	if ( styleJS.styleSheet ) {
		styleJS.styleSheet.cssText = css;
	} else {
		styleJS.appendChild( document.createTextNode( css ) );
	}

	document.querySelector( '#scc-quotemodal' )?.remove();
	document.head.appendChild( styleJS );

	if ( verifiedMandatoryItems( calcId ) ) {
		document.querySelectorAll( '.scc-mandatory-msg' ).forEach( ( e ) => {
			e.classList.add( 'scc-hidden' );
		} );

		const quoteFormPlaceholder = document.querySelector( '#quote-form-placeholder' );

		quoteFormPlaceholder.addEventListener( 'hidden.bs.modal', function() {
			this.classList.remove( 'show' );
		} );

		jQuery( function() {
			jQuery( '.scc-phone-input input[type="phone"]' ).on( 'input', function() {
				const cleanedPhoneNumber = jQuery( this ).val().replace( /\D/g, '' );

				const countryCodeOptions = jQuery( this ).parents( '.scc-phone-input' ).find( '.country-code-select option' );
				const currentCountryCode = jQuery( this ).parents( '.scc-phone-input' ).find( '.country-code-select' ).val();
				const previousMatchCodeLength = 0;

				countryCodeOptions.each( function() {
					if ( new RegExp( '^' + jQuery( this ).attr( 'value' ) ).test( cleanedPhoneNumber ) ) {
						const currentMatchCodeLength = jQuery( this ).attr( 'value' ).length;
						if ( new RegExp( '^' + jQuery( this ).attr( 'value' ) ).test( currentCountryCode ) && currentCountryCode.length >= currentMatchCodeLength ) {
							return false;
						}
					}
				} );

				if ( jQuery( this ).data( 'auto-format' ) === 1 ) {
					const formatedNumber = phoneFormat( cleanedPhoneNumber ).replace( '+', '' );
					jQuery( this ).val( formatedNumber );
				}
			} );

			jQuery( '.scc-phone-input .country-code-select' ).change( function() {
				const selectedChoiceSplitted = jQuery( this ).children( 'option:selected' ).html().split( ' ' );
				const flag = selectedChoiceSplitted[ 0 ];
				const countryCode = selectedChoiceSplitted[ 2 ];
				const label = jQuery( this ).siblings( 'label' );
				label.html( flag + ' ' + countryCode );
				label.removeClass( 'scc-d-none' );
			} );

			jQuery( '.scc-phone-input .country-code-select' ).change();

			jQuery.ajax( {
				url: 'https://api.stylishcostcalculator.com/rest/ip2country',
			} ).then( ( resp ) => {
				jQuery( `.scc-phone-input .country-code-select option[data-countryCode=${ resp }]` ).attr( 'selected', 'selected' );
				jQuery( '.scc-phone-input .country-code-select' ).change();
			} );
		} );

		const quoteForm = wp.template( 'scc-quote-text-field' )( {
			title: sccGetTranslationByKey( calcId, quoteFormTitle ),
			calcId,
			objectColor,
			isCaptchaEnabled,
			disableUnitColumn,
			disableQtyColumn,
			showPriceColumn,
			recaptchaSiteKey,
			showFormInDetail,
			gdprAcceptanceConfig: config.gdprAcceptanceConfig,
			calcName,
			quoteFormFields,
			showPdfNameOnQuoteForm: Number( showPdfNameOnQuoteForm ) === 1 && Number( quoteRecipient ) !== 0,
		} );

		if ( quoteForm ) {
			quoteFormPlaceholder.innerHTML = quoteForm;
			const formElement = quoteFormPlaceholder.querySelector( 'form' );

			const buttonSubmit = formElement.querySelector( '.scc-submit-button' );
			buttonSubmit.addEventListener( 'click', function() {
				const submitEvent = new Event( 'submit', {
					bubbles: true,
					cancelable: true,
				} );
				formElement.dispatchEvent( submitEvent );
			} );
			if ( formElement ) {
				formElement.addEventListener( 'submit', ( evt ) => {
					evt.preventDefault();
					evt.stopPropagation();

					if ( Number( formElement.dataset.hasErrors ) === 1 ) {
						sccUtils.vibrationAnimation( document.querySelector( '.text-danger[data-for]:not(.scc-d-none)' ) );
						return;
					}

					if ( postSubmitCallbackFn ) {
						handleQuoteSubmission( calcId, formElement, evt, objectColor, isCaptchaEnabled || false, disableUnitColumn, disableQtyColumn, showPriceColumn, showFormInDetail, preSubmitCallbackFn, postSubmitCallbackFn );
					} else {
						handleQuoteSubmission( calcId, formElement, evt, objectColor, isCaptchaEnabled || false, disableUnitColumn, disableQtyColumn, showPriceColumn, showFormInDetail, preSubmitCallbackFn, null );
					}
				} );
			}

			const inputEmailField = quoteFormPlaceholder.querySelector( 'input[type="email"]' );
			// If an email is added or requested in a comment box, it is auto-populated in the email quote form
			document.querySelectorAll( `input.comment_box_text`, `#scc_form_${ calcId }` ).forEach( ( e ) => {
				const emailEvaluation = ! (
					! e || ! e.value || ! e.value.includes( '@' ) || ! e.value.includes( '.' ) || e.value.includes( '#' ) || e.value.length < 4
				);
				if ( emailEvaluation && inputEmailField ) {
					inputEmailField.value = e.value;
				}
			} );

			// Start the email validation process
			const { block_free_email_domain } = config;
			let detectFreeEmail = false;
			inputEmailField.removeAttribute( 'onkeyup' );
			inputEmailField.removeAttribute( 'onfocusout' );
			[ 'keyup', 'change' ].forEach( ( e ) => {
				if ( block_free_email_domain && block_free_email_domain === '1' ) {
					detectFreeEmail = true;
				}
				inputEmailField.addEventListener( e, ( event ) => sccUtils.validateEmail( event, detectFreeEmail ) );
			} );
			// End the email validation process

			const sccDataDismiss = quoteFormPlaceholder.querySelectorAll( '[data-dismiss="modal"]' );
			sccDataDismiss.forEach( ( e ) => {
				e.addEventListener( 'click', function() {
					// Use classList to add or remove classes
					quoteFormPlaceholder.classList.add( 'scc-modal-display-none' ); // hides the element
					quoteFormPlaceholder.classList.remove( 'scc-modal-display-block' );

					document.body.classList.remove( 'scc-body-overflow-hidden' );
					document.body.classList.add( 'scc-body-overflow-auto' );
				} );
			} );
			quoteFormPlaceholder.querySelectorAll( '[type="phone"]' ).forEach( ( inputField ) => sccUtils.validatePhoneNumberInput( inputField, calcId ) );

			// Use classList to add or remove classes
			quoteFormPlaceholder.classList.remove( 'scc-modal-display-none' );
			quoteFormPlaceholder.classList.add( 'scc-modal-display-block' ); // shows the element
			// initiate flatpickr with isModal = true
			sccFlatpickrModalInit( calcId );
			document.body.classList.add( 'scc-body-overflow-hidden' );
			document.body.classList.remove( 'scc-body-overflow-auto' );

			[ 'fade', 'in' ].forEach( ( e ) => {
				quoteFormPlaceholder.classList.remove( e );
			} );
		}
		if ( window.self !== window.top ) {
			const modal = document.querySelector( '.df-scc-euiModalHeader__title.scc-vcenter' );
			if ( modal ) {
				window.parent.postMessage( { action: 'scroll', top: ( sccGetOffset( modal ).top - 100 ) }, '*' );
			}
		}
	}
}
window.initiateQuoteForm = initiateQuoteForm;

function showQuoteDetailView( calcId ) {
	if ( ! sccUtils.isMinimumTotalMet( calcId ) ) {
		return;
	}
	if ( ! verifiedMandatoryItems( calcId ) ) {
		return;
	}
	try {
		triggerSubmit( 4, this, 0, 0, calcId, true );
	} catch {
		//console.log("error");
	}
	const {
		webhookConfig,
		customJsConfig,
		pdf: pdfConfig,
		objectColor,
	} = sccData[ calcId ].config;

	const isDetailViewWebhookEnabled = webhookConfig.some( ( e ) => e.scc_set_webhook_detail_view?.enabled );
	const customJsConfigItem = customJsConfig.find( ( e ) => e.scc_set_customJs_detail_view );
	const isDetailViewCustomJsEnabled = customJsConfigItem?.scc_set_customJs_detail_view?.enabled;
	const customJsCallback = customJsConfigItem?.scc_set_customJs_detail_view?.customJs;

	const fontName = sccData[ calcId ].config.fontConfig.serviceFont;
	const css = sccGenerateCSS( objectColor, fontName, pdfConfig );

	const style = document.createElement( 'style' );
	style.setAttribute( 'id', 'scc-detail-view' );
	style.type = 'text/css';
	if ( style.styleSheet ) {
		style.styleSheet.cssText = css;
	} else {
		style.appendChild( document.createTextNode( css ) );
	}

	const oldStyle = document.getElementById( 'scc-detail-view' );
	if ( oldStyle ) {
		oldStyle.remove();
	}
	document.head.appendChild( style );

	if ( ! sccData[ calcId ].pdf ) {
		const warningNode = document.querySelector( `#scc_form_${ calcId } .scc-warning.no-items-selected` );
		warningNode.classList.remove( 'scc-d-none' );
		setTimeout( () => {
			warningNode.classList.add( 'scc-d-none' );
		}, 5000 );
		return;
	}

	const detailViewPlaceholder = document.getElementById( 'detail-view-placeholder' );
	if ( detailViewPlaceholder && typeof wp !== 'undefined' && typeof wp.template === 'function' ) {
		detailViewPlaceholder.innerHTML = wp.template( 'scc-quote-items-list-modal' )( {
			pdf: sccData[ calcId ].pdf,
			calcId,
			pdfConfig,
			objectColor,
		} );
	}

	document.body.style.overflow = 'hidden';

	if ( isDetailViewWebhookEnabled ) {
		fetch( wp.ajax.settings.url + '?action=scc_do_webhooks' + '&calcId=' + calcId + '&type=detailed_view' + '&nonce=' + window[ 'pageCalcFront' + calcId ].nonce, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify( sccData[ calcId ].webhook ),
		} );
	}

	if ( isDetailViewCustomJsEnabled && typeof sccData[ calcId ].postEventCallbacks.detailedViewCustomJsCode === 'function' ) {
		const quoteItems = sccData[ calcId ].webhook;
		sccData[ calcId ].postEventCallbacks.detailedViewCustomJsCode( quoteItems );
	}
	if ( window.self !== window.top ) {
		const modal = document.querySelector( '.sccTale_price-1' );
		window.parent.postMessage( { action: 'scroll', top: sccGetOffset( modal ) }, '*' );
	}
}
window.showQuoteDetailView = showQuoteDetailView;
// Helper function for generating the CSS. This should be placed outside `showQuoteDetailView`.
function sccGenerateCSS( objectColor, fontName, pdfConfig ) {
	let css = `
    .hover_bkgr_fricctableprice-1 .span2,
    .hover_bkgr_fricctableprice-1 .users-price-summary-scc,
    .hover_bkgr_fricctableprice-1 .Title-Descriptions-Summary-Window,
    .hover_bkgr_fricctableprice-1 .Section-Title-Summary-Window,
    .hover_bkgr_fricctableprice-1 .span-title,
    .hover_bkgr_fricctableprice-1 .Total_Price,
    .scc-col-md-10.scc-col-xs-12.main-title,
    .detailview-date span,
    .scc_email_template_view > div {
        font-family : ${ fontName };
    }
    `;

	css += `
    #sccTale_price-1::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #FFF;
        background-image: -webkit-gradient(linear, 40% 0%,75% 84%,from(${ objectColor }),to(${ objectColor }),color-stop(.6,${ objectColor }))
    }
    .scc-preview-row .Product-Titles-Summary-Window .span2 .scc-material-icon {
        color: ${ objectColor };
    }
    .scc-preview-row .Product-Titles-Summary-Window .span2 .scc-fontawesome {
        color: ${ objectColor };
    }
    `;

	if ( pdfConfig.disableQtyColumn ) {
		css += `
        .scc-col-md-2.scc-col-xs-2.sscfull-height:not(:nth-child(3)),
        span.Title-Descriptions-Summary-Window.trn.Quantity {
            opacity: 0;
        }
        `;
	}

	if ( ! pdfConfig.showPriceColumn ) {
		css += `
        .scc-col-md-2.scc-col-xs-3.sscfull-height {
            display: none;
        }
        `;
	}

	if ( pdfConfig.disableUnitColumn ) {
		css += `
        .scc-col-md-2.scc-col-xs-2.sscfull-height:not(:nth-child(2)),
        span.Title-Descriptions-Summary-Window.trn.Unit.Price {
            opacity: 0;
        }
        `;
	}

	return css;
}

function hideDetailView() {
	document.getElementById( 'detail-view-placeholder' ).innerHTML = '';
	document.body.style.overflow = 'auto';
}
window.hideDetailView = hideDetailView;

function sccSavePDF( enable, calcId ) {
	const sanitizedPDFJson = sccData[ calcId ].pdf;
	sanitizedPDFJson.rows = sanitizedPDFJson.rows.filter( ( e ) => Object.keys( e ).length );
	const tableTitle = sanitizedPDFJson.pdf_title;
	const pdfConfig = sccData[ calcId ].config.pdf;

	if ( enable == 0 ) {
		const alertElements = document.querySelectorAll( '.scc-alert' );
		alertElements.forEach( ( element ) => element.remove() );
		return;
	}

	const formData = new FormData();
	formData.append( 'action', 'sccSendPDF' );
	formData.append( 'payload', Base64.encode( JSON.stringify( sanitizedPDFJson ) ) );
	formData.append( 'disableUnitColumn', pdfConfig.disableUnitColumn );
	formData.append( 'disableQtyColumn', pdfConfig.disableQtyColumn );
	formData.append( 'showPriceColumn', pdfConfig.showPriceColumn );
	formData.append( 'tableTitle', tableTitle );
	formData.append( 'calcId', calcId );
	formData.append( 'nonce', window[ 'pageCalcFront' + calcId ].nonce );

	document.querySelector( `#sccTale_price-${ calcId }` ).style.cursor = 'wait';
	const svgElements = document.querySelectorAll( `#sccTale_price-${ calcId } .pdf-preview-icons svg` );
	svgElements.forEach( ( svg ) => svg.style.cursor = 'wait' );

	fetch( wp.ajax.settings.url, {
		method: 'POST',
		body: formData,
	} )
		.then( ( response ) => response.json() )
		.then( ( data ) => {
			const linkSource = `data:application/pdf;base64,${ data.file }`;
			const downloadLink = document.createElement( 'a' );
			const fileName = `${ tableTitle }-${ new Date().toLocaleDateString() }.pdf`;

			downloadLink.href = linkSource;
			downloadLink.download = fileName;
			downloadLink.click();

			document.querySelector( `#sccTale_price-${ calcId }` ).style.cursor = 'default';
			svgElements.forEach( ( svg ) => svg.style.cursor = 'pointer' );
		} )
		.catch( ( err ) => {
			//console.log('err', err);

			const disposition = err.headers.get( 'content-disposition' );
			const matches = /"([^"]*)"/.exec( disposition );
			const filename = matches !== null && matches[ 1 ] ? matches[ 1 ] : 'file.pdf';

			err.text().then( ( text ) => {
				const blob = new Blob( [ text ], { type: 'application/pdf' } );
				const link = document.createElement( 'a' );
				link.href = window.URL.createObjectURL( blob );
				link.download = filename;

				document.body.appendChild( link );
				link.click();
				document.body.removeChild( link );
			} );
		} );
}
window.sccSavePDF = sccSavePDF;

const PrintDoc = function( enable, calcId ) {
	if ( enable === 0 ) {
		// Remove .scc-alert elements
		document.querySelectorAll( '.scc-alert' ).forEach( ( element ) => element.remove() );

		// Find .scc-detailed-list-head elements and append the message
		document.querySelectorAll( 'body .scc-detailed-list-head' ).forEach( ( ob ) => {
			ob.innerHTML = `
                <div class='alert alert-info scc-alert' role='alert'>
                    <button class='close' type='button' data-dismiss='alert'>×</button>
                    <p>This feature is only for Premium users. You can purchase the premium version at 
                        <a href='https://stylishcostcalculator.com/' class='alert-link'>
                            <strong>https://stylishcostcalculator.com</strong>
                        </a>
                    </p>
                </div>`;
		} );

		return;
	}

	const pdfConfig = sccData[ calcId ].config.pdf;

	const printPreview = ( data, type = 'application/pdf' ) => {
		let blob = null;
		blob = b64toBlob( data.file, type );
		const blobURL = URL.createObjectURL( blob );
		const theWindow = window.open( blobURL );
		const theDoc = theWindow.document;
		const theScript = document.createElement( 'script' );
		function injectThis() {
			window.print();
		}
		theScript.innerHTML = `window.onload = ${ injectThis.toString() };`;
		theDoc.body.appendChild( theScript );
	};
	window.printPreview = printPreview;
	const b64toBlob = ( content, contentType ) => {
		contentType = contentType || '';
		const sliceSize = 512;
		// method which converts base64 to binary
		const byteCharacters = window.atob( content );
		const byteArrays = [];
		for ( let offset = 0; offset < byteCharacters.length; offset += sliceSize ) {
			const slice = byteCharacters.slice( offset, offset + sliceSize );
			const byteNumbers = new Array( slice.length );
			for ( let i = 0; i < slice.length; i++ ) {
				byteNumbers[ i ] = slice.charCodeAt( i );
			}
			const byteArray = new Uint8Array( byteNumbers );
			byteArrays.push( byteArray );
		}
		const blob = new Blob( byteArrays, {
			type: contentType,
		} ); // statement which creates the blob
		return blob;
	};

	const sanitizedPDFJson = sccData[ calcId ].pdf;
	const tableTitle = sanitizedPDFJson.pdf_title;
	sanitizedPDFJson.rows = sanitizedPDFJson.rows.filter( ( e ) => Object.keys( e ).length );

	const data = {
		action: 'sccSendPDF',
		payload: Base64.encode( JSON.stringify( sanitizedPDFJson ) ),
		disableUnitColumn: pdfConfig.disableUnitColumn,
		disableQtyColumn: pdfConfig.disableQtyColumn,
		showPriceColumn: pdfConfig.showPriceColumn,
		tableTitle,
		calcId,
		nonce: window[ 'pageCalcFront' + calcId ].nonce,
	};

	const element = document.querySelector( `#sccTale_price-${ calcId }` );
	if ( element ) {
		element.style.cursor = 'wait';
	}

	const svgElement = document.querySelector( `#sccTale_price-${ calcId } .pdf-preview-icons svg` );
	if ( svgElement ) {
		svgElement.style.cursor = 'wait';
	}

	fetch( wp.ajax.settings.url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		body: new URLSearchParams( data ).toString(),
	} )
		.then( ( response ) => response.json() )
		.then( ( b64 ) => {
			printPreview( b64 );
			document.querySelector( `#sccTale_price-${ calcId }` ).style.cursor = 'default';
			document.querySelector( `#sccTale_price-${ calcId } .pdf-preview-icons svg` ).style.cursor = 'pointer';
		} )
		.catch( ( err ) => {
		} );
};
window.PrintDoc = PrintDoc;

function sccShowStripeErrorMessage( sourceElement, messageBuilderFn, message ) {
	if ( sourceElement ) {
		const textElement = sourceElement.querySelector( '.df-scc-euiText.df-scc-euiText--medium' );
		if ( textElement ) {
			textElement.innerHTML = '';
			textElement.append( messageBuilderFn( 'Error', message ) );
		}

		const headerElement = sourceElement.querySelector( '.df-scc-euiModalHeader__title' );
		if ( headerElement ) {
			headerElement.textContent = '';
		}
	}
}
function handleQuoteSubmission(
	calcId,
	$this,
	event,
	color = 'cyan',
	isCaptchaEnabled = false,
	disableUnitColumn = false,
	disableQtyColumn = false,
	showPriceColumn = 1,
	showFormInDetail = false,
	preSubmitCallbackFn = null,
	postSubmitCallbackFn = null,
) {
	event.preventDefault();
	try {
		//Update total calculator amount before sending quote
		triggerSubmit( 4, this, 0, 0, calcId, true );
	} catch {
		//console.log("error");
	}
	// validating required fields
	const $requiredFields = $this.querySelectorAll( '[required]' );
	let formIsValid = true;
	$requiredFields.forEach( ( field ) => {
		// checking if field is a checkbox
		const isCheckbox = field.type === 'checkbox';
		const fieldHasValue = isCheckbox ? field.checked : field.value;
		if ( ! fieldHasValue ) {
			formIsValid = false;
			// finding name attribute of the field
			const fieldName = field.getAttribute( 'name' );
			const fieldErrorTextNode = $this.querySelector( '.text-danger[data-for=' + fieldName + ']' );
			if ( isCheckbox ) {
				// set error text for checkbox
				fieldErrorTextNode.innerHTML = sccGetTranslationByKey( calcId, 'Please check this box above if you want to proceed' );
			} else {
				// set error text for other fields
				fieldErrorTextNode.innerHTML = sccGetTranslationByKey( calcId, 'This field is required' );
			}
			// removing scc-d-none class from error text node
			fieldErrorTextNode.classList.remove( 'scc-d-none' );
			sccUtils.vibrationAnimation( fieldErrorTextNode );
			// adding one-off event listener to remove error text on focus, and for checkbox on change
			if ( isCheckbox ) {
				field.addEventListener( 'change', function() {
					fieldErrorTextNode.classList.add( 'scc-d-none' );
				}, { once: true } );
			} else {
				field.addEventListener( 'focus', function() {
					fieldErrorTextNode.classList.add( 'scc-d-none' );
				}, { once: true } );
			}
		}
	} );
	if ( ! formIsValid ) {
		return;
	}
	const { config } = sccData[ calcId ];
	const { quoteRecipient, sendQuoteFormDataToUser, sendQuoteFormDataToAdmin } = config;
	isCaptchaEnabled = Boolean( config.captcha.enabled );
	let emailValidToken = '';
	const hasErrors = $this.querySelectorAll( '[data-has-errors]' ).length > 0;
	if ( hasErrors ) {
		return;
	}
	const doAjax = async ( ...extraData ) => {
		const postCaptchaExtraDataString = $this.getAttribute( 'data-post-captcha-extra-data' );
		const postCaptchaExtraData = postCaptchaExtraDataString ? JSON.parse( postCaptchaExtraDataString ) : [];
		const { siteKey, provider } = config.captcha;
		const { priceRangeTotalSettings, currency_conversion_mode } = config;
		const isPriceRangeActive = 0 !== Number( priceRangeTotalSettings.rangePercent );

		const captchaResponse = provider === 'turnstile' ? $this.dataset.turnstileKey : $this.dataset.recaptchaKey;

		if ( isCaptchaEnabled && ! captchaResponse ) {
			$this.dataset.postCaptchaExtraData = JSON.stringify( extraData ); // Assuming extraData is an array or object
			sccProcessTrunstile( calcId, siteKey, $this, provider );
			return;
		}
		if ( ! sccData[ calcId ].pdf ) {
			console.warn( 'Nothing is selected in calculator' );
			return;
		}
		// sanitize PDFJson, so it doesn't trigger errors
		const sanitizedPDFJson = sccData[ calcId ].pdf;
		sanitizedPDFJson.rows = sanitizedPDFJson.rows.filter(
			( e ) => Object.keys( e ).length,
		);
		if ( currency_conversion_mode != 'off' ) {
			const { scc_rate_label_convertion } = config;
			const [ rate, label ] = scc_rate_label_convertion.split( '/' );
			Object.assign( sanitizedPDFJson, {
				converted_currency_code: label,
				converted_currency_rate: rate,
				converted_currency_total: parseFloat( rate ) * sanitizedPDFJson.total,
			} );
		}
		if ( isPriceRangeActive ) {
			const { totalTextWithRange, min, max } = sccData[ calcId ]?.priceRanges;
			Object.assign( sanitizedPDFJson, {
				total_price_range: totalTextWithRange,
				total_price_range_min: min,
				total_price_range_max: max,
			} );
		}
		const userSubmittedData = new FormData( $this );
		const toSubmitData = new FormData();
		const pageReferer = document.referrer;
		const encodedQuoteData = Base64.encode(
			JSON.stringify( { referer: pageReferer, pdf: sanitizedPDFJson } ),
		);
		const isTextingEnabled = config?.isTextingEnabled;
		let countryCode = $this.querySelector( '.country-code-select' )?.value || '';
		countryCode = countryCode.length ? '+' + countryCode : '';
		toSubmitData.append( 'action', 'sccQuoteSubmission' );
		toSubmitData.append(
			'data',
			JSON.stringify( [ ...userSubmittedData.entries() ].map( ( e ) => {
				/*  fieldKey is e[0] and fieldVal is e[1]; */
				if ( e[ 1 ] == 'on' ) {
					e[ 1 ] = 'Yes';
				}
				// cleaning up the number input for sending to twilio
				if ( e[ 0 ] == 'phone' && isTextingEnabled ) {
					e[ 1 ] = countryCode + e[ 1 ].replace( /[^\d\+]/g, '' );
				}
				// adding the country code to the phone number
				if ( e[ 0 ] == 'phone' ) {
					e[ 1 ] = countryCode + e[ 1 ];
				}
				return e;
			} ) ),
		);
		toSubmitData.append( 'quote_data', encodedQuoteData );
		toSubmitData.append( 'calcId', calcId );
		toSubmitData.append( 'captchaResponse', captchaResponse );
		toSubmitData.append( 'disableUnitColumn', disableUnitColumn );
		toSubmitData.append( 'disableQtyColumn', disableQtyColumn );
		toSubmitData.append( 'showPriceColumn', showPriceColumn );
		toSubmitData.append( 'showFormInDetail', showFormInDetail );
		toSubmitData.append( 'quoteRecipient', quoteRecipient );
		toSubmitData.append( 'emailValidationToken', emailValidToken );
		toSubmitData.append( 'nonce', window[ 'pageCalcFront' + calcId ].nonce );
		toSubmitData.append( 'sendQuoteFormDataToUser', sendQuoteFormDataToUser );
		toSubmitData.append( 'sendQuoteFormDataToAdmin', sendQuoteFormDataToAdmin );
		// sendQuoteFormDataToAdmin
		if ( extraData.length ) {
			extraData.forEach( ( e ) => {
				toSubmitData.append( e[ 0 ], e[ 1 ] );
			} );
		}

		if ( Array.isArray( postCaptchaExtraData ) && postCaptchaExtraData.length ) {
			postCaptchaExtraData.forEach( ( e ) => {
				toSubmitData.append( e[ 0 ], e[ 1 ] );
			} );
		}
		// Iterate over file input elements
		const fileInputs = document.querySelectorAll( `#scc_form_${ calcId } .scc-file-upload input[type="file"]` );
		fileInputs.forEach( ( input, i ) => {
			toSubmitData.append( `file-${ i }`, input.files[ 0 ] );
		} );

		// Iterate over signature boxes
		const signatureCanvas = document.querySelectorAll( `#scc_form_${ calcId } [data-typeelement="signature box"] canvas.signature-box` );
		let i = 1;
		for ( const canvas of signatureCanvas ) {
			if ( canvas.getContext( '2d' ).getImageData( 0, 0, canvas.width, canvas.height ).data.some( ( channel ) => channel !== 0 ) ) {
				const signatureBlob = await new Promise( ( resolve ) => canvas.toBlob( resolve ) );
				toSubmitData.append( `signature-${ i }`, signatureBlob, canvas.dataset.title + '.png' );
				toSubmitData.append( `signature-box-show-on-pdf[${ i }]`, canvas.dataset.showOnPdf );
				i++;
			}
		}

		// Create an HTML structure
		const buildMessage = ( title, message ) => {
			const wrapper = document.createElement( 'div' );
			const titleElement = document.createElement( 'span' );
			titleElement.style.fontSize = '24px';
			titleElement.className = 'trn';
			titleElement.textContent = title;

			const messageElement = document.createElement( 'p' );
			messageElement.innerHTML = message;

			wrapper.appendChild( titleElement );
			wrapper.appendChild( document.createElement( 'br' ) );
			wrapper.appendChild( messageElement );

			return wrapper;
		};

		const modalElements = {
			loadingWrapper: $this.querySelector( '.loading-wrapper' ),
			formBody: $this.querySelector( '.df-scc-euiText.df-scc-euiText--medium' ),
			$modalTitle: $this.querySelector( '.df-scc-euiModalHeader__title' ),
			modalFooter: $this.querySelectorAll( '.df-scc-euiModalFooter' )[ 0 ],
			modalSuccessFooter: $this.querySelector( '.summeryModalFooter' ),
		};

		const pleaseWaitText = sccGetTranslationByKey( calcId, 'Please wait...' );
		modalElements.formBody.style.display = 'none';
		modalElements.modalTitleOriginText = modalElements.$modalTitle.textContent;
		modalElements.$modalTitle.textContent = '';
		const spanNode = document.createElement( 'span' );
		const textNode = document.createTextNode( pleaseWaitText );
		spanNode.appendChild( textNode );
		const iNode = document.createElement( 'i' );
		iNode.className = 'scc-btn-spinner';
		modalElements.$modalTitle.appendChild( spanNode );
		modalElements.$modalTitle.appendChild( iNode );
		modalElements.modalFooter.style.display = 'none';

		fetch( wp.ajax.settings.url, {
			method: 'POST',
			body: toSubmitData,
		} )
			.then( ( response ) => response.json() )
			.then( ( data ) => {
				// Your success code here
				//			})
				if ( data?.action_required ) {
					// Handle Stripe 3D Secure
					modalElements.$modalTitle.textContent = 'Please Wait. Additional Steps Necessary.';
					stripe.handleCardPayment( data.payment_intent_client_secret )
						.then( ( result ) => {
							if ( result.error ) {
								modalElements.formBody.style.display = 'block';
								sccShowStripeErrorMessage( $this, buildMessage, result.error.message );
								return;
							}
							doAjax( [ 'payment_intent_id', result.paymentIntent.id ] );
						} );
					return;
				}

				modalElements.formBody.style.display = 'block';

				if ( data?.success == false && data.data.errorType == 'stripeError' ) {
					sccShowStripeErrorMessage( $this, buildMessage, data.data.message );
					return;
				}

				let blurredZoneSelector = `scc_form_${ calcId }`;

				if ( config.enable_floating_totalbar ) {
					blurredZoneSelector = `scc-total-bar-${ calcId }`;
				}

				const blurArea = document.querySelector( `#${ blurredZoneSelector } .scc-blur-total-price` );
				const blurButton = document.querySelector( `#${ blurredZoneSelector } .scc-blur-tp-self` );
				const blurElements = document.querySelectorAll( `#${ blurredZoneSelector } .scc-blur-text` );
				const blurSelfElements = document.querySelectorAll( `#${ blurredZoneSelector } .scc-blur-self-color` );

				if ( blurArea ) {
					blurArea.remove();
					blurButton.remove();
					blurElements.forEach( ( el ) => {
						el.classList.remove( 'scc-blur-text' );
					} );
					blurSelfElements.forEach( ( el ) => {
						el.classList.remove( 'scc-blur-self-color' );
					} );
				}
				const { webhookConfig, customJsConfig, post_quote_redirection_page_link: postQuoteSubmissionRedirectUrl } = sccData[ calcId ].config;
				const isQuoteWebhookEnabled = webhookConfig.filter( ( e ) => Object.keys( e ) == 'scc_set_webhook_quote' )[ 0 ]?.scc_set_webhook_quote?.enabled;
				const isPostQuoteCustomJsEnabled = customJsConfig.filter( ( e ) => Object.keys( e ) == 'scc_set_customJs_quote' )[ 0 ]?.scc_set_customJs_quote?.enabled;

				const failedMessageElement = document.createElement( 'div' );
				failedMessageElement.innerHTML = `<p><span class="trn">Email quote sending failed.</span></p>
				<p><a href="https://help.stylishcostcalculator.com/en/category/features-22x2mc/">Learn More</a></p>`;

				const successMessageElement = document.createElement( 'div' );
				successMessageElement.innerHTML = `
					<span style="font-size:24px" class="trn">${ sccGetTranslationByKey( calcId, 'Success' ) }</span>
					<p><span class="trn">${ sccGetTranslationByKey( calcId, data.has_stripe_subscription ? 'Thank you! You have been subscribed! An email has been sent to' : 'Thank you! We sent your quote to' ) }</span> ${ userSubmittedData.get( 'email' ) }.</p>
					<p class="trn" style="font-size:14px;">
					<svg height="22" viewBox="0 0 24 24" width="22" xmlns="http://www.w3.org/2000/svg">
						<g id="essentials/alerts/info-circle" fill="none" fill-rule="evenodd"><path fill="currentColor" id="Fill-66" d="m11.4994898 9.0034c-.552 0-1-.449-1-1s.448-1 1-1 1 .449 1 1-.448 1-1 1m1 7h-2c-.276 0-.49999998-.224-.49999998-.5s.22399998-.5.49999998-.5h.5v-4h-.5c-.276 0-.49999998-.224-.49999998-.5s.22399998-.5.49999998-.5h1c.276 0 .5.224.5.5v4.5h.5c.276 0 .5.224.5.5s-.224.5-.5.5m-1-13c-4.68699998 0-8.49999998 3.813-8.49999998 8.5s3.813 8.5 8.49999998 8.5c4.687 0 8.5-3.813 8.5-8.5s-3.813-8.5-8.5-8.5m0 18c-5.23799998 0-9.49999998-4.262-9.49999998-9.5s4.262-9.5 9.49999998-9.5c5.238 0 9.5 4.262 9.5 9.5s-4.262 9.5-9.5 9.5"></path></g>
					</svg>
						<span>${ sccGetTranslationByKey( calcId, 'Remember to check your spam folder.' ) }</span>
					</p>`;

				let message = successMessageElement;

				if ( typeof ( data ) === 'undefined' || typeof ( data ) === 'string' || typeof ( data ) === 'boolean' ) {
					message = failedMessageElement;
				}

				if ( data?.error && typeof ( data?.hasCustomMessage ) === 'undefined' ) {
					message = failedMessageElement;
				}

				if ( data?.hasCustomMessage ) {
					let msgContent = data.messages.map( ( msg ) => `${ msg.msg }. Error: ${ msg.code }` ).join( '<br>' );
					msgContent += "<br><br>Please contact the website's administrator.";
					message = buildMessage( 'Oops! There was an error!', msgContent );
				}

				// Check for no error and if isQuoteWebhookEnabled is true
				if ( ! data?.error && isQuoteWebhookEnabled ) {
					const payload = {
						quoteItems: JSON.stringify( sccData[ calcId ].webhook ),
						emailQuoteForm: toSubmitData.get( 'data' ),
					};
					const { config } = sccData[ calcId ];
					if ( currency_conversion_mode != 'off' ) {
						const { scc_rate_label_convertion } = config;
						const [ rate, label ] = scc_rate_label_convertion.split( '/' );
						Object.assign( payload, {
							currency_conversion: {
								currency_code: label,
								currency_rate: rate,
								currency_total: parseFloat( rate ) * sanitizedPDFJson.total,
							},
						} );
					}
					if ( isPriceRangeActive ) {
						const { totalTextWithRange, min, max } = sccData[ calcId ]?.priceRanges;
						Object.assign( payload, {
							total_price_range: {
								range: totalTextWithRange,
								range_min: min,
								range_max: max,
							},
						} );
					}

					fetch( `${ wp.ajax.settings.url }?action=scc_do_webhooks&calcId=${ calcId }&type=quote_send&webhook_token=${ data.webhook_tmp_token }&nonce=${ window[ 'pageCalcFront' + calcId ].nonce }`, {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify( payload ),
					} )
						.then( ( response ) => response.json() )
						.then( ( data ) => {
							if ( postSubmitCallbackFn ) {
								document.getElementById( 'quote-form-placeholder' ).classList.add( 'scc-hidden' );
								postSubmitCallbackFn();
							}
						} );
				}

				// Execute custom JS for post quote email event
				if ( isPostQuoteCustomJsEnabled && sccData[ calcId ].postEventCallbacks.hasOwnProperty( 'postQuoteCustomJsCode' ) ) {
					const quoteItems = sccData[ calcId ].webhook;
					const userEntries = Array.from( userSubmittedData )
						.filter( ( u ) => ! [ 'g-recaptcha-response', 'scc-gdpr-chbox' ].includes( u[ 0 ] ) )
						.map( ( entry ) => {
							const fieldNameByKey = [ 'firstName', 'lastName' ].includes( entry[ 0 ] ) ? entry[ 0 ] : sccData[ calcId ].config.quoteFormFields.find(
								( field ) => Object.keys( field )[ 0 ] == entry[ 0 ],
							)[ entry[ 0 ] ].name;

							return {
								[ fieldNameByKey ]: entry[ 1 ],
							};
						} );

					sccData[ calcId ].postEventCallbacks.postQuoteCustomJsCode( quoteItems, userEntries );
				}

				if ( postQuoteSubmissionRedirectUrl ) {
					// closing the modal form
					$this.querySelector( '[data-dismiss="modal"]' ).click();
					window.location = postQuoteSubmissionRedirectUrl;
				}

				if ( postSubmitCallbackFn && ! isQuoteWebhookEnabled && ! data?.error ) {
					document.getElementById( 'quote-form-placeholder' ).classList.add( 'scc-hidden' );
					postSubmitCallbackFn();
				}

				if ( ! postSubmitCallbackFn ) {
					$this.querySelector( '.df-scc-euiText.df-scc-euiText--medium' ).innerHTML = '';
					// append `message` to the modal body
					$this.querySelector( '.df-scc-euiText.df-scc-euiText--medium' ).appendChild( message );
					$this.querySelector( '.df-scc-euiModalHeader__title' ).textContent = '';
					modalElements.modalSuccessFooter.classList.remove( 'scc-d-none' );
				}

				// Reset captcha response
				$this.setAttribute( 'data-recaptcha-key', '' );

				if ( window.hasOwnProperty( 'translatorJson' ) ) {
					initTranslationScriptSCC( $this, { lang: 'en', t: translatorJson } );
				}
			},
			);
	};
	// handle the validation with set_transient, set the transient, then have it verify while sending out the email.
	if ( config?.isEmailValidationActive ) {
		const emailInput = $this.querySelector( '[name="email"]' );
		const submitButton = $this.querySelector( '[type="submit"]' );
		const submitButtonText = submitButton.querySelector( '.df-scc-euiButton__text' );

		// Prepare the request data
		const requestData = {
			action: 'scc_validate_email',
			emailAddress: emailInput.value,
			nonce: window[ 'pageCalcFront' + calcId ].nonce,
		};

		// Handle the before send operations
		submitButton.setAttribute( 'disabled', true );
		submitButtonText.setAttribute( 'data-original-text', submitButtonText.innerHTML );
		submitButtonText.innerHTML = sccFrontendAssets.svgs.spinner;

		// Make the request
		fetch( wp.ajax.settings.url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			body: JSON.stringify( requestData ),
		} )
			.then( ( response ) => response.json() )
			.then( ( data ) => {
				if ( data.success ) {
					emailValidToken = data.data.tmp_token;
					doAjax();
				} else {
					const warningNode = $this.querySelector( '.footer-warning-msg' );
					warningNode.textContent = sccGetTranslationByKey(
						calcId,
						'Oops! You might have typed a bad email address. Please check your email and try again.',
					);
					warningNode.classList.remove( 'scc-d-none' );

					submitButton.removeAttribute( 'disabled' );
					submitButtonText.innerHTML = submitButtonText.getAttribute( 'data-original-text' );

					setTimeout( () => {
						warningNode.classList.add( 'scc-d-none' );
					}, 5000 );
				}
			} )
			.catch( ( error ) => {
				console.error( 'There was an error with the fetch operation:', error );
			} );
	}
	const recaptchaResponseToken = $this.getAttribute( 'data-recaptcha-key' );
	if ( preSubmitCallbackFn && ! recaptchaResponseToken && ! config?.isEmailValidationActive ) {
		preSubmitCallbackFn( doAjax );
		return;
	}
	if ( ! config?.isEmailValidationActive ) {
		doAjax();
	}
}

function sccProcessTrunstile( calcId, recaptchaSiteKey, element, provider ) {
	const robotCheckNoticeText = sccGetTranslationByKey( calcId, 'Prove that you are not a robot' );
	const htmlData = `
        <div class="df-scc-euiModalBody__overflow turnstile">
            <div class="df-scc-euiText df-scc-euiText--medium">
                <p class="trn">${ robotCheckNoticeText }</p>
                <div class="scc-cf-turnstile scc-g-recaptcha form-field"></div>
            </div>
        </div>
    `;

	// Load the captcha script
	const script = document.createElement( 'script' );
	script.src = provider === 'turnstile' ? 'https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit' : `https://www.google.com/recaptcha/api.js`;
	script.onload = function() {
		const modalBody = element.closest( '.df-scc-euiModal' ).querySelector( '.df-scc-euiModalBody' );

		// Hide existing children
		Array.from( modalBody.children ).forEach( ( child ) => child.style.display = 'none' );

		// Add the reCAPTCHA HTML
		modalBody.insertAdjacentHTML( 'afterbegin', htmlData );
		if ( provider === 'turnstile' ) {
			const { config } = sccData[ calcId ];
			const { siteKey } = config.captcha;
			if ( ! siteKey ) {
				return;
			}
			turnstile.render( modalBody.querySelector( '.scc-cf-turnstile' ), {
				sitekey: siteKey,
				callback: ( token ) => {
					const submitBtn = element.querySelector( '[type=submit]' );
					element.dataset.turnstileKey = token;
					submitBtn.removeAttribute( 'disabled' );
					submitBtn.click();
				},
			} );
		}
		if ( provider === 'recaptcha_v2' ) {
			grecaptcha.ready( function() {
				grecaptcha.render( modalBody.querySelector( '.scc-g-recaptcha' ), {
					sitekey: recaptchaSiteKey,
					callback( e ) {
						const submitBtn = element.querySelector( '[type=submit]' );
						element.dataset.recaptchaKey = e;
						submitBtn.removeAttribute( 'disabled' );
						submitBtn.click();
					},
				} );
			} );
		}
	};

	script.onerror = function() {
		console.error( 'Failed to load the reCAPTCHA API' );
	};

	document.head.appendChild( script );
}

function sccGetTranslationByKey( calcId, key ) {
	const translation = sccData[ calcId ].config.translation?.filter(
		( e, i ) => e.key == key,
	)[ 0 ]?.translation;
	if ( translation ) {
		return translation;
	}
	return key;
}
window.sccGetTranslationByKey = sccGetTranslationByKey;

function sccMandatoryValidationCheck( element, submitEvent, calculator_id ) {
	if ( submitEvent ) {
		submitEvent.preventDefault();
	}

	if ( ! calculator_id && element ) {
		const calculatorWrapper = element.closest( '.scc_wrapper' );
		if ( calculatorWrapper ) {
			calculator_id = calculatorWrapper.id.split( 'scc_form_' )[ 1 ];
		}
	}

	let scc_mandatory = '';

	const mandatoryElements = document.querySelectorAll( '.mandatory_yes_' + calculator_id );
	mandatoryElements.forEach( ( el ) => {
		if ( el.value === '' && el.style.display !== 'none' ) {
			el.addEventListener( 'change', function() {
				if ( this.value !== '' ) {
					this.parentElement.parentElement.querySelector( '.scc-mandatory-msg' ).classList.remove( 'scc-d-flex' );
					this.parentElement.parentElement.querySelector( '.scc-mandatory-msg' ).classList.add( 'scc-hidden' );
				}
			}, { once: true } );

			const alertDanger = el.parentElement.parentElement.querySelector( '.scc-mandatory-msg' );
			const alertDangerText = alertDanger.querySelector( '.scc-mandatory-translated-text' );
			alertDangerText.innerText = 'Please choose an option☝️';
			alertDanger.classList.remove( 'scc-hidden' );
			alertDanger.classList.add( 'scc-d-flex' );

			window.scroll( {
				top: el.offsetTop,
				behavior: 'smooth',
			} );
			if ( window.self !== window.top ) {
				window.parent.postMessage( { action: 'scroll', top: el.offsetTop }, '*' );
			}
			sccUtils.vibrationAnimation( alertDanger );

			scc_mandatory = 'scc_remains';

			if ( window.hasOwnProperty( 'translatorJson' ) ) {
				initTranslationScriptSCC( 'body', { lang: 'en', t: translatorJson } );
			}

			checkIfParentIsAccordionAndOpenIt( element );
		}
	} );

	const mandatoryCheckbox = document.querySelectorAll( '#scc_form_' + calculator_id + ' ' + '[data-mandatory=yes]' );
	mandatoryCheckbox.forEach( ( e ) => {
		scc_mandatory += checkBoxMandatoryCheck( e );
	} );

	const mandatorySliders = document.querySelectorAll( '#scc_form_' + calculator_id + ' ' + '[data-slider-mandatory=yes]' );
	mandatorySliders.forEach( ( e ) => {
		scc_mandatory += sliderMandatoryCheck( e );
	} );

	if ( scc_mandatory === '' ) {
		if ( submitEvent ) {
			submitEvent.target.submit();
		}
		if ( ! submitEvent ) {
			return true;
		}
	}
}

//this function cleans the HTML of the titles to send the plain text to paypal
function sccCleanHTML( htmlText ) {
	const cleanedElement = document.createElement( 'div' );
	cleanedElement.innerHTML = htmlText;
	return cleanedElement.textContent;
}

//this function process the items to send to paypal checkout
function processItemsForPayPal( {
	items,
	tax,
	comments,
	calculator_id,
	isTaxInclusionEnabled = false,
	combineItems = false,
	coupon,
}, currentTotal ) {
	function getValuePerUnit( e ) {
		// make unit value of quantity modifier sliders to 0
		if ( e?.calculationType == 'quantity_mod' ) {
			return 0;
		}
		// handling variable math
		if ( e?.type == 'math' ) {
			return isNaN( e.price[ 0 ] ) ? 0 : Number( e.price[ 0 ] );
		}
		const valuePerUnit = e?.calculatedValue ? e.calculatedValue : e.price;
		// detect if in the backend and have negative value
		if ( valuePerUnit < 0 && typeof ( sccBackendStore ) !== 'undefined' ) {
			sccBackendUtils.showCombinedCheckoutNotice();
		}
		return valuePerUnit;
	}
	let total = 0;
	let itemsToAddPaypalForm = '';
	const combinedItems = [];
	let itemsAdded = 0;
	for ( let i = 0; i < items.length; i++ ) {
		let item_value =
			items[ i ].qtn === null || isNaN( items[ i ].qtn || items[ i ].qtn === 0 )
				? getValuePerUnit( items[ i ] )
				: getValuePerUnit( items[ i ] ) * items[ i ].qtn;
		// exclude zero of negative rated items and workarounds for custom math, as it has 0 as quantity value
		if ( ( isNaN( item_value ) || Number( item_value ) <= 0 ) && items[ i ].type !== 'customMath' ) {
			continue;
		}
		if ( items[ i ].type == 'customMath' ) {
			item_value = items[ i ].calculatedValue;
			if ( item_value === 0 ) {
				continue;
			}
		}
		const item_name =
			items[ i ].qtn === null ||
				items[ i ].qtn === 1 ||
				isNaN( items[ i ].qtn ) ||
				items[ i ].qtn === 0
				? sccCleanHTML( items[ i ].name )
				: sccCleanHTML( items[ i ].name ) + ' x ' + items[ i ].qtn;
		if ( item_name ) {
			if ( combineItems ) {
				combinedItems.push( item_name );
			}
			itemsToAddPaypalForm +=
				'  <input type="hidden" name="item_name_' +
				( itemsAdded + 1 ) +
				'" value="' +
				item_name +
				'" class="btPayPalItem">';
			itemsToAddPaypalForm +=
				'  <input type="hidden" name="amount_' +
				( itemsAdded + 1 ) +
				'" value="' +
				parseFloat( item_value ).toFixed( 2 ) +
				'" class="btPayPalItem">';
			itemsAdded++;
		}
		total +=
			items[ i ].qtn === null || isNaN( items[ i ].qtn ) || items[ i ].type == 'customMath'
				? getValuePerUnit( items[ i ] )
				: getValuePerUnit( items[ i ] ) * items[ i ].qtn;
	}
	total = total - ( coupon?.discountAmount || 0 );
	if ( combineItems ) {
		// deducting the tax
		currentTotal = tax ? currentTotal - tax : currentTotal;
		itemsToAddPaypalForm = `<input type="hidden" name="item_name_1" value="${ combinedItems.join(
			', ',
		) }" class="btPayPalItem">  <input type="hidden" name="amount_1" value="${ currentTotal }" class="btPayPalItem">`;
	}
	if ( tax && isTaxInclusionEnabled ) {
		if ( combineItems ) {
			itemsAdded = 1;
		}
		itemsToAddPaypalForm +=
			'  <input type="hidden" name="item_name_' +
			( itemsAdded + 1 ) +
			'" value="tax" class="btPayPalItem">';
		itemsToAddPaypalForm +=
			'  <input type="hidden" name="amount_' +
			( itemsAdded + 1 ) +
			'" value="' +
			tax.toFixed( 2 ) +
			'" class="btPayPalItem">';
	}
	const formElement = document.querySelector( `#scc_form_${ calculator_id } form.paypal_form .paypal_form_add_items` );
	if ( formElement ) {
		formElement.innerHTML = itemsToAddPaypalForm;
	}
}

// this function process the items to send to Stripe checkout
function sccProcessCheckout( calcId, $this ) {
	// Processing raw form data
	const rawItems = sortedItemsArray[ calcId ];
	const processedItems = [];
	rawItems.forEach( ( e ) => {
		processedItems.push( { ...e } );
	} );
	const encodedQuoteData = Base64.encode( JSON.stringify( { rawItems: processedItems } ) );

	const isCheckoutInProgress = $this.getAttribute( 'stripe-checkout-in-progress' );
	const checkOutBtn = $this;
	const checkOutBtnLoadingIndicator = checkOutBtn.querySelector( 'i' );
	const checkOutBtnImg = checkOutBtn.querySelector( '.scc-payment-logo' );
	const checkOutBtnIcon = checkOutBtn.querySelector( '.scc-cta-btn-icon' );

	const validationCheckResult = sccMandatoryValidationCheck( null, null, calcId );
	if ( ! validationCheckResult ) {
		return;
	}

	if ( isCheckoutInProgress ) {
		return;
	}

	const formData = new FormData();
	formData.append( 'action', 'scc_stripe_action' );
	formData.append( 'data', Base64.encode( JSON.stringify( sccData[ calcId ].stripeCart ) ) );
	formData.append( 'currency', sccData[ calcId ].config.currencyCode );
	formData.append( 'calcId', calcId );
	formData.append( 'calc_data', encodedQuoteData );
	formData.append( 'coupon_code', Base64.encode( JSON.stringify( sccData[ calcId ].coupon ) ) );
	formData.append( 'total_value', sccData[ calcId ].total );
	formData.append( 'nonce', window[ 'pageCalcFront' + calcId ].nonce );

	$this.setAttribute( 'stripe-checkout-in-progress', '1' );
	checkOutBtn.classList.add( 'scc-is-loading', 'scc-clear-background' );
	checkOutBtnLoadingIndicator.classList.remove( 'scc-d-none' );
	checkOutBtnLoadingIndicator.classList.add( 'scc-d-block' );
	checkOutBtnImg.classList.add( 'scc-d-none' );
	if ( checkOutBtnIcon ) {
		checkOutBtnIcon.classList.add( 'scc-d-none' );
	}

	fetch( wp.ajax.settings.url, {
		method: 'POST',
		body: formData,
	} )
		.then( ( response ) => response.json() )
		.then( ( session ) => {
			if ( ! session.error ) {
				stripe.redirectToCheckout( { sessionId: session.id } );
			} else {
				const errMessage = document.querySelector( `#scc_form_${ calcId } .scc-msg-validation` );
				errMessage.textContent = session.error;
				errMessage.classList.add( 'scc-display-block' );
				setTimeout( function() {
					errMessage.classList.remove( 'scc-display-block' );
					$this.removeAttribute( 'stripe-checkout-in-progress' );
					checkOutBtn.classList.remove( 'scc-is-loading' );
					checkOutBtnLoadingIndicator.classList.add( 'scc-d-none' );
					checkOutBtnLoadingIndicator.classList.remove( 'scc-d-block' );
					checkOutBtnImg.classList.remove( 'scc-d-none' );
					if ( checkOutBtnIcon ) {
						checkOutBtnIcon.classList.remove( 'scc-d-none' );
					}
				}, 5000 );
			}
		} )
		.catch( ( error ) => {
			console.error( 'Fetch Error:', error );
		} );
}

function sccPreCheckout( calcId, callback ) {
	if ( ! sccUtils.isMinimumTotalMet( calcId ) ) {
		return;
	}
	const { config } = sccData[ calcId ];
	if ( config.preCheckoutQuoteForm && verifiedMandatoryItems( calcId ) ) {
		initiateQuoteForm( calcId, callback );
		return;
	}
	if ( verifiedMandatoryItems( calcId ) ) {
		callback();
	}
}
window.sccPreCheckout = sccPreCheckout;

function showWooCommerceLoadingModal( buttonBackground, calcId ) {
	const code = `
        <div
            id="sccShowWooCommerceModal"
            class="scc-modal-2020 fade"
            role="dialog"
            style="opacity:1"
        >
            <div class="scc-modal-dialog-2020" style="width:560px;top:250px;">
                <div class="scc-modal-content-2020">
                    <div class="scc-modal-header-2020">
                        ${ sccGetTranslationByKey( calcId, 'Adding To Cart' ) }
                        <button type="button" class="close" data-dismiss="modal">
                            &times;
                        </button>
                    </div>
                    <div class="scc-modal-body-2020">
                        <div class="df-scc-progress df-scc-progress-striped active">
                            <div
                                class="df-scc-progress-bar"
                                role="progressbar"
                                aria-valuenow="100"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style="background-color: ${ buttonBackground }; width: 100%"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>`;

	const placeholder = document.getElementById( 'woocommerce-loading-placeholder' );
	placeholder.innerHTML = code;
	document.getElementById( 'sccShowWooCommerceModal' ).classList.add( 'scc-modal-display-block' );
}

// this function process the items to send to WooCommerce checkout
function sccWoocommerceAction( calcId ) {
	if ( verifiedMandatoryItems( calcId ) ) {
		const { pdf, config } = sccData[ calcId ];
		const cartAddAction = config?.addToCartRedirect || 'open_cart';
		const buttonBackground = config.objectColor;

		const pendingLicense = document.querySelectorAll( '.scc-pending-license' ).length ? true : false;

		if ( false ) {
			document.querySelector( '.row.scc-btn-hndle' ).textContent = 'Please Activate Stylish Cost Calculator Premium';
			return;
		}

		sccRegisterConversion( calcId, 'add-to-cart' );
		showWooCommerceLoadingModal( buttonBackground, calcId );

		if ( config.isCombinedCheckoutEnabled ) {
			fetch( wp.ajax.settings.url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				body: new URLSearchParams( {
					action: 'scc_wc_combined_checkout',
					payload: Base64.encode( JSON.stringify( pdf ) ),
					cartAddAction,
					calcId,
					woocommerceCombinedCheckoutProdId: config.woocommerceCombinedCheckoutProdId,
					nonce: window[ 'pageCalcFront' + calcId ].nonce,
				} ),
			} )
				.then( ( response ) => response.text() )
				.then( ( data ) => {
					window.location = data;
				} );
			return;
		}

		// sanitize PDFJson, so it doesn't trigger errors
		pdf.rows = pdf.rows.filter( ( e ) => Object.keys( e ).length );

		fetch( wp.ajax.settings.url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: new URLSearchParams( {
				action: 'sccAddWooCommerce',
				payload: Base64.encode( JSON.stringify( pdf ) ),
				cartAddAction,
				calcId,
				nonce: window[ 'pageCalcFront' + calcId ].nonce,
			} ),
		} )
			.then( ( response ) => response.text() )
			.then( ( data ) => {
				window.location = data;
			} );
	}
}
window.sccWoocommerceAction = sccWoocommerceAction;

function sccUslStats() {
	if ( typeof sccData === 'undefined' ) {
		return;
	}
	Object.keys( sccData ).forEach( function( calcId ) {
		// checkConditions( calcId );
		// detect if calculator form is rendered in a not editing page then trigger the dashboard url stats records
		if ( ! window.location.href.includes( '&id_form' ) ) {
			fetch( wp.ajax.settings.url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				body: new URLSearchParams( {
					action: 'sccUpdateUrlStats',
					url: window.location.href,
					calcId,
					nonce: window[ 'pageCalcFront' + calcId ].nonce,
				} ),
			} );
		}
	} );
}

// Start of accordion handling codes
function initSCCAccordion() {
	const acc = document.querySelectorAll( '.scc-accordion' );

	acc.forEach( function( accordion ) {
		if ( accordion.hasAttribute( 'data-accordion-init-done' ) ) {
			return;
		}
		accordion.setAttribute( 'data-accordion-init-done', true );
		accordion.addEventListener( 'click', function() {
			if ( accordion.classList.contains( 'scc-accordion_active' ) ) {
				accordion.classList.remove( 'scc-accordion_active' );
				accordion.classList.add( 'scc-accordion' );
			} else {
				accordion.classList.add( 'scc-accordion_active' );
				accordion.classList.remove( 'scc-accordion' );
			}

			const panels = document.querySelectorAll( '.' + accordion.id );
			panels.forEach( function( panel ) {
				if ( getComputedStyle( panel ).maxHeight === '0px' ) {
					if ( panel.classList.contains( 'simple_button' ) ) {
						panel.classList.addClass( 'scc-accordion-panel-inline' );
					} else if ( ! panel.classList.contains( 'scc-set-hidden' ) ) {
						panel.classList.remove( 'scc-accordion-panel-hidden' );
					}
					panel.classList.remove( 'scc-accordion-panel-hidden' );
					panel.classList.add( 'scc-accordion-panel-visible' );
				} else {
					panel.classList.remove( 'scc-accordion-panel-visible' );
					panel.classList.add( 'scc-accordion-panel-hidden' );
				}
			} );
		} );
	} );

	acc.forEach( function( accordion ) {
		const relatedPanels = document.querySelectorAll( '.' + accordion.id );
		relatedPanels.forEach( function( panel ) {
			panel.classList.add( 'scc-accordion-panel-hidden' );
		} );
	} );
}
window.initSCCAccordion = initSCCAccordion;
// END of accordion handling codes

window.addEventListener( 'DOMContentLoaded', ( event ) => {
	// apply colors
	// START of Multiple Total shortcode with combine attribute's frontend javascript
	// Convert valueChange event listener
	document.querySelectorAll( '.scc-multiple-total-wrapper[data-combination]' ).forEach( function( wrapper ) {
		wrapper.addEventListener( 'valueChange', function( event ) {
			const showCurrencySymbol = Number( wrapper.dataset.currSym ) === 1 ? true : false;
			const combinationString = wrapper.dataset.combination;
			const commaSeparator = wrapper.dataset.commaSeparator;
			if ( ! combinationString ) {
				return;
			}
			try {
				const combination = JSON.parse( combinationString );
				const values = combination.map( ( e ) => sccData[ e ]?.total || 0 );
				let combinedValue = 0;
				for ( const value of values ) {
					combinedValue += parseFloat( value );
				}

				const firstCalculator = combination[ 0 ];
				const currencyLabel = sccData[ firstCalculator ].config.currencyCode;

				const totalElement = wrapper.querySelector( '.scc-total' );
				totalElement.textContent = getCurrencyText( null, combinedValue, '', {
					useCurrencyLetters: ! showCurrencySymbol, currencyCode: currencyLabel, removeCurrency: false, tseparator: commaSeparator,
				} );
			} catch ( error ) {
				// eslint-disable-next-line no-console
				console.error( 'Error parsing JSON:', error );
			}
		} );
	} );

	// Convert doMath event listener
	document.querySelectorAll( '.scc-multiple-total-wrapper[data-math]:not([data-combination]) .scc-total' ).forEach( function( totalElement ) {
		totalElement.addEventListener( 'doMath', function( event ) {
			const mathOperator = JSON.parse( totalElement.parentElement.dataset.math );
			let elementTotal = parseFloat( totalElement.textContent );
			const calcIdMatch = Array.from( totalElement.parentElement.classList ).find( ( e ) => e.startsWith( 'calcid-' ) );
			const calcId = calcIdMatch ? calcIdMatch.replace( 'calcid-', '' ) : 0;

			if ( elementTotal && mathOperator && mathOperator.length == 2 ) {
				const opKey = mathOperator[ 0 ];
				const opVal = parseFloat( mathOperator[ 1 ] );

				switch ( opKey ) {
					case 'divide':
						if ( opVal > 0 ) {
							elementTotal /= opVal;
						}
						break;
					case 'multiply':
						elementTotal *= opVal;
						break;
					case 'add':
						elementTotal += opVal;
						break;
					case 'subtract':
						elementTotal -= opVal;
						break;
					default:
						break;
				}

				if ( calcId ) {
					totalElement.textContent = getCurrencyText( calcId, elementTotal );
				} else {
					totalElement.textContent = elementTotal;
				}
			}
		} );
	} );

	// END of Multiple Total shortcode with apply-math attribute's frontend javascript
	// detect if in the editing page or the frontend, apply strategies accordingly

	if ( typeof isInsideEditingPage !== 'undefined' && isInsideEditingPage() ) {
		// Find the element
		document.querySelector( '.preview_form_right_side' ).addEventListener( 'previewLoaded', function() {
			initializeScc( true ).then( () => {
			} ).catch( ( error ) => {
				console.error( 'Error initializing SCC:', error );
			} );
		} );
	} else {
		initializeScc();
		sccWhenAvailable( 'wp', () => {
			sccUslStats();
		} );
	}

	/*
	 * Verify if we are into the Elementor editor
	 */
	if ( window.elementorFrontend && window.elementorFrontend.isEditMode() ) {
		const widgetExecute = () => {
			initializeScc();
		};

		// DOM Changes Observer
		const observer = new MutationObserver( function( mutations ) {
			mutations.forEach( function( mutation ) {
				if ( mutation.addedNodes.length ) {
					for ( let i = 0; i < mutation.addedNodes.length; i++ ) {
						const node = mutation.addedNodes[ i ];
						if ( node.classList && node.classList.contains( 'elementor-widget-container' ) ) {
							const widgetContent = node.querySelector( '.elementor-text-editor.elementor-clearfix.elementor-inline-editing' );
							if ( widgetContent ) {
								widgetExecute();
								//observer.disconnect(); // Disconect the observer
							}
						}
					}
				}
			} );
		} );

		observer.observe( document.body, {
			childList: true,
			subtree: true,
		} );
	}
	/*
	 * End Verification
	 */

	// set the column sizes
	if ( window.matchMedia( '(max-width: 480px)' ).matches ) {
		const elements = document.querySelectorAll( '.scc-chkbx-cols-3' );
		elements.forEach( ( el ) => {
			el.classList.add( 'scc-chkbx-cols-2' );
			el.classList.remove( 'scc-chkbx-cols-3' );
		} );
	}
} );

// Register conversion, called from the calculator's Stripe or Paypal payment handler
// type: "stripe-conversion" or "paypal-conversion"
function sccRegisterConversion( calcId, type = 'stripe' ) {
	if ( typeof sccData[ calcId ] !== 'undefined' ) {
		const sanitizedPDFJson = sccData[ calcId ].pdf;
		sanitizedPDFJson.rows = sanitizedPDFJson.rows.filter(
			( e ) => Object.keys( e ).length,
		);
		const pageReferer = document.referrer;
		const encodedQuoteData = Base64.encode(
			JSON.stringify( { referer: pageReferer, pdf: sanitizedPDFJson } ),
		);

		// Removing the use of eval for nonce
		let nonceValue;
		if ( window[ 'pageCalcFront' + calcId ] ) {
			nonceValue = window[ 'pageCalcFront' + calcId ].nonce;
		}

		const data = {
			action: 'scc_register_conversion',
			calcId,
			type,
			quote_data: encodedQuoteData,
			nonce: nonceValue,
		};

		fetch( wp.ajax.settings.url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: new URLSearchParams( data ),
		} )
			.then( ( response ) => response.json() )
			.then( ( data ) => {
				//console.log(data);
			} )
			.catch( ( error ) => {
				console.error( 'Error:', error );
			} );
	}
}
window.sccRegisterConversion = sccRegisterConversion;

function sccRegisterView( calcId ) {
	if ( typeof sccData[ calcId ] !== 'undefined' ) {
		// Removing the use of eval for nonce
		let nonceValue;
		if ( window[ 'pageCalcFront' + calcId ] ) {
			nonceValue = window[ 'pageCalcFront' + calcId ].nonce;
		}

		const data = {
			action: 'scc_register_view',
			calcId,
			url_page: window.location.href,
			nonce: nonceValue,
		};

		fetch( wp.ajax.settings.url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: new URLSearchParams( data ),
		} )
			.then( ( response ) => response.json() )
			.then( ( data ) => {
				//console.log(data);
			} )
			.catch( ( error ) => {
				console.error( 'Error:', error );
			} );
	}
}
window.sccRegisterView = sccRegisterView;

function sccRegisterInteraction( calcId ) {
	const elemento = document.getElementById( 'scc_form_' + calcId );
	elemento.addEventListener( 'click', function() {
		if ( typeof sccData[ calcId ] !== 'undefined' ) {
			// Removing the use of eval for nonce
			let nonceValue;
			if ( window[ 'pageCalcFront' + calcId ] ) {
				nonceValue = window[ 'pageCalcFront' + calcId ].nonce;
			}
			const data = {
				action: 'scc_register_interaction',
				calcId,
				url_page: window.location.href,
				nonce: nonceValue,
			};
			fetch( wp.ajax.settings.url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				body: new URLSearchParams( data ),
			} )
				.then( ( response ) => response.json() )
				.then( ( data ) => {
					//console.log(data);
				} )
				.catch( ( error ) => {
					console.error( 'Error:', error );
				} );
		}
	} );
}
window.sccRegisterInteraction = sccRegisterInteraction;

function sccHandlePayPalCheckout( element ) {
	const isCheckoutInProgress = element.getAttribute( 'paypal-checkout-in-progress' );
	if ( isCheckoutInProgress ) {
		return;
	}
	element.setAttribute( 'paypal-checkout-in-progress', '1' );
	const checkOutBtn = element;
	const checkOutBtnLoadingIndicator = checkOutBtn.querySelector( 'i' );
	const checkOutBtnImg = checkOutBtn.querySelector( '.scc-payment-logo' );
	const checkOutBtnIcon = checkOutBtn.querySelector( '.scc-cta-btn-icon' );
	checkOutBtn.classList.add( 'scc-is-loading' );
	checkOutBtn.style.backgroundImage = '';
	checkOutBtnLoadingIndicator.classList.remove( 'scc-d-none' );
	checkOutBtnImg.classList.add( 'scc-d-none' );
	if ( checkOutBtnIcon ) {
		checkOutBtnIcon.classList.add( 'scc-d-none' );
	}
	const previousElement = element.previousElementSibling;
	if ( previousElement && previousElement.tagName === 'FORM' ) {
		previousElement.submit();
	}
}

function sccPreProCheckout( calcId, $this, payMethod ) {
	if ( ! sccUtils.isMinimumTotalMet( calcId ) ) {
		return;
	}
	try {
		triggerSubmit( 4, this, 0, 0, calcId, true );
	} catch {
		//console.log("error");
	}

	if ( payMethod == 'paypal' ) {
		sccItemValidationChecker( calcId ).then( function( response ) {
			if ( response ) {
				sccPreCheckout( calcId, () => sccHandlePayPalCheckout( $this ) );
			} else {
				const errMessage = document.querySelector( `#scc_form_${ calcId } .scc-msg-validation` );
				errMessage.textContent = 'Error with the calculator validation';
				errMessage.classList.add( 'scc-display-block' );
				errMessage.classList.remove( 'scc-hidden' );
				setTimeout( () => {
					errMessage.classList.add( 'scc-hidden' );
					errMessage.classList.remove( 'scc-display-block' );
				}, 5000 );
			}
		} );
	}

	if ( payMethod == 'stripe' ) {
		sccPreCheckout( calcId, () => sccProcessCheckout( calcId, $this ) );
	}

	if ( payMethod == 'stripe-subscription' ) {
		const { config } = window.sccData[ calcId ];

		const stripeSubscriptionFormCallback = ( ajaxCallback ) => {
			const stripeFormPlaceholder = document.querySelector( '#stripe-card-form-placeholder' );

			const modalHiddenHandler = function() {
				stripeFormPlaceholder.classList.remove( 'show' );
			};
			stripeFormPlaceholder.removeEventListener( 'hidden.bs.modal', modalHiddenHandler );
			stripeFormPlaceholder.addEventListener( 'hidden.bs.modal', modalHiddenHandler );

			const stripeFormModal = wp.template( 'scc-stripe-card-prompt' )( {
				calcId,
				amount: getCurrencyText( calcId, sccData[ calcId ].total ),
				period: config.stripe_subscription_settings.plan_period,
			} );
			if ( stripeFormModal ) {
				stripeFormPlaceholder.innerHTML = stripeFormModal;
			}

			const dismissModalElement = stripeFormPlaceholder.querySelector( '[data-dismiss="modal"]' );
			dismissModalElement.addEventListener( 'click', () => {
				stripeFormPlaceholder.classList.add( 'scc-hidden' );
				stripeFormPlaceholder.classList.remove( 'scc-display-block' );
			} );

			const stripeForm = stripeFormPlaceholder.querySelector( 'form' );
			stripeForm.addEventListener( 'submit', ( event ) => {
				event.preventDefault();
				handleStripeBilling( stripeForm, event, ajaxCallback );
			} );

			stripeFormPlaceholder.classList.add( 'scc-display-block' );
			stripeFormPlaceholder.classList.remove( 'scc-hidden', 'fade' );

			const cardElementConfig = { classes: { base: 'scc-stripe-element', complete: 'scc-stripe-element-complete', empty: 'scc-stripe-element-empty', focus: 'scc-stripe-element-focus', invalid: 'scc-stripe-element-invalid', webkitAutofill: 'scc-stripe-element-webkit-autofill' }, hidePostalCode: true, style: { base: { fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif', fontSize: '16px', color: 'rgba(0, 0, 0, 0.7)' } } };
			const cardElement = stripe.elements().create( 'card', cardElementConfig );
			cardElement.mount( '#stripe-card-form-placeholder .scc-stripe-card-element' );
			config.stripeCardElement = cardElement;
		};
		initiateQuoteForm( calcId, null, stripeSubscriptionFormCallback, 'Enter Details to Continue' );
	}
}
window.sccPreProCheckout = sccPreProCheckout;

// we remove the empty section from the itemsArray and we keep unique items
function sccFilterRawItems( rawItems ) {
	const rawData = rawItems.filter( ( item, index, self ) => {
		const sectionUndefined = typeof item.section === 'undefined';

		const isHidden = item.hasOwnProperty( 'hidden' ) && item.hidden === true;

		const uniqueIdElement = ! item.id_element ||
			( index === self.findIndex( ( el ) => el.id_element === item.id_element ) );

		const uniqueIdElementItem = ! item.id_elementItem ||
			( index === self.findIndex( ( el ) => el.id_elementItem === item.id_elementItem ) );

		return sectionUndefined && uniqueIdElement && uniqueIdElementItem;
	} );
	return rawData;
}

function sccItemValidationChecker( calcId ) {
	return new Promise( function( resolve, reject ) {
		if ( typeof sccData[ calcId ] !== 'undefined' ) {
			const rawItems = sccFilterRawItems( itemsArray[ calcId ] );
			const processedItems = [];
			rawItems.forEach( ( e ) => {
				processedItems.push( { ...e } );
			} );
			const encodedQuoteData = Base64.encode( JSON.stringify( { rawItems: processedItems } ) );

			const data = {
				action: 'scc_item_validation_checker',
				calcId,
				calc_data: encodedQuoteData,
				total_value: sccData[ calcId ].total,
				coupon_code: sccData[ calcId ].coupon,
				nonce: window[ 'pageCalcFront' + calcId ].nonce,
			};

			fetch( wp.ajax.settings.url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
				},
				body: new URLSearchParams( data ).toString(),
			} )
				.then( ( response ) => response.json() )
				.then( ( data ) => {
					if ( data.status === false ) {
						resolve( false );
					} else {
						resolve( true );
					}
				} )
				.catch( ( error ) => {
					reject( error );
				} );
		}
	} );
}

function dfSCC_Recaclulate( hasReCalculationTargets = false ) {
	if ( hasReCalculationTargets ) {
	}

	const visibleDropdowns = document.querySelectorAll( "[id*=ssc-elmt-][data-typeelement='Dropdown Menu']:not(.scc-conditional-hidden) select[id*=byjson]" );
	if ( visibleDropdowns.length > 0 ) {
		setTimeout( () => {
			for ( let index = 0; index < visibleDropdowns.length; index++ ) {
				// Assuming `tomselect` is a property of the dropdown element, and it has a method called "trigger"
				visibleDropdowns[ 0 ].tomselect.trigger( 'change' );
			}
		}, 300 );
	}

	const firstCheckbox = document.querySelector( 'input[id*=itemcreated]' );
	if ( firstCheckbox && visibleDropdowns.length <= 0 ) {
		const checkedStatus = firstCheckbox.checked;
		setTimeout( () => {
			firstCheckbox.click();
			firstCheckbox.click();
		}, 300 );
	}
}

function handleStripeBilling( stripeForm, event, ajaxCallback ) {
	event.preventDefault();
	const calcId = stripeForm.getAttribute( 'data-calc-id' );
	const { config } = sccData[ calcId ];
	const cardElement = config.stripeCardElement;
	stripe.createPaymentMethod( 'card', cardElement, {
		billing_details: {
			name: stripeForm.querySelector( 'input[name="scc-stripe-name-on-card"]' ).value,
		},
	} ).then( function( result ) {
		if ( result.error ) {
		}

		if ( ! result.error ) {
			const rawItems = itemsArray[ calcId ];
			const processedItems = [];
			rawItems.forEach( ( e ) => {
				processedItems.push( { ...e } );
			} );
			const encodedQuoteData = Base64.encode(
				JSON.stringify( { rawItems: processedItems } ),
			);

			ajaxCallback( [ 'stripePaymentMethodId', result.paymentMethod.id ],
				[ 'currency', sccData[ calcId ].config.currencyCode ],
				[ 'calcId', calcId ],
				[ 'calc_data', encodedQuoteData ],
				[ 'coupon_code', sccData[ calcId ].coupon ],
				[ 'total_value', sccData[ calcId ].total ] );
			stripeForm.previousElementSibling.click();
		}
	} );
}
function checkCouponCode( calcId ) {
	const modalObject = event.currentTarget.closest( '.df-scc-euiModal' );
	const buttonContent = modalObject.querySelector( '.df-scc-euiButtonContent.df-scc-euiButton__content' );

	buttonContent.innerHTML = `${ sccFrontendAssets.svgs.spinner }
    <span class="trn df-scc-euiButton__text">Checking...</span>`;

	const params = new URLSearchParams( {
		action: 'sscCheckCoupon',
		coupon_code: modalObject.querySelector( 'input' ).value,
		calcId,
		nonce: window[ 'pageCalcFront' + calcId ].nonce,
	} );

	fetch( `${ wp.ajax.settings.url }?${ params.toString() }` )
		.then( ( response ) => response.json() )
		.then( ( data ) => {
			const showWarning = ( msgText ) => {
				const warningMessageNode = modalObject.querySelector( '.text-danger' );
				const oldText = warningMessageNode.textContent;
				if ( msgText ) {
					warningMessageNode.textContent = msgText;
				}
				warningMessageNode.style.display = 'block';
				setTimeout( () => {
					warningMessageNode.textContent = oldText;
					warningMessageNode.style.display = 'none';
				}, 3000 );
				if ( window.hasOwnProperty( 'translatorJson' ) ) {
					initTranslationScriptSCC( 'body', {
						lang: 'en',
						t: translatorJson,
					} );
				}
			};

			if ( typeof data === 'object' ) {
				if ( data.data ) {
					buttonContent.innerHTML = '<span class="trn df-scc-euiButton__text">Submit</span>';
					if ( data.data === false || data.data === 'false' ) {
						showWarning();
					} else if ( typeof data.data === 'object' ) {
						const coupon = data.data;
						if ( ! coupon.isActive ) {
							showWarning( 'This coupon has expired' );
							return;
						}
						document.querySelector( '#scc-coupon-modal-placeholder' )
							.querySelector( '[data-dismiss="modal"]' )
							.click();
						sccData[ calcId ].coupon = coupon;
						dfSCC_Recaclulate();
					}
				}
			} else if ( ! data ) {
				document.querySelector( '#button_check_coupon' ).textContent = 'Check Coupon Code';
			}
		} )
		.catch( ( error ) => {
		} );
}
window.checkCouponCode = checkCouponCode;

function addCouponCodeModal( calcId ) {
	if ( ! sccUtils.isMinimumTotalMet( calcId ) ) {
		return;
	}
	const config = sccData[ calcId ].config;
	const { objectColor, fontConfig, section_title, service } = config;

	if ( ! sccData[ calcId ].pdf ) {
		const warningNode = document.querySelector( `#scc_form_${ calcId } .scc-warning.no-items-selected` );
		warningNode.classList.remove( 'scc-d-none' );
		setTimeout( () => {
			warningNode.classList.add( 'scc-d-none' );
		}, 5000 );
		return;
	}

	let titleColor = section_title.color;
	let serviceColor = service.color;
	let objectColors = objectColor;

	if ( titleColor === '#ffffff' || titleColor === '#fff' || titleColor === 'white' ) {
		titleColor = '#000000';
	}
	if ( serviceColor === '#ffffff' || serviceColor === '#fff' || serviceColor === 'white' ) {
		serviceColor = '#000000';
	}
	if ( objectColors === '#ffffff' || objectColors === '#fff' || objectColors === 'white' ) {
		objectColors = '#000000';
	}

	const css = `.df-scc-euiModalHeader__title,
	.df-scc-euiFormLabel.df-scc-euiFormRow__label,
	.modal .df-scc-euiText,
	.modal .df-scc-euiModal,
	.df-scc-euiButtonContent.df-scc-euiButtonEmpty__content,
	.df-scc-euiButton.df-scc-euiButton--primary.df-scc-euiButton--fill {
		font-family : ${ fontConfig.serviceFont };
	}
	.df-scc-euiModalHeader__title{
		color:${ titleColor };
	}
	.df-scc-euiFormRow__label{
		color:${ serviceColor };
	}
	.df-scc-euiButtonEmpty__text{
		color:${ objectColors };
	}
	.df-scc-euiButtonContent.df-scc-euiButton__content{
		background-color:${ objectColors };
		border-color:${ objectColors };
		border-radius:3px;
	}
	input.df-scc-euiFieldText:focus {
		background-image: linear-gradient(to top, ${ objectColors }, ${ objectColors } 2px, transparent 2px, transparent 100%);
		color: ${ objectColors };
	}`;

	// Create a new style element
	const style = document.createElement( 'style' );
	style.setAttribute( 'id', 'scc-quotemodal' );
	style.type = 'text/css';
	style.innerHTML = css;

	if ( style.styleSheet ) {
		style.styleSheet.cssText = css;
	} else {
		style.appendChild( document.createTextNode( css ) );
	}

	// Append the new style element to the head
	document.querySelector( '#scc-quotemodal' )?.remove();
	document.head.appendChild( style );

	const template = wp.template( 'scc-coupon-modal' )( { calcId } );
	const couponModal = document.getElementById( 'scc-coupon-modal-placeholder' );

	couponModal.innerHTML = template;

	const closeModalButton = couponModal.querySelectorAll( '[data-dismiss="modal"]' );
	closeModalButton.forEach( ( el ) => {
		el.addEventListener( 'click', function() {
			couponModal.classList.remove( 'scc-modal-display-block' );
			couponModal.classList.add( 'scc-modal-display-none' );
			document.body.classList.remove( 'scc-overflow-hidden' );
			document.body.classList.add( 'scc-overflow-auto' );
		} );
	} );
	couponModal.classList.add( 'scc-modal-display-block' );
	 couponModal.classList.remove( 'scc-modal-display-none' );
	document.body.classList.remove( 'scc-overflow-auto' );
	document.body.classList.add( 'scc-overflow-hidden' );
	[ 'fade', 'in' ].forEach( ( className ) => {
		couponModal.classList.remove( className );
	} );
}
window.addCouponCodeModal = addCouponCodeModal;

function showDiscountNotice( calcId, discountInfo ) {
	const targetCalculator = document.querySelector( '#scc_form_' + calcId );
	discountInfo.calcId = calcId;
	const discountNotice = wp.template( 'scc-discount-notice' )( discountInfo );

	// Remove previous discount notice
	const prevDiscountNotice = targetCalculator.querySelector( '.coupon_info_container' );
	if ( prevDiscountNotice ) {
		prevDiscountNotice.remove();
	}

	// Insert new discount notice before total section
	const totalSection = targetCalculator.querySelector( '.scc-total-section' );
	totalSection.insertAdjacentHTML( 'beforebegin', discountNotice );
}

function getCurrencyText(
	calcId,
	amount,
	convertedCurrency = '',
	customConfig = null,
) {
	const currencyFormat = ( currencyFormatConfig ) => {
		switch ( currencyFormatConfig ) {
			case 'default':
				return {
					region: navigator.languages[ 0 ],
					params: {
					},
					symbolPlacement: 'before',
				};
			case 'north_america':
				return {
					region: 'en-US',
					params: {},
					symbolPlacement: 'before',
				};
			case 'latin_america':
				return {
					region: 'es-AR',
					params: {},
				};
			case 'continental_europe':
				return {
					region: 'de-DE',
					params: {
					},
					symbolPlacement: 'after',
				};
			case 'russia':
				return {
					region: 'ru-RU',
					params: {},
					symbolPlacement: 'after',
				};
			case 'japan_and_south_korea':
				return {
					region: 'ja-JP',
					params: {},
					symbolPlacement: 'before',
				};
			case 'switzerland':
				return {
					region: 'de-CH',
					params: {},
					symbolPlacement: 'before_with_space',
				};
			case 'indonesia':
				return {
					region: 'id-ID',
					params: {
					},
					symbolPlacement: 'before_with_space',
				};
			default:
				return {
					region: navigator.languages[ 0 ],
					params: {
					},
					symbolPlacement: 'before',
				};
		}
	};
	const currencyOutputWithSymbol = ( amount, symbolPlacement ) => {
		switch ( symbolPlacement ) {
			case 'before':
				return _currencySymbol + amount;
			case 'before_with_space':
				return _currencySymbol + ' ' + amount;
			case 'after':
				return amount + ' ' + _currencySymbol;
			default:
				return _currencySymbol + amount;
		}
	};
	let { useCurrencyLetters, currencyCode, removeCurrency, tseparator } = calcId ? sccData[ calcId ].config : customConfig;
	amount = parseFloat( amount ).toFixed( 2 );
	currencyCode = convertedCurrency ? convertedCurrency : currencyCode;
	let _currencySymbol = currencySymbol( currencyCode );
	const skipSymbol = [ 'USD', 'SGD', 'CAD' ];
	let currencySymbolPlacement = 'before';
	if (
		convertedCurrency.length > 0 &&
		skipSymbol.findIndex( ( sym ) => sym == convertedCurrency )
	) {
		_currencySymbol = convertedCurrency;
		useCurrencyLetters = true;
	}
	/** if amount is a negative number, and the remove currency feature and the use currency letter feature are disabled */
	if ( amount < 0 && ! removeCurrency && ! useCurrencyLetters ) {
		amount = Math.abs( amount );
		_currencySymbol = '-' + _currencySymbol;
	}
	if ( tseparator === 'comma' ) {
		amount = priceCommaStyler( amount );
	} else {
		const hasFractions = amount - parseInt( amount ) > 0;
		const { region, params, symbolPlacement } = currencyFormat( tseparator );
		if ( hasFractions ) {
			amount = parseFloat( amount ).toLocaleString( region, { ...params, minimumFractionDigits: 2 } );
		}
		if ( ! hasFractions ) {
			amount = parseFloat( amount ).toLocaleString( region, params );
		}
		currencySymbolPlacement = symbolPlacement;
	}
	if ( removeCurrency ) {
		return amount;
	}
	return useCurrencyLetters
		? amount + ' ' + currencyCode
		: currencyOutputWithSymbol( amount, currencySymbolPlacement );
}
function outputDate( format ) {
	var today = new Date();
	let dd = today.getDate();
	let mm = today.getMonth() + 1;
	const yyyy = today.getFullYear();
	if ( dd < 10 ) {
		dd = '0' + dd;
	}
	if ( mm < 10 ) {
		mm = '0' + mm;
	}
	if ( format == 'dd-mm-yyyy' ) {
		var today = dd + '/' + mm + '/' + yyyy;
	} else {
		var today = mm + '/' + dd + '/' + yyyy;
	}
	return today;
}

window.onresize = () => {
	if ( window.matchMedia( '(max-width: 480px)' ).matches ) {
		const elements = document.querySelectorAll( '.scc-chkbx-cols-3' );
		elements.forEach( ( el ) => {
			el.classList.add( 'scc-chkbx-cols-2', 'restore-col-3' );
			el.classList.remove( 'scc-chkbx-cols-3' );
		} );
	} else {
		const elements = document.querySelectorAll( '.scc-chkbx-cols-2.restore-col-3' );
		elements.forEach( ( el ) => {
			el.classList.add( 'scc-chkbx-cols-3' );
			el.classList.remove( 'scc-chkbx-cols-2', 'restore-col-3' );
		} );
	}
};

function dfSccInputBoxAddCommas( inputField ) {
	let val = inputField.value.replace( /[^0-9.]/g, '' ); // remove any non-digit and non-dot characters
	const dotCount = ( val.match( /\./g ) || [] ).length;
	if ( dotCount > 1 ) {
		// remove all dots except the last one
		val = val.replace( /\./g, ( match, offset, string ) => {
			return ( string.slice( offset ).match( /\./g ) || [] ).length > 1 ? '' : '.';
		} );
	}
	const parts = val.split( '.' ); // split the string into integer and decimal parts
	parts[ 0 ] = parseInt( parts[ 0 ], 10 ); // convert the integer part to an integer

	if ( isNaN( parts[ 0 ] ) ) {
		// if the integer part is not a valid number, empty the input field
		inputField.value = '';
	} else {
		// if the integer part is a valid number, format it with commas
		parts[ 0 ] = parts[ 0 ].toLocaleString( 'en-US' );

		// join the integer and decimal parts back together
		inputField.value = parts.join( '.' );
	}
}
window.dfSccInputBoxAddCommas = dfSccInputBoxAddCommas;
//default value in form
function dfsccLoaded( formId ) {
	document.querySelectorAll( '[id^=ssc-elmt-]' ).forEach( function( e ) {
		const type = e.getAttribute( 'data-typeelement' );
		switch ( type ) {
			case 'checkbox':
				triggerCheck( e );
				break;
			case 'Dropdown Menu':
				triggerTomSelect( e );
				break;
			case 'slider':
				triggerSlider( e );
				break;
			case 'quantity box':
				triggerQuantityBox( e );
				break;
			case 'custom math':
				triggerCustom( e );
				break;
			case 'file upload':
				triggerFile( e );
				break;
			case 'math':
				triggerMathSlider( e );
				break;
			case 'date':
				triggerDate( e );
				break;
			case 'distance':
				triggerDistance( e );
				break;
			case 'signature box':
				triggerSignatureBox( e );
				break;
		}
		function triggerDistance( cntx ) {
			const i = e.querySelector( '.scc-distance-input-end' );
			const formid = i.getAttribute( 'data-formid' );
			const element = i.getAttribute( 'data-elementid' );
			if ( i.value ) {
				triggerSubmit( 12, i, element, 0, formid );
			}
		}
		function triggerDate( cntx ) {
			const i = e.querySelector( 'input' );
			const formid = i.getAttribute( 'data-formid' );
			const element = i.getAttribute( 'data-elementid' );
			const elementType = i.getAttribute( 'data-elementtype' );
			// Single Date
			if ( i.value && elementType === '10' ) {
				triggerSubmit( 10, i, element, 0, formid );
			}
			// Date Range
			if ( i.value && elementType === '11' ) {
				triggerSubmit( 11, i, element, 0, formid );
			}
		}
		function triggerQuantityBox( cntx ) {
			const i = e.querySelector( 'input' );
			const formid = i.getAttribute( 'data-calc-id' );
			const element = i.getAttribute( 'data-element-id' );
			if ( i.value ) {
				triggerSubmit( 3, i, element, 0, formid );
			}
		}
		function triggerCheck( cntx ) {
			e.querySelectorAll( 'input' ).forEach( function( e ) {
				const formId = e.getAttribute( 'data-formid' );
				const elementId = e.getAttribute( 'data-elementid' );
				const itemId = e.getAttribute( 'data-itemid' );
				const optdef = e.getAttribute( 'data-optdefault' );
				const checkboxtype = e.getAttribute( 'data-checkboxtype' );
				const isImageCheckbox = e.getAttribute( 'data-image-checkbox' ) === 'true';
				const isRadioBtn = e.getAttribute( 'type' ) == 'radio';
				if ( optdef == 1 ) {
					e.checked = true;
					if ( checkboxtype == 6 && ! isImageCheckbox ) {
						changeButtonToogle( e );
					}
					triggerSubmit( isRadioBtn ? 6 : 1, e, elementId, 'chkbx-' + itemId, formId );
				} else {
					triggerSubmit( 0, e, elementId, 'chkbx-' + itemId, formId, true );
				}
			} );
		}

		function triggerTomSelect( cntx ) {
			const el = cntx.querySelector( 'select[id^=byjson]' );
			const formId = el.getAttribute( 'data-formid' );
			const elementId = el.getAttribute( 'data-elementid' );
			const plugins = [];
			const shouldEnableSearchInput = el.options.length > 7;

			if ( shouldEnableSearchInput ) {
				plugins.push( 'dropdown_input' );
			}

			try {
				if ( ! el.tomselect ) {
					const tomSelectInstance = new TomSelect( el, {
						plugins,
						searchField: [ { field: 'text', weight: 2 }, { field: 'text2', weight: 0.5 } ],
						hidePlaceholder: true,
						maxOptions: 'null',
						hideSelected: true,
						placeholder: sccGetTranslationByKey( formId, 'Search for an option...' ),
						onInitialize() {
							this.input.setAttribute( 'data-prev-choice', this.input.value );

							if ( ! shouldEnableSearchInput ) {
								this.control_input.setAttribute( 'disabled', '' );
							}

							let previusValue = this.input.getAttribute( 'data-prev-choice' ) || 0;
							if ( previusValue === 'null' ) {
								previusValue = 0;
							}

							const selected = this.input.value;
							triggerSubmit( 2, this.input, 0, previusValue + '/' + selected, formId );
						},
						render: {
							option( data, escape ) {
								const thumbnailUrl = data.src != 0 ? data.src : null;
								const contentResult = data.text.split( 'scc-dropdown-opt-content' );
								const isChooseAnOption = data.$option.classList.contains( 'scc-pick-an-option' );
								let htmlString;
								if ( isChooseAnOption ) {
									htmlString = `<div class="firstchild1">${ sccGetTranslationByKey( formId, data.text.trim() ) }</div>`;
								} else if ( thumbnailUrl ) {
									htmlString = `<div> <span class="scc-tooltip2 dropdown-image-tooltip" tooltip="{&quot;title&quot;:&quot;&quot;,&quot;content&quot;:&quot;&quot;,&quot;image&quot;:&quot;${ thumbnailUrl }&quot;,&quot;btn_label&quot;:&quot;&quot;,&quot;btn_link&quot;:&quot;&quot;}" flow="left"><img loading="lazy" class="me-2" src="${ thumbnailUrl }"></span><div class="scc-option-content"><div class="scc-dropdown-opt-title">${ contentResult[ 0 ] }</div><div class="scc-dropdown-opt-description">${ contentResult[ 1 ] }</div></div></div>`;
								} else {
									htmlString = `<div class="scc-option-content"><div class="scc-dropdown-opt-title">${ contentResult[ 0 ] }</div><div class="scc-dropdown-opt-description">${ contentResult[ 1 ] }</div></div>`;
								}
								return htmlString;
							},
							item( item, escape ) {
								const thumbnailUrl = item.src != 0 ? item.src : null;
								const contentResult = item.text.split( 'scc-dropdown-opt-content' );
								const isChooseAnOption = item.$option.classList.contains( 'scc-pick-an-option' );
								let htmlString;
								if ( isChooseAnOption ) {
									htmlString = `<div class="scc-pick-an-option-bkp firstchild2">
                        <div class="scc-pick-an-option-text-bkp firstchild2">${ sccGetTranslationByKey( formId, item.text.trim() ) }</div>
                    </div>`;
								} else if ( thumbnailUrl ) {
									htmlString = `<div><span class="scc-tooltip2 dropdown-image-tooltip" tooltip="{&quot;title&quot;:&quot;&quot;,&quot;content&quot;:&quot;&quot;,&quot;image&quot;:&quot;${ thumbnailUrl }&quot;,&quot;btn_label&quot;:&quot;&quot;,&quot;btn_link&quot;:&quot;&quot;}" flow="left" ><img loading="lazy"  src="${ thumbnailUrl }"></span><div class="scc-option-content"><div class="scc-dropdown-opt-title">${ contentResult[ 0 ] }</div><div class="scc-dropdown-opt-description">${ contentResult[ 1 ] }</div></div></div>`;
								} else {
									htmlString = `<div class="no-image scc-option-content"><div class="scc-dropdown-opt-title">${ contentResult[ 0 ] }</div><div class="scc-dropdown-opt-description">${ contentResult[ 1 ] }</div></div>`;
								}
								return htmlString;
							},
						},
					} );

					const itemSpan = tomSelectInstance.control.querySelector( '.item span' );
					if ( itemSpan ) {
						itemSpan.addEventListener( 'mousemove', ( evt ) => {
							const { clientX: x, clientY: y, currentTarget: imageNode } = evt;
							const { left: imgElementLeft, right: imgElementRight, top: imgElementTop, bottom: imgElementBottom } = imageNode.getBoundingClientRect();
							if ( ( ( x - ( imgElementLeft + 1 ) ) > 0 ) && ( ( ( ( imgElementBottom - 1 ) - y ) > 0 ) && ( y > ( imgElementTop + 1 ) ) ) ) {
							} else {
								imageNode.classList.remove( 'highlighted' );
							}
						} );
					}

					tomSelectInstance.on( 'change', function( currentValue ) {
						const image = this.control.querySelector( '.item span' );
						if ( image ) {
							const tooltipTemplateNode = document.querySelector( '#scc-tooltip' );
							sccUtils.registerTooltips( image, tooltipTemplateNode, formId );
						}
						let previusValue = this.input.getAttribute( 'data-prev-choice' ) || 0;
						if ( previusValue == 'null' ) {
							previusValue = 0;
						}
						const selected = currentValue;
						triggerSubmit( 2, this.input, 0, previusValue + '/' + selected, formId );
						if ( typeof ( currentValue ) !== 'undefined' ) {
							this.input.setAttribute( 'data-prev-choice', selected );
						}
					} );
				}
			} catch ( e ) {
				console.error( 'Error initializing TomSelect:', e );
			}
		}
		window.triggerTomSelect = triggerTomSelect;

		function triggerSlider( cntx ) {
			try {
				const sliderNode = cntx.querySelector( '.slider-styled' );
				// check if the slider div has a noUiSlider initialized already
				if ( sliderNode && ! sliderNode.hasOwnProperty( 'noUiSlider' ) ) {
					const startValue = parseInt(
						sliderNode.getAttribute( 'data-start-value' ),
					);
					const minValue = parseInt( sliderNode.getAttribute( 'data-slider-min' ) );
					const maxValue = parseInt( sliderNode.getAttribute( 'data-slider-max' ) );
					const slideStep = parseInt( sliderNode.getAttribute( 'data-slider-step' ) );
					const rangeMatrix = sliderNode.getAttribute( 'data_range' ).split( ',' );
					const sliderCalculationType = sliderNode.getAttribute(
						'data-calculation-type',
					);
					const isPriceHintEnabled =
						sliderNode.getAttribute( 'data-show-pricehint' ) == 'true' &&
						sliderCalculationType !== 'quantity_mod';
					const elementId = sliderNode.getAttribute( 'data-elementid' );
					const sliderInputNode = cntx.querySelector( '.scc-slider-input' );
					const cur = sliderNode.getAttribute( 'data-currency' );
					const sym = sliderNode.getAttribute( 'data-symbol' );
					const renderTooltip = ( tooltipVal ) => {
						return `<div class="scc-price-hint-label" >
							<div class="scc-price-hint-box">
								<div class="df-scc-slider-tooltip price-hint-text" >+ ${ tooltipVal }</div>
							</div>
						</div>`;
					};
					const handleTooltips = ( value ) => {
						for ( let k = 0; k < rangeMatrix.length; k += 3 ) {
							let roundedPrice = 0;
							if ( isPriceHintEnabled ) {
								let sliderPrice = 0;
								switch ( sliderCalculationType ) {
									case 'default':
									case 'bulk':
										sliderPrice = parseFloat( rangeMatrix[ k + 2 ] ) * value;
										break;
									case 'bulk-v2':
										sliderPrice = sccUtils.multiplyBasedOnConfig( parseInt( Number( value ) ), rangeMatrix );
										break;
									case 'sliding':
										sliderPrice = parseFloat( rangeMatrix[ k + 2 ] );
										break;
									default:
										sliderPrice = parseFloat( rangeMatrix[ k + 2 ] ) * value;
										break;
								}
								roundedPrice = sliderPrice.toFixed( 2 );
							}
							if ( parseInt( value ) <= parseInt( rangeMatrix[ k + 1 ] ) ) {
								const priceText =
									sym == 0
										? currencySymbol( cur ) + priceCommaStyler( roundedPrice )
										: priceCommaStyler( roundedPrice ) + ' ' + cur;
								return renderTooltip( priceText );
							}
						}
					};
					const sliderInstance = noUiSlider.create( sliderNode, {
						start: [ startValue ],
						connect: 'lower',
						tooltips: isPriceHintEnabled
							? { from: handleTooltips, to: handleTooltips }
							: null,
						range: {
							min: [ minValue, slideStep ],
							max: maxValue,
						},
						cssPrefix: 'df-scc-slider-',
					} );
					const sliderHandleTouchArea = sliderNode.querySelector(
						'.df-scc-slider-touch-area',
					);
					const sliderHandleNode = sliderNode.querySelector(
						'.df-scc-slider-handle',
					);
					sliderHandleTouchArea.innerHTML = `<span class="df-scc-slider-handle-text">${ startValue }</span>`;
					if ( isPriceHintEnabled ) {
						sliderNode.noUiSlider.getTooltips()[ 0 ].style.opacity = '0';
					}
					const sliderHandleText = sliderHandleTouchArea.querySelector(
						'.df-scc-slider-handle-text',
					);
					// let elementId = elementId;
					sliderNode.noUiSlider.on( 'update', ( value ) => {
						const numberOfSteps = ( maxValue - minValue ) / slideStep;
						const currentValue = parseFloat( sliderHandleText.textContent.replace( /,/g, '' ) );
						const handleValue = parseInt( value[ 0 ] ).toFixed();
						sliderHandleText.innerText = Number( handleValue ).toLocaleString(
							'en-US',
							{ maximumFractionDigits: 2 },
						);
						const defaultHeightWeight = '40px';
						const defaultHandleRadius = '20px';
						if ( handleValue.length > 3 ) {
							const charExtraLength = handleValue.length - 4;
							const heightWeight = 55 + charExtraLength * 5 + 'px';
							const handleRadius = 35 + charExtraLength * 5 + 'px';
							sliderHandleNode.style.top = -25 - charExtraLength * 5 + 'px';
							sliderHandleNode.style.width = heightWeight;
							sliderHandleNode.style.height = heightWeight;
							sliderHandleNode.style.borderRadius = handleRadius;
						} else {
							const currentStyle = getComputedStyle( sliderHandleNode );
							if ( currentStyle.borderRadius !== '20px' ) {
								sliderHandleNode.style.top = '-16px';
								sliderHandleNode.style.width = defaultHeightWeight;
								sliderHandleNode.style.height = defaultHeightWeight;
								sliderHandleNode.style.borderRadius = defaultHandleRadius;
							}
						}
						if ( sliderInputNode ) {
							sliderInputNode.value = parseInt( value[ 0 ] ).toFixed();
						}
						if ( handleValue == minValue ) {
							sliderHandleNode.style.right = `-36px`;
						} else {
							const right = parseFloat( window.getComputedStyle( sliderHandleNode ).getPropertyValue( 'right' ) );
							const rightValue = right + ( 31 / numberOfSteps ) * ( ( handleValue - currentValue ) / slideStep );
							sliderHandleNode.style.right = `${ rightValue }px`;
						}
					} );

					const postChangeAction = ( sliderValue ) => {
						const ww = document.querySelector( '#itemcreateds_0_0_' + elementId );
						const showOnDetailedList = sliderNode.getAttribute( 'data-show-on-detailed-list' ) == 'true';
						const formId = sliderNode.getAttribute( 'data-formid' );
						const subid = sliderNode.getAttribute( 'data-subid' );
						const o = sliderNode.getAttribute( 'data-slider-id' );
						const calculationType = sliderNode.getAttribute( 'data-calculation-type' );
						if ( calculationType === 'bulk-v2' ) {
							const newSliderValue = ( sccUtils.multiplyBasedOnConfig( Number( sliderValue ), rangeMatrix ) );
							triggerSubmit(
								5,
								0,
								`${ o }/${ sliderValue }/${ newSliderValue }/${ sliderCalculationType }/${ subid }`,
								0,
								formId,
							);
							return;
						}
						for ( let k = 0; k < rangeMatrix.length; k += 3 ) {
							if ( parseInt( sliderValue ) <= parseInt( rangeMatrix[ k + 1 ] ) ) {
								let labelText = '';
								if ( ww ) {
									const closestElement = ww.closest( '.scc-form-field-item' );
									if ( closestElement ) {
										const labelElement = closestElement.querySelector( 'label' );
										if ( labelElement ) {
											labelText = labelElement.textContent;
										}
									}
								}
								const switched = {
									name: 'title',
									unit: sliderValue,
									value: parseFloat( rangeMatrix[ k + 2 ] ),
									sliderInsubsection: true,
									sliderCalculationType,
									showOnDetailedList,
									paypal_name: labelText,
									calculator_id: 2,
								};
								triggerSubmit(
									5,
									0,
									`${ o }/${ sliderValue }/${ switched.value }/${ switched.sliderCalculationType }/${ subid }`,
									0,
									formId,
								);
								return 'done';
							}
						}
					};

					postChangeAction( startValue );

					const actionPromise = ( value ) => new Promise( function( resolve ) {
						resolve( postChangeAction( value ) );
					} );
					sliderNode.noUiSlider.on( 'set', actionPromise );
					if ( sliderInputNode ) {
						sliderInputNode.addEventListener( 'change', function() {
							sliderNode.noUiSlider.set( [ this.value ] );
						} );
					}
					sliderNode.noUiSlider.on( 'start', function() {
						if ( this.getTooltips() ) {
							this.getTooltips()[ 0 ].style.opacity = 1; // fadeIn
						}
					} );

					sliderNode.noUiSlider.on( 'end', function() {
						if ( this.getTooltips() ) {
							setTimeout( () => {
								this.getTooltips()[ 0 ].style.opacity = 0; // fadeOut
							}, 1500 );
						}
					} );
				}
			} catch ( e ) {
				//
			}
		}

		function triggerCustom( cntx ) {
			const elementId = parseInt( cntx.getAttribute( 'data-elementid' ) );
			const formId = parseInt( cntx.getAttribute( 'data-formid' ) );
			triggerSubmit( 4, this, elementId, 0, formId );
		}

		function triggerFile( element ) {
			const fileInput = element.querySelector( 'input[type="file"]' );
			const uploadBtn = element.querySelector( '.scc-ui.scc-fu-button' );
			const uploadFileNamePlaceholder = element.querySelector( '.scc-ui.scc-fu-action.scc-fu-input input[type="text"]' );

			uploadBtn.addEventListener( 'click', function() {
				fileInput.click();
			} );

			uploadFileNamePlaceholder.addEventListener( 'click', function() {
				fileInput.click();
			} );

			fileInput.addEventListener( 'change', function( e ) {
				let name = '';
				try {
					name = e.target.files[ 0 ].name;
				} catch ( error ) {}

				try {
					if ( e.target.files[ 0 ].size / 1024 > e.target.getAttribute( 'data-maxSize' ) ) {
						const warnText = element.querySelector( '.alert.alert-danger' );
						warnText.innerHTML = '<span class="trn">file size exceeds the limit</span>';
						warnText.style.display = 'block';
						e.target.value = ''; // clear the file input

						initTranslationScriptSCC( warnText, {
							lang: 'en',
							t: translatorJson,
						} );

						setTimeout( () => {
							warnText.style.display = 'none';
						}, 5000 );
					}
				} catch ( error ) {}

				uploadFileNamePlaceholder.value = name;
			} );
		}

		function triggerSignatureBox( sccEl ) {
			const paintCanvas = sccEl.querySelector( 'canvas.signature-box' ); //sccEl is the wrapper of SCC element
			paintCanvas.width = paintCanvas.clientWidth;
			paintCanvas.height = paintCanvas.clientHeight;

			const context = paintCanvas.getContext( '2d' );

			sccEl.querySelector( '.clear-btn' ).addEventListener( 'click', function( e ) {
				context.clearRect( 0, 0, paintCanvas.width, paintCanvas.height );
			} );

			context.lineCap = 'round';
			context.strokeStyle = 'black';
			context.lineWidth = 2;

			let x = 0,
				y = 0;
			let isMouseDown = false;

			const stopDrawing = () => {
				isMouseDown = false;
			};
			const startDrawing = ( event ) => {
				isMouseDown = true;
				[ x, y ] = [ event.offsetX, event.offsetY ];
			};
			const drawLine = ( event ) => {
				if ( isMouseDown ) {
					const newX = event.offsetX;
					const newY = event.offsetY;
					context.beginPath();
					context.moveTo( x, y );
					context.lineTo( newX, newY );
					context.stroke();
					//[x, y] = [newX, newY];
					x = newX;
					y = newY;
				}
			};

			paintCanvas.addEventListener( 'pointerdown', startDrawing );
			paintCanvas.addEventListener( 'pointermove', drawLine );
			window.addEventListener( 'pointerup', stopDrawing );
		}

		function triggerMathSlider( cntx ) {
			try {
				const sliderNodes = cntx.querySelectorAll( '.slider-styled' );
				if ( sliderNodes.length > 0 ) {
					sliderNodes.forEach( ( sliderNode ) => {
						// check if the slider div has a noUiSlider initialized already
						if ( sliderNode && ! sliderNode.hasOwnProperty( 'noUiSlider' ) ) {
							const startValue = parseInt(
								sliderNode.getAttribute( 'data-slider-min' ),
							);
							const minValue = parseInt(
								sliderNode.getAttribute( 'data-slider-min' ),
							);
							const maxValue = parseInt(
								sliderNode.getAttribute( 'data-slider-max' ),
							);
							const slideStep = parseInt(
								sliderNode.getAttribute( 'data-slider-step' ),
							);
							const myCalcId = sliderNode.getAttribute( 'data-form-id' );
							const sliderCalculationType = sliderNode.getAttribute(
								'data-calculation-type',
							);
							const isPriceHintEnabled =
								sliderNode.getAttribute( 'data-show-pricehint' ) == 'true' &&
								sliderCalculationType !== 'quantity_mod';
							const elementId = sliderNode.getAttribute( 'data-elementid' );
							const sliderInputNode = sliderNode.closest( '.scc-vmath-input-boxed-slider-wrapper' )?.querySelector( '.scc-slider-input' );
							const cur = sliderNode.getAttribute( 'data-currency' );
							const sym = sliderNode.getAttribute( 'data-symbol' );
							const sliderInstance = noUiSlider.create( sliderNode, {
								start: [ startValue ],
								connect: 'lower',
								range: {
									min: [ minValue, slideStep ],
									max: maxValue,
								},
								cssPrefix: 'df-scc-slider-',
							} );
							const sliderHandleTouchArea = sliderNode.querySelector(
								'.df-scc-slider-touch-area',
							);
							const sliderHandleNode = sliderNode.querySelector(
								'.df-scc-slider-handle',
							);
							sliderHandleTouchArea.innerHTML = `<span class="df-scc-slider-handle-text 22">${ startValue }</span>`;
							isPriceHintEnabled
								? ( sliderNode.noUiSlider.getTooltips()[ 0 ].style.display =
									'none' )
								: 0;
							const sliderHandleText = sliderHandleTouchArea.querySelector(
								'.df-scc-slider-handle-text',
							);
							sliderNode.noUiSlider.on( 'update', ( value ) => {
								const handleValue = parseInt( value[ 0 ] ).toFixed();
								sliderHandleText.innerText = handleValue;
								const defaultHeightWeight = '40px';
								const defaultHandleRadius = '20px';
								if ( handleValue.length > 4 ) {
									const charExtraLength = handleValue.length - 4;
									const heightWeight = 40 + charExtraLength * 5 + 'px';
									const handleRadius = 20 + charExtraLength * 5 + 'px';
									sliderHandleNode.style.width = heightWeight;
									sliderHandleNode.style.height = heightWeight;
									sliderHandleNode.style.borderRadius = handleRadius;
								} else {
									const currentStyle = getComputedStyle( sliderHandleNode );
									if ( currentStyle.borderRadius !== '20px' ) {
										sliderHandleNode.style.width = defaultHeightWeight;
										sliderHandleNode.style.height = defaultHeightWeight;
										sliderHandleNode.style.borderRadius = defaultHandleRadius;
									}
								}
								if ( sliderInputNode ) {
									sliderInputNode.value = parseInt( value[ 0 ] ).toFixed();
								}
							} );
							const postChangeAction = ( sliderValue ) => {
								sliderNode.value = parseFloat( sliderValue ).toFixed( 0 );
								triggerSubmit( 8, sliderNode, 0, 0, myCalcId );
							};

							postChangeAction( startValue );
							const actionPromise = ( value ) =>
								new Promise( function( resolve, reject ) {
									resolve( postChangeAction( value ) );
								} );
							sliderNode.noUiSlider.on( 'change', actionPromise );
							sliderInputNode &&
								sliderInputNode.addEventListener( 'change', function() {
									sliderNode.noUiSlider.set( [ this.value ] );
									actionPromise( this.value );
								} );
							sliderNode.noUiSlider.on( 'start', function() {
								setTimeout( () => {
									//
								}, 1000 );
							} );
							sliderNode.noUiSlider.on( 'end', function() {
								setTimeout( () => {
									//
								}, 3000 );
							} );
						}
					} );
				} else {
					/* here, we detect if the variable math does not contain any input or slider element, if none of the types are there, we
							  try to trigger change event to the first of the input nodes representing the element attached, a hack to trigger the calculation
							  beacuse without it, the variable math calculation does not kick off. */
					const inputFieldsInVariableMath =
						cntx.querySelectorAll( '[data-type="1"]' );
					if ( inputFieldsInVariableMath.length == 0 ) {
						const firstOfAttachedElementNode =
							cntx.querySelector( '[data-type="3"]' );
						sccUtils.triggerChangeEvent( firstOfAttachedElementNode );
					}
				}
			} catch ( e ) {

			}
		}
	} );
	document.querySelectorAll( '#scc_form_' + formId + ' .imageplus' ).forEach( ( img ) => {
		const buttons = function() {
			const container = document.createElement( 'div' );
			const btnL = document.createElement( 'button' );
			btnL.className = 'scc-left';
			btnL.innerHTML = '-';
			const num = document.createElement( 'span' );
			num.className = 'scc-number';
			num.innerHTML = '0';
			const btnR = document.createElement( 'button' );
			btnR.className = 'scc-right';
			btnR.innerHTML = '+';
			container.appendChild( btnL );
			container.appendChild( num );
			container.appendChild( btnR );
			return container;
		};
		img.querySelector( 'input' ).disabled = true;
		img.querySelector( '.scc-img-quantity-selec' ).appendChild( buttons() );
		const trigger = function() {
			const dom = this.closest( '.imageplus' );
			const input = this.closest( '.imageplus' ).querySelector( 'input' );
			const $number = dom.querySelector( '.scc-number' );
			const element = input.getAttribute( 'data-elementid' );
			const item = input.getAttribute( 'data-itemid' );
			const cal = input.getAttribute( 'data-formid' );
			if ( this.innerHTML == '+' ) {
				$number.innerHTML = parseInt( $number.innerHTML ) + 1;
			} else {
				$number.innerHTML =
					parseInt( $number.innerHTML ) > 0
						? parseInt( $number.innerHTML ) - 1
						: parseInt( $number.innerHTML );
			}
			triggerSubmit( 9, dom, element, item, cal );
		};
		img.querySelector( '.scc-left' )?.addEventListener( 'click', trigger );
		img.querySelector( '.scc-right' )?.addEventListener( 'click', trigger );
		img.querySelector( '.scc-left' )?.click();
	} );
	const { config } = sccData[ formId ];
	const { enableSmartURL } = config;
	if ( enableSmartURL ) {
		sccUtils.hydrateCalcForm( formId );
	}
}
window.dfsccLoaded = dfsccLoaded;
//total script
/**
 * *totalScript
 * todo: on calculator select, check, slide adds the id of the element to calcule the total
 * ?type attr takes 1 for checkboxes, 2 for dropdown, 3 for cuantityBox, 4 for customMath 5 for slider
 * ?1 needs to evaluete if checked is the id of the element is added, itemsTotal else is remove
 * ?2 needs to have the previus value, the previus id is replaced with the new id
 * ?3 needs to have qnt if id doenst exist is added to elementsTotal else is replace with the new qnt
 * ?4 on load the id is added to customMath
 * ?5 the id,qnt,price,scaling is added if doesnt exist else qnt, price, isScaling is replace
 * ?6 extra props, varies by the element
 * @param type
 * @param dom
 * @param element
 * @param item
 * @param calcId
 * @param extraProps
 */
function triggerSubmit( type, dom, element, item, calcId, extraProps = false ) {
	const { smartURLTriggerSubmitCallback, parseValueFromElementInteraction } = sccUtils;
	const intractionData = parseValueFromElementInteraction( { type, dom, element, item, calcId } );
	const { config } = sccData[ calcId ];
	const { enableSmartURL } = config;
	if ( enableSmartURL && smartURLTriggerSubmitCallback ) {
		smartURLTriggerSubmitCallback( { type, calcId, sccData, intractionData } );
	}
	const container = document.querySelector( '#scc_form_' + calcId );
	// 1 checkboxes, 2 dropdown, 3 quantity box, 4 custom math, 5 slider, 6 radiobutton, 10 Single date Picker, 11 Date Range
	const total = [ document.querySelector( '.scc-total-' + calcId ) ];
	const floatingBarTotal = document.querySelector( "[data-floating-total-bar='" + calcId + "']" );
	const floatingBarConvertedTotal = floatingBarTotal ? floatingBarTotal?.querySelector( '.df-scc-tp5-conv-total' ) : null;
	// find the floating total bar's total div if the total is null
	if ( floatingBarTotal ) {
		total.push( floatingBarTotal );
	}
	const { priceRangeTotalSettings } = config;
	let numberDom;
	const check = false;
	// sccSetupTooltips( calcId );
	//console.log(item);
	switch ( type ) {
		case 1:

			var p = intractionData.value;
			if ( p && itemsTotal[ calcId ].includes( item ) == false ) {
				itemsTotal[ calcId ].push( item );
			} else {
				var index = itemsTotal[ calcId ].indexOf( item );
				if ( index >= 0 ) {
					itemsTotal[ calcId ].splice( index, 1 );
				}
			}
			if ( dom.type == 'radio' ) {
				const parentElementId = dom.dataset.elementid;
				const relatedCheckboxes = [ ...document.querySelectorAll( `[data-elementid="${ parentElementId }"]` ) ].filter( ( z ) => z !== dom );
				relatedCheckboxes.forEach( ( z ) => {
					itemsTotal[ calcId ] = itemsTotal[ calcId ].filter( ( x ) => x !== 'chkbx-' + z.dataset.itemid );
				} );
			}
			break;
		case 2:
			var splited = item.split( '/' );
			var prev = splited[ 0 ];
			var now = splited[ 1 ];
			if ( prev && prev !== 'undefined' ) {
				if ( itemsTotal[ calcId ].indexOf( prev ) >= 0 ) {
					itemsTotal[ calcId ]?.splice( itemsTotal[ calcId ].indexOf( prev ), 1 );
				}
			}
			if ( now != 0 && now !== 'undefined' ) {
				if ( itemsTotal[ calcId ].includes( now ) == false ) {
					itemsTotal[ calcId ].push( now );
				}
			}
			break;
		case 3:
			// remove commas
			let numberWithoutComma = dom.value.replace( /,/g, '' );
			if ( parseFloat( numberWithoutComma ) > parseFloat( dom.getAttribute( 'max' ) ) ) {
				numberWithoutComma = dom.getAttribute( 'max' );
			}
			if ( parseFloat( numberWithoutComma ) < parseFloat( dom.getAttribute( 'min' ) ) ) {
				numberWithoutComma = dom.getAttribute( 'min' );
			}
			numberDom = numberWithoutComma;
			var o = [ element, numberDom ];
			var ss = elementsTotal[ calcId ].find( ( e ) => e[ 0 ] == element );
			if ( ss ) {
				ss[ 1 ] = numberDom;
			} else {
				elementsTotal[ calcId ].push( o );
			}
			if ( numberDom == 0 ) {
				var index = elementsTotal[ calcId ].indexOf( ss );
				elementsTotal[ calcId ].splice( index, 1 );
			}
			break;
		case 4:
			const m = customMath[ calcId ].includes( element );
			if ( ! m ) {
				customMath[ calcId ].push( element );
			}
			break;
		case 5:
			var elementComposed = element.split( '/' ); //brings id,qnt,price,isScaling
			var idComposed = elementComposed[ 0 ].split( '-' );
			var id = idComposed[ 1 ];
			var qnt = elementComposed[ 1 ];
			var price = elementComposed[ 2 ];
			var calculationType = elementComposed[ 3 ];
			var subcId = elementComposed[ 4 ];
			var f = Sliders[ calcId ].find( ( s ) => s[ 0 ] == id );

			//this script help us to find the most expensive cost and savings calculation
			//this trigger the savings message in the frontend as well
			const sliderElement = document.querySelector( '#ssc-elmt-' + id );
			var range = sliderElement.querySelector( '.slider-styled' ).getAttribute( 'data_range' ).split( ',' );
			var grouped = [];
			var priceRanges = [];
			for ( let i = 0; i < range.length; i += 3 ) {
				grouped.push( range.slice( i, i + 3 ) );
			}
			//getting array of prices (ranges)
			for ( let i = 0; i < grouped.length; i++ ) {
				priceRanges.push( grouped[ i ][ 2 ] );
			}
			var mostExpensivePrice = Math.max( ...priceRanges );
			var savings = ( qnt * mostExpensivePrice ) - ( calculationType == 'bulk-v2' ? ( price ) : ( qnt * price ) );
			var savingsScale = mostExpensivePrice - price;
			var savingsMessage = sliderElement.querySelector( '.scc-slider-cost-savings.scc-savings-' + id );
			var percentage = calculationType == 'bulk-v2' ? `${ Math.round( ( savings / ( qnt * mostExpensivePrice ) ) * 100 ) }%` : `${ Math.round( ( savingsScale / mostExpensivePrice ) * 100 ) }%`;

			var displaySavings = savingsMessage?.getAttribute( 'data-display-savings' );
			var renderBulkSavings = getCurrencyText( calcId, savings ) + percentage;
			let renderBulkV2Savings = getCurrencyText( calcId, savings ) + percentage;
			var renderScaleSavings = getCurrencyText( calcId, savingsScale ) + percentage;
			var renderSavingsList = '';
			if ( displaySavings == '' || displaySavings == '$' ) {
				renderBulkSavings = getCurrencyText( calcId, savings );
				renderBulkV2Savings = getCurrencyText( calcId, savings );
				renderScaleSavings = getCurrencyText( calcId, savingsScale );
			}
			if ( displaySavings == '%' ) {
				renderBulkSavings = percentage;
				renderBulkV2Savings = percentage;
				renderScaleSavings = percentage;
			}
			if ( displaySavings == '$+%' ) {
				renderBulkSavings = getCurrencyText( calcId, savings ) + ' (' + percentage + ')';
				renderBulkV2Savings = getCurrencyText( calcId, savings ) + ' (' + percentage + ')';
				renderScaleSavings = getCurrencyText( calcId, savingsScale ) + ' (' + percentage + ')';
			}
			if ( savingsMessage ) {
				if ( savings > 0 ) {
					savingsMessage.style.display = '';
					sliderElement.style.flexWrap = 'wrap';
					savingsMessage.querySelector( '.scc-savings-label' ).innerHTML = `<b>${ sccGetTranslationByKey( calcId, 'You Saved' ) }:</b>`;
					if ( calculationType == 'bulk' ) {
						savingsMessage.querySelector( '.scc-savings-cost' ).innerHTML = renderBulkSavings;
						renderSavingsList = `${ sccGetTranslationByKey( calcId, 'You Saved' ) }: ${ renderBulkSavings }`;
					} else if ( calculationType == 'bulk-v2' ) {
						savingsMessage.querySelector( '.scc-savings-cost' ).innerHTML = renderBulkV2Savings;
						renderSavingsList = `${ sccGetTranslationByKey( calcId, 'You Saved' ) }: ${ renderBulkV2Savings }`;
					} else {
						savingsMessage.querySelector( '.scc-savings-cost' ).innerHTML = renderScaleSavings;
						renderSavingsList = `${ sccGetTranslationByKey( calcId, 'You Saved' ) }: ${ renderScaleSavings }`;
					}
				} else {
					savingsMessage.style.display = 'none';
				}
			}
			//end of script

			var o = [ id, qnt, price, calculationType, subcId, renderSavingsList ];
			if ( savingsMessage ) {
				if ( calculationType === 'bulk-v2' ) {
					o.push( renderBulkV2Savings );
				} else {
					o.push( calculationType === 'bulk' ? renderBulkSavings : renderScaleSavings );
				}
			}

			if ( f ) {
				f[ 1 ] = qnt;
				f[ 2 ] = price;
				f[ 3 ] = calculationType;
				f[ 5 ] = renderSavingsList;
				if ( savingsMessage ) {
					f[ 6 ] = calculationType === 'bulk' ? renderBulkSavings : renderScaleSavings;
				}
			} else {
				Sliders[ calcId ].push( o );
			}
			break;
		case 6:
			var i = new RegExp( /chkbx-/ ).test( item ) ? item.replace( 'chkbx-', '' ) : item;
			var o = document
				.querySelector( '#ssc-elmt-' + element )
				.querySelectorAll( 'input' )
				.forEach( function( e ) {
					const popo = e.getAttribute( 'radio-item-number' );
					//console.log(parseInt(popo));
					const index = itemsTotal[ calcId ].indexOf( 'chkbx-' + parseInt( popo ) );
					if ( index >= 0 ) {
						itemsTotal[ calcId ].splice( index, 1 );
					}
				} );
			itemsTotal[ calcId ].push( 'chkbx-' + i );
			//console.log(itemsTotal[calcId]);
			break;
		case 7:
			var numberDom2 = dom.value;
			var o = [ element, numberDom2 ];
			var ss = elementsComment[ calcId ].find( ( e ) => e[ 0 ] == element );
			if ( ss ) {
				ss[ 1 ] = numberDom2;
			} else {
				elementsComment[ calcId ].push( o );
			}
			if ( numberDom2 == 0 ) {
				var index = elementsComment[ calcId ].indexOf( ss );
				elementsComment[ calcId ].splice( index, 1 );
			}
			break;
		case 8:
			const idmv = dom.getAttribute( 'data-elementid' );
			const vmathRoot = sccUtils
				.getCalcWrapper( calcId )
				.querySelector( `#ssc-elmt-${ idmv }` );
			const useZeroAsFallback = vmathRoot.classList.contains( 'scc-use-zero-as-fallback' );
			if ( parseFloat( dom.value ) > parseFloat( dom.getAttribute( 'max' ) ) ) {
				dom.value = dom.getAttribute( 'max' );
			}
			if ( parseFloat( dom.value ) < parseFloat( dom.getAttribute( 'min' ) ) ) {
				dom.value = dom.getAttribute( 'min' );
			}
			const mv = [ idmv, changedVariableMath( dom ), useZeroAsFallback ];
			const sortOperation = variableMathElement[ calcId ].find( ( m ) => m[ 0 ] == idmv );
			if ( sortOperation ) {
				sortOperation[ 1 ] = changedVariableMath( dom );
			} else {
				variableMathElement[ calcId ].push( mv );
			}
			break;
		case 9:
			const qtn = dom.querySelector( '.scc-number' ).innerHTML;
			const split = function( n ) {
				return String( n ).split( '/' )[ 0 ];
			};
			if ( qtn > 0 ) {
				var index = itemsTotal2[ calcId ].map( split ).indexOf( item );
				if ( index == -1 ) {
					itemsTotal2[ calcId ].push( item + '/' + 1 );
				} else {
					// modify qtn
					var index = itemsTotal2[ calcId ].map( split ).indexOf( item );
					if ( index >= 0 ) {
						itemsTotal2[ calcId ][ index ] = item + '/' + qtn;
					}
				}
			} else {
				var index = itemsTotal2[ calcId ].map( split ).indexOf( item );
				if ( index >= 0 ) {
					itemsTotal2[ calcId ].splice( index, 1 );
				} else {
					itemsTotal2[ calcId ].push( item + '/' + 0 );
				}
			}
			break;
		case 10:
			//element date
			const { isTime, timeValue } = extraProps;
			var formatDate = sccData[ calcId ]?.pdf?.dateFormat ?? 'yyyy-mm-dd';
			var date = sccFormatStringDate( sccStandardDateFormat( dom.value, formatDate ), formatDate );
			var o = [ element, date ];
			var ss = elementDate[ calcId ].find( ( e ) => e[ 0 ] === element );
			if ( ss ) {
				ss[ 1 ] = [ date, timeValue ].join( ' ' );
			} else {
				elementDate[ calcId ].push( o );
			}
			if ( ! date ) {
				elementDate[ calcId ].splice( ss, 1 );
			}
			break;
		case 11:
			//Element date range
			const container = dom.closest( '.scc-form-field-item-control' );
			const startDate = container.querySelector( '.scc-start-date' );
			const startDateTime = container.querySelector( '.scc-start-date  + .time-input' );
			const endDate = container.querySelector( '.scc-end-date' );
			const endDateTime = container.querySelector( '.scc-end-date  + .time-input' );
			const flatPickerConfig = startDate._flatpickr.config;
			const { dateRangePricingStructure, subsectionId } = container.dataset;
			// const subsectionId = sliderNode.getAttribute( 'data-subId' );

			var formatDate = sccData[ calcId ]?.config?.pdf?.dateFormat ?? 'yyyy-mm-dd';
			var price = container.querySelector( '.scc-date-cost-per-date' ).value;
			var qnt = 1;
			// get the dates from the input fields
			const startDateVal = new Date( sccStandardDateFormat( startDate.value, formatDate ) );
			const endDateVal = new Date( sccStandardDateFormat( endDate.value, formatDate ) );
			startDateVal.setHours( 0, 0, 0, 0 );
			endDateVal.setHours( 0, 0, 0, 0 );
			if ( startDate.value ) {
				const min_end_date = new Date( startDateVal.getTime() );
				const min_end_date_string = min_end_date.toISOString().slice( 0, 10 );
				endDate.setAttribute( 'min', min_end_date_string );
				const event = new Event( 'sccMinChanged' );
				endDate.dispatchEvent( event );
			} else {
				endDate.setAttribute( 'min', '' );
			}
			//validate if the start date is greater than the end date
			var date = '';
			if ( startDate.value != '' && endDate.value != '' ) {
				let { includeDays } = startDate.dataset;
				includeDays = includeDays ? includeDays.split( ', ' ) : [];
				const allDays = [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ];
				const excludedDays = allDays.filter( ( day ) => ! includeDays.includes( day ) );
				const { numberOfExcludedDays } = sccUtils.countExcludedDaysInDatePicker( startDateVal, endDateVal, flatPickerConfig.disable, excludedDays );
				const totalExcludedDays = numberOfExcludedDays;
				if ( startDateVal > endDateVal ) {
					//alert('The start date must be less than the end date');
					  //container.querySelector('.scc-start-date').value = '';
					  container.querySelector( '.scc-end-date' ).value = startDate.value;
					const warningNode = container.querySelector( '.scc-err-date-range' );
					  warningNode.classList.remove( 'scc-d-none' );

					  date = '';
					  setTimeout( () => {
						  warningNode.classList.add( 'scc-d-none' );
					  }, 5000 );
					  return;
				  }
					  const difference = ( endDateVal - startDateVal ) - ( totalExcludedDays * 24 * 60 * 60 * 1000 );
					  qnt = Math.floor( difference / ( 1000 * 60 * 60 * 24 ) ) + 1;

				const fromTime = startDateTime.value;
				const toTime = endDateTime.value;
				let fromDate = sccFormatStringDate( sccStandardDateFormat( startDate.value, formatDate ), formatDate );
				let toDate = sccFormatStringDate( sccStandardDateFormat( endDate.value, formatDate ), formatDate );
				if ( fromTime ) {
					fromDate = `${ fromDate } ${ fromTime }`;
				}
				if ( toTime ) {
					toDate = `${ toDate } ${ toTime }`;
				}
				  	date = `${ sccGetTranslationByKey( calcId, 'From' ) } ${ fromDate } ${ sccGetTranslationByKey( calcId, 'To' ) } ${ toDate }`;
			}
			var o = [ element, date, price, qnt, dateRangePricingStructure, subsectionId ];
			var ss = elementDate[ calcId ].find( ( e ) => e[ 0 ] == element );
			if ( ss ) {
				ss[ 1 ] = date;
				ss[ 2 ] = price;
				ss[ 3 ] = qnt;
				ss[ 4 ] = dateRangePricingStructure;
				ss[ 5 ] = subsectionId;
			} else {
				elementDate[ calcId ].push( o );
			}
			//if (!date) elementDate[calcId].splice(ss, 1);
			if ( ! date ) {
				var index = elementDate[ calcId ].indexOf( ss );
				elementDate[ calcId ].splice( index, 1 );
			}

			break;
		case 12:
			//distance calculation trigger
			var o = [ element, dom.value ];
			const containerContext = dom.closest( '.scc-distance' );
			const inputStart = containerContext.querySelector( '.scc-distance-input-start' );
			const inputEnd = containerContext.querySelector( '.scc-distance-input-end' );
			let distanceDesc = '';
			var qnt = 0;
			const calcType = dom.getAttribute( 'data-calculation-type' );
			let distanceUnit = 'KM';
			const addressOfFirstInput = inputStart.value;
			const addressOfSecondInput = inputEnd.value;
			if ( inputStart.getAttribute( 'data-distance-units' ) == 'km' ) {
				distanceUnit = 'KM';
			} else if ( inputStart.getAttribute( 'data-distance-units' ) == 'mi' ) {
				distanceUnit = 'MILES';
			}
			if ( inputStart.value != '' && inputEnd.value != '' ) {
				distanceDesc = `${ sccGetTranslationByKey( calcId, 'From' ) } ` + addressOfFirstInput + ` ${ sccGetTranslationByKey( calcId, 'To' ) } ` + addressOfSecondInput + ` (${ distanceUnit })`;
			}

			const distance = inputEnd.getAttribute( 'data-numeric-value' );
			if ( distance == '' || distance == null ) {
				qnt = 0;
			} else if ( sccUtils.detectStripePaymentMethod( calcId ) ) {
				qnt = Math.round( distance );
			} else {
				qnt = parseFloat( distance );
			}

			var range = dom.getAttribute( 'data-range' ).split( ',' );
			var grouped = [];
			var priceRanges = [];
			for ( let i = 0; i < range.length; i += 3 ) {
				grouped.push( range.slice( i, i + 3 ) );
			}
			//getting array of prices (ranges)
			for ( let i = 0; i < grouped.length; i++ ) {
				priceRanges.push( grouped[ i ][ 2 ] );
			}

			var price = parseFloat( priceRanges[ 0 ] );

			var o = [ element, distanceDesc, price, qnt, calcType, addressOfFirstInput, addressOfSecondInput, distanceUnit ];
			var ss = elementDistance[ calcId ].find( ( e ) => e[ 0 ] == element );
			if ( ss ) {
				ss[ 1 ] = distanceDesc;
				ss[ 2 ] = price;
				ss[ 3 ] = qnt;
				ss[ 4 ] = calcType;
				ss[ 5 ] = addressOfFirstInput;
				ss[ 6 ] = addressOfSecondInput;
				ss[ 7 ] = distanceUnit;
			} else {
				elementDistance[ calcId ].push( o );
			}

			break;
	}
	container.querySelectorAll( '[id*=ssc-elmt-]' ).forEach( function( e ) {
		if ( e.classList.contains( 'scc-conditional-hidden' ) ) {
			return;
		}
		/**
		 * *For dropdown
		 */
		const sa = e.getAttribute( 'id' );
		const drop = e.classList.contains( 'scc_dropdown_' );
		if ( drop ) {
			const select = e.querySelector( 'select' );
			const o = select ? select.options[ select.selectedIndex ].value : 0;
			var index = itemsTotal[ calcId ].indexOf( o );
			if ( index < 0 ) {
				itemsTotal[ calcId ].push( o );
			}
		}
		/**
		 * *For Checkboxes
		 */
		const chck = e.classList.contains( 'scc-chkbx-container' );
		if ( chck ) {
			const ch = e.querySelectorAll( 'input' ).forEach( function( e ) {
				const s = e.checked;
				if ( s ) {
					const i = e.getAttribute( 'id' ).split( '_' );
					const index = itemsTotal[ calcId ].indexOf(
						'chkbx-' + parseInt( i[ i.length - 1 ] ),
					);
					if ( index < 0 ) {
						itemsTotal[ calcId ].push( 'chkbx-' + parseInt( i[ i.length - 1 ] ) );
					}
				}
			} );
		}
		/**
		 * *Quantity box
		 */
		const isQuantityInput = e.getAttribute( 'data-typeelement' ) == 'quantity box';
		if ( isQuantityInput ) {
			var i = e.getAttribute( 'id' ).split( '-' );
			var index = elementsTotal[ calcId ].findIndex(
				( e ) => e[ 0 ] == parseInt( i[ i.length - 1 ] ),
			);
			const qtnInputValue = e.querySelector(
				'[data-inputtype="number_input"]',
			).value;
			if ( index < 0 && qtnInputValue > 0 ) {
				elementsTotal[ calcId ].push( [ parseInt( i[ i.length - 1 ] ), qtnInputValue ] );
			}
		}
		/**
		 * *For Custom math
		 */
		const customM = e.classList.contains( 'ssc_custom_math__' );
		if ( customM ) {
			var i = e.getAttribute( 'id' ).split( '-' );
			var index = customMath[ calcId ].indexOf( parseInt( i[ i.length - 1 ] ) );
			if ( index < 0 ) {
				customMath[ calcId ].push( parseInt( i[ i.length - 1 ] ) );
			}
		}
		/**
		 * *Slider
		 */
		const slider = e.classList.contains( 'scc_sliderr__' );
		if ( slider ) {
			var i = e.getAttribute( 'id' ).split( '-' );
			var index = Sliders[ calcId ].find( ( s ) => s[ 0 ] == i[ i.length - 1 ] );
			if ( ! index ) {
				//crear vaa
				const sliderNode = e.querySelector( '.slider-styled' );
				const sliderHandleNode = sliderNode.querySelector(
					'.df-scc-slider-handle',
				);
				const qtn = sliderHandleNode
					? parseInt( sliderHandleNode.getAttribute( 'aria-valuetext' ) )
					: sliderNode.getAttribute( 'data-start-value' );
				const calculationType = sliderNode.getAttribute( 'data-calculation-type' );
				const range = sliderNode.getAttribute( 'data_range' ).split( ',' );
				const subId = sliderNode.getAttribute( 'data-subId' );
				//groups each 3 elements 0 range min 1 range max 2 price
				const grouped = [];
				for ( let i = 0; i < range.length; i += 3 ) {
					grouped.push( range.slice( i, i + 3 ) );
				}
				//get price acording to range
				function getPriceRange( group, qnt2 ) {
					let o = null;
					group.forEach( ( g ) => {
						if (
							parseInt( qnt2 ) >= parseInt( g[ 0 ] ) &&
							parseInt( qnt2 ) <= parseInt( g[ 1 ] )
						) {
							o = g[ 2 ];
						}
					} );
					return o;
				}
				//sends again to total script
				let o = [];
				id = i[ i.length - 1 ];
				qnt = qtn;
				price = getPriceRange( grouped, qtn );
				subcId = subId;
				o = [ id, qnt, price, calculationType, subcId ];
				if ( qnt != 0 ) {
					Sliders[ calcId ].push( o );
				}
			}
		}
		/**
		 * Custom Text/HTML
		 */
		const isCustomHtml = e.classList.contains( 'scc-custom-html' );
		if ( isCustomHtml ) {
			var i = e.getAttribute( 'id' ).split( '-' );
			var index = itemsTotal[ calcId ].indexOf( 'html-' + parseInt( i[ i.length - 1 ] ) );
			if ( index < 0 ) {
				itemsTotal[ calcId ].push( 'html-' + parseInt( i[ i.length - 1 ] ) );
			}
		}
	} );
	/**
	 * *Gets the prices of elements, except for slider
	 * ?the prices are found with the element value received in the method in the calculator data, if esxists the amount is added to
	 * ?sections total, the total is calculated for sections to be shown in section subtotal if enabled
	 * ?elments /items are added to itemsArray to be used in detail, id, name, qnt, price, description !for slider if description is true means slidingScale is set true
	 * ?maths (custom math modifies the total)
	 */
	const { sections } = config;
	// elementitem
	const mathArray = []; //for detail
	const arraySectItems = [];
	itemsArray[ calcId ] = [];
	globalMathArray[ calcId ] = [];
	mathArray[ calcId ] = [];
	arraySectItems[ calcId ] = [];
	sections.forEach( ( secs, sectionIndex ) => {
		// let tot = 0;
		const secOne = [];
		const maths = [];
		const sub = secs.subsection;

		if ( sub.length ) {
			itemsArray[ calcId ].push( {
				section: secs.name,
				sectionId: secs.id,
				showSectionTotalOnPdf: secs.showSectionTotalOnPdf,
			} );
		}
		sub.forEach( ( subs, subIndex ) => {
			const elmts = subs.element;
			const countElmts = elmts.length;
			elementsTotal[ calcId ].forEach( ( et ) => {
				const itemOne = [];
				const f = elmts.find( ( el1 ) => el1.id == et[ 0 ] );
				// Multiplies the quantity box with the slider
				let qt = 1;
				Sliders[ calcId ].forEach( ( scs ) => {
					const g = elmts.find( ( itt ) => itt.subsection_id == scs[ 4 ] );
					if ( g ) {
						qt = scs[ 1 ];
					}
				} );
				elementDate[ calcId ].forEach( ( scs ) => {
					const g = elmts.find( ( itt ) => itt.subsection_id == scs[ 5 ] );
					if ( g && [ 'quantity_modifier_and_unit_price', 'quantity_mod' ].includes( scs[ 4 ] ) ) {
						qt = scs[ 3 ];
					}
				} );
				if ( f ) {
					//quantity box
					itemOne.id_element = f.id;
					itemOne.type = 'quantityInput';
					itemOne.qtn = et[ 1 ] * qt;
					itemOne.name = renderIconDetailedListPDF( f ) + f.titleElement;
					itemOne.description = '';
					itemOne.price = f.value2;
					itemOne.parentSectionId = secs.id;
					itemOne.subSectionIndex = subIndex;
					itemOne.woo_commerce = {
						product_id: parseInt( f.element_woocomerce_product_id ) || 0,
						quantity: parseFloat( itemOne.qtn ),
					};

					if ( f.uniqueId ) {
						itemOne.uniqueId = f.uniqueId;
					} else {
						console.error( 'uniqueId is not set' );
					}
					itemsArray[ calcId ].push( itemOne );
					if ( f.showPriceHint == '1' && f.id == element ) {
						const calculationResultNode = sccUtils
							.getCalcWrapper( calcId )
							.querySelector(
								`#ssc-elmt-${ f.id } .scc-price-hint-box .price-hint-text`,
							);
						if ( calculationResultNode ) {
							const calculatedValue = parseFloat( f.value2 ) * ( parseFloat( et[ 1 ] ) * qt );
							calculationResultNode.textContent =
								'+ ' + getCurrencyText( calcId, calculatedValue );
							//console.log("step#6");
							calculationResultNode.classList.remove( 'scc-d-none' );

							const priceHintElement = document.querySelector( `#ssc-elmt-${ f.id } .scc-price-hint-box .price-hint-text` );
							sccShowPriceHint( priceHintElement );
						}
						// show price hint
					}
				}
			} );
			elementsComment[ calcId ].forEach( ( et1 ) => {
				const itemOne = [];
				const f = elmts.find( ( el1 ) => el1.id == et1[ 0 ] );
				if ( f ) {
					itemOne.id_element = f.id;
					itemOne.qtn = 0;
					itemOne.name = renderIconDetailedListPDF( f ) + f.titleElement;
					itemOne.type = 'comment';
					itemOne.description = et1[ 1 ];
					itemOne.price = 0;
					itemOne.parentSectionId = secs.id;
					itemOne.subSectionIndex = subIndex;

					if ( f.uniqueId ) {
						itemOne.uniqueId = f.uniqueId;
					} else {
						console.error( 'uniqueId is not set' );
					}
					itemsArray[ calcId ].push( itemOne );
				}
			} );

			Sliders[ calcId ].forEach( ( Ss ) => {
				// match against currently available elements in this subsection
				const f = elmts.find( ( s ) => s.id == Ss[ 0 ] );
				// converting possible null value to number
				Ss[ 2 ] = Number( Ss[ 2 ] );
				const itemOne = [];
				if ( f ) {
					itemOne.id_element = f.id;
					itemOne.qtn = Ss[ 1 ];
					itemOne.name = renderIconDetailedListPDF( f ) + f.titleElement;
					itemOne.description = Ss[ 5 ];
					itemOne.calculationType = Ss[ 3 ];
					itemOne.price = Ss[ 2 ];
					itemOne.type = 'slider';
					itemOne.savings = Ss[ 6 ];
					itemOne.displayDetailList =
						f.displayDetailList == 3 ? false : true;
					itemOne.parentSectionId = secs.id;
					itemOne.subSectionIndex = subIndex;
					itemOne.woo_commerce = {
						product_id: parseInt( f.elementitems[ 0 ].woocomerce_product_id ) || 0,
						quantity: parseFloat( itemOne.qtn ),
					};
					// Here, Ss[3] is to determine if it is a sliding scale
					if ( f.uniqueId ) {
						itemOne.uniqueId = f.uniqueId;
					} else {
						console.error( 'uniqueId is not set' );
					}
					switch ( Ss[ 3 ] ) {
						case 'sliding':
							if ( parseFloat( Ss[ 1 ] ) > 0 ) {
							}
							itemsArray[ calcId ].push( itemOne );
							break;
						case 'quantity_mod':
							itemsArray[ calcId ].push( itemOne );
							break;
						case 'default':
							itemsArray[ calcId ].push( itemOne );
							break;
						default:
							itemsArray[ calcId ].push( itemOne );
							break;
					}
				}
			} );
			elmts.forEach( ( itms ) => {
				/**
				 * if the current element is a dropdown, add dropdownTitle property to it's items
				 * to reference it in webhook as a title of the dropdown and the choice items name
				 */
				if ( itms.type == 'Dropdown Menu' ) {
					itms.elementitems = itms.elementitems.map( ( elementitem ) => {
						elementitem.dropdownTitle = itms.titleElement;
						elementitem.showTitlePdf = itms.showTitlePdf;
						elementitem.titleIcon = renderIconDetailedListPDF( itms );
						return elementitem;
					} );
				}

				let num = 0;
				/**
				 * *Multiplies the number of items in the same subsection
				 */
				let q = 1;
				Sliders[ calcId ].forEach( ( scs ) => {
					const g = elmts.find( ( itt ) => itt.subsection_id == scs[ 4 ] );
					if ( g ) {
						q = scs[ 1 ];
					}
				} );
				elementDate[ calcId ].forEach( ( scs ) => {
					const g = elmts.find( ( itt ) => itt.subsection_id == scs[ 5 ] );
					if ( g && [ 'quantity_modifier_and_unit_price', 'quantity_mod' ].includes( scs[ 4 ] ) ) {
						q = scs[ 3 ];
					}
				} );
				const items = itms.elementitems;
				// using Set for unique value only
				const uniqueItemsTotal = [ ...new Set( itemsTotal[ calcId ] ) ];
				uniqueItemsTotal.forEach( ( it ) => {
					const itemOne = [];
					const f = items.find( ( el2 ) =>
						el2.type == 'checkbox' || itms.type == 'checkbox'
							? 'chkbx-' + el2.id == it
							: el2.id == it,
					);
					const type = itms.type;
					if ( f ) {
						num = f.price;
						itemOne.id_elementItem = f.id;
						itemOne.element_id = itms.id;
						itemOne.qtn = q;
						itemOne.type = type;
						itemOne.name = renderIconDetailedListPDF( f ) + f.name.replace( /\\/g, '' );
						itemOne.description = f.description.replace( /\\/g, '' );
						itemOne.showDescriptionOnPdf = itms.value6 == '1' ? true : false;
						itemOne.image_url = f.value1;
						itemOne.show_image_on_pdf = itms.show_image_on_pdf === '1' ? true : false;
						itemOne.price = f.price;
						itemOne.parentSectionId = secs.id;
						itemOne.subSectionIndex = subIndex;
						if ( f.dropdownTitle ) {
							itemOne.dropdownTitle = f.dropdownTitle;
							itemOne.showTitlePdf = f.showTitlePdf;
						}
						itemOne.woo_commerce = {
							product_id: parseInt( f.woocomerce_product_id ) || 0,
							quantity: parseFloat( itemOne.qtn ),
						};

						if ( f.uniqueId ) {
							itemOne.uniqueId = f.uniqueId;
						} else {
							console.error( 'uniqueId is not set' );
						}
						itemsArray[ calcId ].push( itemOne );
						// determining if the current interacted element is a dropdown, and it has price hint enabled, also if the dom object
						// is not a valid html DOM object
						try {
							if ( itms.showPriceHint == '1' && itms.type == 'Dropdown Menu' && type == 'Dropdown Menu' && dom !== window && dom && dom.closest( '[data-elementid]' )?.getAttribute( 'data-elementid' ) == itms.id ) {
								const calculationResultNode = sccUtils
									.getCalcWrapper( calcId )
									.querySelector(
										`#ssc-elmt-${ itms.id } .scc-price-hint-box .price-hint-text`,
									);
								if ( calculationResultNode ) {
									calculationResultNode.textContent =
										'+ ' + getCurrencyText( calcId, f.price );
									calculationResultNode.classList.remove( 'scc-d-none' );

									sccShowPriceHint( calculationResultNode );
								}
							}
						} catch ( e ) {
							//console.log(e);
						}
						try {
							if ( itms.showPriceHint == '1' && itms.type == 'checkbox' && type == 'checkbox' && dom && dom?.getAttribute( 'data-itemid' ) == f.id ) {
								const calculationResultNode = sccUtils
									.getCalcWrapper( calcId )
									.querySelector(
										`#ssc-elmt-${ itms.id } [data-itemid="${ f.id }"]`,
									).previousElementSibling?.querySelector( '.price-hint-text' );
								if ( calculationResultNode ) {
									calculationResultNode.textContent =
										'+ ' + getCurrencyText( calcId, f.price );
									calculationResultNode.classList.remove( 'scc-d-none' );
									sccShowPriceHint( calculationResultNode );
								}
							}
						} catch ( e ) {
							//console.log(e);
						}
					}
					const filteredTextHtml =
						'html-' + itms.id == it && itms.type == 'texthtml';
					if ( filteredTextHtml ) {
						const calcWrapper = sccUtils.getCalcWrapper( calcId );
						const customCodeNode = calcWrapper.querySelector(
							`[id="ssc-elmt-${ itms.id }"] .custom-code-v2`,
						);
						const textHtmlType = typeof customCodeNode === 'object' ? 2 : 1;
						const customCodeText = customCodeNode ? customCodeNode : '';
						const processJson = itms.value2;
						const processNode = processJson.replaceAll( /{\\"texthtml\\":\\"|\\"}/g, '' );
						const processNode2 = processNode.replaceAll( /\\n/g, '' );
						const htmlCode = processNode2.replaceAll( /\\/g, '' );
						itemsArray[ calcId ].push( {
							description: `<span>${ htmlCode }</span>`,
							element_id: itms.id,
							id_element: itms.id,
							name: itms.titleElement,
							price: 0,
							type: 'texthtml',
							textHtmlType,
							customCodeText,
							qtn: 0,
							showTitlePdf: itms.displayDetailList,
						} );
					}
				} );
				elementDate[ calcId ].forEach( ( edd ) => {
					let dateType = 'date';
					let priceDate = 0;
					let priceDateTotal = 0;
					let numDays = 0;
					if ( edd[ 2 ] ) {
						dateType = 'date range';
						numDays = ( [ 'quantity_modifier_and_unit_price', 'quantity_mod' ].includes( edd[ 4 ] ) ) ? q : ( edd[ 3 ] * q );
						priceDate = edd[ 2 ];
						priceDateTotal = ( edd[ 2 ] * numDays ) / parseFloat( countElmts );
					}
					const itemOne = [];
					const f = elmts.find( ( el1 ) => el1.id == edd[ 0 ] );
					if ( f ) {
						itemOne.id_element = f.id;
						itemOne.qtn = numDays;
						itemOne.name = renderIconDetailedListPDF( f ) + f.titleElement;
						itemOne.type = dateType;
						itemOne.description = edd[ 1 ];
						itemOne.price = priceDate;
						itemOne.parentSectionId = secs.id;
						itemOne.subSectionIndex = subIndex;
						itemOne.calculationType = edd[ 4 ];
						itemOne.woo_commerce = {
							product_id: parseInt( f.element_woocomerce_product_id ) || 0,
							quantity: parseFloat( itemOne.qtn ),
						};
						if ( f.displayDetailList == 0 ) {
							itemOne.displayDetailList = true;
						} else {
							itemOne.displayDetailList = false;
						}
						if ( f.showTitlePdf == 1 ) {
							itemOne.showTitlePdf = true;
						} else {
							itemOne.showTitlePdf = false;
						}
						if ( itemsArray[ calcId ].findIndex( ( e ) => e.id_element == f.id ) < 0 ) {
							if ( f.uniqueId ) {
								itemOne.uniqueId = f.uniqueId;
							} else {
								console.error( 'uniqueId is not set' );
							}
							itemsArray[ calcId ].push( itemOne );
						}
					}
				} );

				elementDistance[ calcId ].forEach( ( edd ) => {
					const itemOne = [];
					const priceDistanceTotal = ( edd[ 3 ] * q * edd[ 2 ] ) / parseFloat( countElmts ); //qtn * price
					const f = elmts.find( ( el1 ) => el1.id == edd[ 0 ] );
					if ( f ) {
						itemOne.id_element = f.id;
						itemOne.qtn = edd[ 3 ] * q;
						itemOne.name = renderIconDetailedListPDF( f ) + f.titleElement;
						itemOne.type = 'distance';
						itemOne.description = edd[ 1 ];
						itemOne.price = edd[ 2 ];
						itemOne.parentSectionId = secs.id;
						itemOne.subSectionIndex = subIndex;
						itemOne.addressOfFirstInput = edd[ 5 ];
						itemOne.addressOfSecondInput = edd[ 6 ];
						itemOne.distanceUnit = edd[ 7 ];
						itemOne.woo_commerce = {
							product_id: parseInt( f.element_woocomerce_product_id ) || 0,
							quantity: parseFloat( itemOne.qtn ),
						};
						if ( f.displayDetailList == 0 ) {
							itemOne.displayDetailList = true;
						} else {
							itemOne.displayDetailList = false;
						}
						if ( f.showTitlePdf == 1 ) {
							itemOne.showTitlePdf = true;
						} else {
							itemOne.showTitlePdf = false;
						}

						if ( itemsArray[ calcId ].findIndex( ( e ) => e.id_element == f.id ) < 0 ) {
							if ( f.uniqueId ) {
								itemOne.uniqueId = f.uniqueId;
							} else {
								console.error( 'uniqueId is not set' );
							}
							itemsArray[ calcId ].push( itemOne );
						}
						if ( f.showPriceHint == '1' && f.id == element ) {
							const calculationResultNode = sccUtils
								.getCalcWrapper( calcId )
								.querySelector(
									`#ssc-elmt-${ f.id } .scc-price-hint-box .price-hint-text`,
								);
							if ( calculationResultNode ) {
								calculationResultNode.textContent =
									'+ ' + getCurrencyText( calcId, edd[ 3 ] * q * edd[ 2 ] );
								sccShowPriceHint( calculationResultNode );
							}
							// show price hint
						}
					}
				} );
				// send image checkbox items with quantity input to itemsArray
				itemsTotal2[ calcId ].forEach( ( it ) => {
					const iii = String( it ).split( '/' );
					const itemOne = [];
					q = iii.length > 1 ? iii[ 1 ] : 1;
					const f = items.find( ( el2 ) => el2.id == iii[ 0 ] );
					if ( f ) {
						num = f.price;
						itemOne.id_elementItem = f.id;
						itemOne.element_id = itms.id;
						itemOne.qtn = q;
						itemOne.type = 'qtn-image';
						itemOne.name = f.name.replace( /\\/g, '' );
						itemOne.description = f.description.replace( /\\/g, '' );
						itemOne.price = f.price;
						itemOne.parentSectionId = secs.id;
						itemOne.subSectionIndex = subIndex;
						itemOne.showDescriptionOnPdf = itms.value6 == '1' ? true : false;
						itemOne.image_url = f.value1;
						itemOne.show_image_on_pdf = itms.show_image_on_pdf === '1' ? true : false;
						if ( f.dropdownTitle ) {
							itemOne.dropdownTitle = f.dropdownTitle;
							itemOne.showTitlePdf = f.showTitlePdf;
						}
						itemOne.woo_commerce = {
							product_id: parseInt( f.woocomerce_product_id ) || 0,
							quantity: parseFloat( itemOne.qtn ),
						};

						if ( f.uniqueId ) {
							itemOne.uniqueId = f.uniqueId;
						} else {
							console.error( 'uniqueId is not set' );
						}
						itemsArray[ calcId ].push( itemOne );
						try {
							if ( itms.showPriceHint == '1' && itms.type == 'checkbox' && type == 9 ) {
								const calculationResultNode = sccUtils
									.getCalcWrapper( calcId )
									.querySelector(
										`#ssc-elmt-${ itms.id } [data-itemid="${ f.id }"]`,
									).previousElementSibling?.querySelector( '.price-hint-text' );
								if ( calculationResultNode ) {
									calculationResultNode.textContent =
										'+ ' + getCurrencyText( calcId, f.price * q );
									calculationResultNode.classList.remove( 'scc-d-none' );
									sccShowPriceHint( calculationResultNode );
								}
							}
						} catch ( e ) {
							//console.log(e);
						}
					}
				} );
			} );
			customMath[ calcId ].forEach( ( cm ) => {
				const itemOne = [];
				const f = elmts.find( ( mm ) => mm.id == cm );
				if ( f ) {
					const m = {
						action: f.value1,
						value: f.value2,
						showInDetail: f.displayFrontend,
						customMathId: f.id,
						modifyTotal: f.value4,
					};
					itemOne.id_element = f.id;
					itemOne.qtn = 0;
					itemOne.name = renderIconDetailedListPDF( f ) + f.titleElement;
					itemOne.description = f.value1;
					itemOne.parentSectionId = secs.id;
					itemOne.subSectionIndex = subIndex;
					( itemOne.type = 'customMath' ), ( itemOne.price = f.value2 );
					if ( f.displayDetailList == 1 ) {
						itemOne.displayDetailList = true;
					} else {
						itemOne.displayDetailList = false;
					}
					if ( f.uniqueId ) {
						itemOne.uniqueId = f.uniqueId;
					} else {
						console.error( 'uniqueId is not set for custom math' );
					}
					itemOne.woo_commerce = {
						product_id: parseInt( f.element_woocomerce_product_id ) || 0,
						quantity: 1,
					};
					itemsArray[ calcId ].push( itemOne );
					if ( m.modifyTotal == 'true' ) {
						if (
							globalMathArray[ calcId ].findIndex(
								( e ) => e.customMathId == m.customMathId,
							) < 0
						) {
							globalMathArray[ calcId ].push( m );
						}
						return;
					}
					if ( m.value == '' ) {
						m.value = 0;
						console.warn( 'You must set a value in custom math' );
					}
				}
			} );
			variableMathElement[ calcId ].forEach( ( vm ) => {
				if ( vm[ 1 ] <= 0 && ! vm[ 2 ] ) {
					return;
				}
				const itemOne = [];
				const f = elmts.find( ( ee ) => ee.id == vm[ 0 ] );
				let qtm = 1;
				Sliders[ calcId ].forEach( ( scs ) => {
					const g = elmts.find( ( itt ) => itt.subsection_id == scs[ 4 ] );
					if ( g ) {
						qtm = scs[ 1 ];
					}
				} );
				elementDate[ calcId ].forEach( ( scs ) => {
					const g = elmts.find( ( itt ) => itt.subsection_id == scs[ 5 ] );
					if ( g && [ 'quantity_modifier_and_unit_price', 'quantity_mod' ].includes( scs[ 4 ] ) ) {
						qtm = scs[ 3 ];
					}
				} );
				if ( vm[ 2 ] && vm[ 1 ] <= 0 ) {
					vm[ 1 ] = [ 0, '' ];
				}
				if ( f ) {
					const vmathRoot = sccUtils
						.getCalcWrapper( calcId )
						.querySelector( `#ssc-elmt-${ f.id }` );
					const priceHintNode = vmathRoot.querySelector( `.df-scc-element-tooltip` );
					const showResultOnFrontend = vmathRoot.classList.contains( 'scc-show-result-front' );
					const isPriceHintEnabled = vmathRoot.classList.contains( 'scc-has-pricehint' );
					const calculationResultNode = vmathRoot.querySelector( `.vmath-result` );
					const itemsWithElements = f.elementitems.filter(
						( e ) => [ 'scc-vmath-element', 'scc-section-total' ].findIndex( ( linkedItem ) => linkedItem == e.name ) >= 0,
					);
					let extraCalculationTasks = null;
					if ( itemsWithElements.length ) {
						extraCalculationTasks = sccUtils.handleVariableMath(
							calcId,
							itemsWithElements,
							itemsArray[ calcId ],
						);
					}
					itemOne.id_element = f.id;
					itemOne.type = 'math';
					itemOne.qtn = qtm;
					itemOne.name = f.titleElement;
					itemOne.queueType = 'variable-math';
					itemOne.extraCalculationTasks = extraCalculationTasks;
					itemOne.parentSectionId = secs.id;
					itemOne.subSectionIndex = subIndex;
					itemOne.useZeroValue = vm[ 2 ] ? 1 : 0;
					if ( f.showTitlePdf == 1 ) {
						itemOne.description = '';
					}
					itemOne.price = [ 0, '' ];
					itemOne.items_math = [];
					if ( f.uniqueId ) {
						itemOne.uniqueId = f.uniqueId;
					} else {
						console.error( 'uniqueId is not set' );
					}
					itemsArray[ calcId ].push( itemOne );

					if ( dom instanceof Element && f.id == dom.getAttribute( 'data-elementid' ) ) {
						if ( priceHintNode && isPriceHintEnabled && f.id == dom?.getAttribute( 'data-elementid' ) ) {
							priceHintNode.textContent =
								'+ ' + getCurrencyText( calcId, vm[ 1 ][ 0 ] );
							//console.log("step#5");
							priceHintNode.classList.remove( 'scc-d-none' );
							sccShowPriceHint( priceHintNode );
						}
						if ( calculationResultNode && dom.hasOwnProperty( 'getAttribute' ) && f.id == dom.getAttribute( 'data-elementid' ) && showResultOnFrontend && ! [ 'scc-vmath-element', 'scc-section-total' ].includes( dom.getAttribute( 'data-name' ) ) ) {
							calculationResultNode.textContent = getCurrencyText( calcId, vm[ 1 ][ 0 ] );
							calculationResultNode.classList.remove( 'scc-d-none' );
						}
					 }
				}
			} );
		} );
		// totalSec = totalNew
		secOne.id = secs.id;
		// secOne.total = tot;
		secOne.name = secs.name;
		// secOne["math"] = maths
		arraySectItems[ calcId ].push( secOne );
	} );
	const currency = config.currencyCode;
	const no_currency = config.removeCurrency;
	const symbolType = config.useCurrencyLetters;
	const cur_symbol = currencySymbol( currency );
	const affectTotal = false;
	// applying variable math element support
	itemsArray[ calcId ].forEach( ( e ) => {
		e.item_total_value =
			e?.calculationType === 'quantity_mod' ? 0 : e.qtn * sccUtils.roundNumber( e.price, calcId );
		if ( e.type == 'math' ) {
			e.item_total_value = e.price[ 0 ];
		}
		if ( e.type == 'customMath' ) {
			e.item_total_value = e.calculatedValue;
		}
		if ( e?.calculationType === 'sliding' ) {
			e.item_total_value = e.price;
		}
		if ( e?.calculationType === 'bulk-v2' ) {
			e.item_total_value = e.price;
		}
	} );

	const minOpt = [];
	minOpt[ calcId ] = config.minimumTotalChoose;

	const totalValueBeforeConditionCheck = sccUtils.getTotalValue( calcId ).total;
	sccUtils.evaluateConditions( calcId, itemsArray[ calcId ], totalValueBeforeConditionCheck );
	sccUtils.calculateVariableMathsWithLinkedItems( calcId, type, arraySectItems, element );
	const { sectionTotalValues, total: totalBeforeDiscount } = sccUtils.getTotalValue( calcId, true );

	/**
	 * *process cupon discount if applied
	 */
	const { coupon } = sccData[ calcId ];
	const originalTotal = totalBeforeDiscount;
	let couponData = {};
	let discountAmount = 0;
	if ( coupon && coupon?.isActive ) {
		const { useCurrencyLetters, currencyCode } = config;
		if (
			( sccData[ calcId ].total >= Number( coupon.minspend ) &&
			sccData[ calcId ].total <= Number( coupon.maxspend ) ) ||
		( Number( coupon.maxspend ) < 0 && Number( coupon.minspend ) < 0 )
		) {
			if ( Number( coupon.discountpercentage ) != -1 ) {
				discountAmount =
				Number( originalTotal * ( parseFloat( coupon.discountpercentage ) / 100 ) );

				coupon.discountAmount = discountAmount;
				//sccData[ calcId ].total = sccData[ calcId ].total - discountAmount;
				const discountAmountText = getCurrencyText( calcId, discountAmount );
				couponData = {
					type: 'coupon',
					attr: {
						title: `${ sccGetTranslationByKey( calcId, 'Coupon Discount' ) } ${ coupon.discountpercentage
						}%`,
						discount_price: `-${ discountAmountText }`,
					},
				};
				showDiscountNotice( calcId, {
					discountAmountText,
					percent: coupon.discountpercentage,
				} );
			}
			if ( Number( coupon.discountvalue ) != -1 ) {
				discountAmount = Number( coupon.discountvalue );
				coupon.discountAmount = discountAmount;
				const discountAmountText = getCurrencyText( calcId, discountAmount );
				couponData = {
					type: 'coupon',
					attr: {
						title: sccGetTranslationByKey( calcId, 'Applied discount' ),
						discount_price: `-${ discountAmountText }`,
					},
				};
				showDiscountNotice( calcId, {
					discountAmountText,
					discountvalue: coupon.discountvalue,
				} );
			}
		}
	}

	sccData[ calcId ].total = totalBeforeDiscount - discountAmount;
	sectionTotalValues.forEach( ( sectionData ) => {
		const sectionId = Object.keys( sectionData )[ 0 ];
		const sectionTotal = sectionData[ sectionId ];
		const sectionTotalNode = document.querySelector( `.scc-subtotal-amout-${ sectionId }` );
		if ( sectionTotalNode ) {
			sectionTotalNode.textContent = getCurrencyText( calcId, sectionTotal );
		}
		if ( sectionTotal <= 0 && sectionTotalNode ) {
			sectionTotalNode.parentElement?.classList.add( 'scc-hidden' );
		} else if ( sectionTotalNode ) {
			sectionTotalNode.parentElement?.classList.remove( 'scc-hidden' );
		}
	} );
	/**
	 * *handle min total from calculator settings
	 */
	const minNumber = [];
	minNumber[ calcId ] = parseFloat( config.minimumTotal );
	const enableMinTotal = minNumber[ calcId ] > 0;
	const hideMinimumTotalWarning = sccData[ calcId ].total >= minNumber[ calcId ];
	if ( enableMinTotal ) {
		sccUtils.handleMinimumTotal( container, hideMinimumTotalWarning, minOpt[ calcId ], calcId );
	}
	const isPriceRangeActive = 0 !== Number( priceRangeTotalSettings.rangePercent );

	/**
	 * *Added tax from calculator settings to total script
	 */
	let subTotalBeforeTax = sccData[ calcId ].total;
	let tax_vat = parseFloat( config.taxVat );
	if ( tax_vat && tax_vat > 0 ) {
		tax_vat = tax_vat / 100;
		tax_vat = sccUtils.roundNumber( sccData[ calcId ].total * tax_vat, calcId );
		if ( container.querySelector( '.tax-placeholder' ) ) {
			container.querySelector( '.tax-placeholder' ).textContent = getCurrencyText(
				calcId,
				tax_vat,
			);
		}
		subTotalBeforeTax = sccData[ calcId ].total;
		sccData[ calcId ].total = sccData[ calcId ].total + tax_vat;
	}
	//?set total to preview and supports comma and browser separator
	if ( total && ! isPriceRangeActive ) {
		total.forEach( ( _total ) => {
			if ( _total ) {
				_total.textContent = getCurrencyText( calcId, parseFloat( sccData[ calcId ].total ) );
			}
		} );
	}
	if ( total && isPriceRangeActive ) {
		const rangeAppliedTotal = sccUtils.applyRangeTotal( parseFloat( sccData[ calcId ].total ), priceRangeTotalSettings );
		const originalTotalText = getCurrencyText( calcId, parseFloat( sccData[ calcId ].total ) );
		const rangeAppliedTotalText = getCurrencyText( calcId, rangeAppliedTotal );
		total.forEach( ( _total ) => {
			/* quit if total element is not found, such can be possible
			* if the total bar is hidden, or, uses a sticky price bar not available on the backend preview */
			if ( ! _total ) {
				return;
			}
			_total.textContent = sccData[ calcId ].total == 0 ? originalTotalText : originalTotalText + ' - ' + rangeAppliedTotalText;
		} );

		//add total to [scc_calculator-total price-range='lower'] shortcode
		document.querySelectorAll( `.scc-multiple-total-wrapper.calcid-${ calcId }[data-price-range="lower"] .scc-price-range-total` ).forEach( ( element ) => {
			element.textContent = originalTotalText;
		} );

		//add total to [scc_calculator-total price-range='higher'] shortcode
		document.querySelectorAll( `.scc-multiple-total-wrapper.calcid-${ calcId }[data-price-range="higher"] .scc-price-range-total` ).forEach( ( element ) => {
			element.textContent = rangeAppliedTotalText;
		} );
		sccData[ calcId ].priceRanges = { min: sccData[ calcId ].total, max: rangeAppliedTotal, totalTextWithRange: originalTotalText + ' - ' + rangeAppliedTotalText };
	}
	if ( sccData[ calcId ].config.scc_rate_label_convertion != null ) {
		var total_converted = sccUtils.updateConvertedCurrencyText( calcId, sccData[ calcId ].total );
	}
	// handle multiple total

	const totalNodes = document.querySelectorAll( '.scc-multiple-total-wrapper.calcid-' + calcId + ' .scc-total' );
	totalNodes.forEach( ( node ) => {
		node.textContent = parseFloat( sccData[ calcId ].total ).toFixed( 2 );
		// Note: The 'doMath' event would need a custom event dispatch in vanilla JS.
		node.dispatchEvent( new Event( 'doMath', { bubbles: true, cancelable: true } ) );
	} );

	// Triggering 'valueChange' on ".scc-multiple-total-wrapper.scc-combination"
	const combinationNodes = document.querySelectorAll( '.scc-multiple-total-wrapper.scc-combination' );
	combinationNodes.forEach( ( node ) => {
		node.dispatchEvent( new Event( 'valueChange', { bubbles: true, cancelable: true } ) );
	} );

	const taxParcent = config.taxVat ? config.taxVat : 0;

	const rawItems = sccFilterRawItems( itemsArray[ calcId ] );
	const { paypalConfig, isCombinedCheckoutEnabled } = config;
	const isTaxInclusionEnabled = paypalConfig.objectTaxInclusionInPayPal;
	//Rounding calculator values
	rawItems.forEach( ( item ) => {
		if ( item.price ) {
			if ( item.type == 'math' ) {
				item.price[ 0 ] = sccUtils.roundNumber( parseFloat( item.price[ 0 ] ), calcId );
			} else {
				item.price = sccUtils.roundNumber( parseFloat( item.price ), calcId );
			}
		}
		if ( item.item_total_value ) {
			item.item_total_value = sccUtils.roundNumber( parseFloat( item.item_total_value ), calcId );
		}
	},
	);
	//End of rounding calculator values

	const itemsWithTotalValue = rawItems;
	sccData[ calcId ].webhook = buildWebhookData( [
		itemsWithTotalValue,
		{
			total: sccData[ calcId ].total,
		},
	] );
	const sortedItems = [];
	const checkboxToItemsMapping = [];
	const itemsOrdered = [];
	const sortOperation = sections
		.map( ( q ) => q.subsection.map( ( e ) => ( { [ q.id ]: e.element } ) ) )
		.map( ( eq ) => {
			return eq.flat();
		} )
		.flat();
	[ ...new Set( sortOperation.map( ( e ) => Object.keys( e )[ 0 ] ) ) ].forEach( ( eb ) => {
		itemsOrdered.push( itemsArray[ calcId ].find( ( e ) => e?.sectionId == eb ) );
		sortOperation.filter( ( e ) => Object.keys( e )[ 0 ] == eb )
			.map( ( e ) => e[ eb ] )
			.flat()
			.forEach( ( item ) => {
				if ( item.type == 'checkbox' ) {
					item.elementitems.forEach( ( checkboxItem ) => {
						checkboxItem.type = 'checkbox';
						itemsOrdered.push( checkboxItem );
					} );
				}
				if ( item.type !== 'checkbox' ) {
					itemsOrdered.push( item );
				}
			} );
	} );
	itemsOrdered.forEach( ( e ) => {
		const res = itemsArray[ calcId ].find( ( eq ) => {
			let resp = false;
			if ( e.type == 'Dropdown Menu' || e.type == 'checkbox' ) {
				if ( e.type == 'checkbox' ) {
					resp = ! [ e.id == eq.id_elementItem ].some(
						( e ) => e == false,
					);
					if ( resp ) {
						return true;
					}
				} else {
					resp = e.elementitems.find( ( ez ) => ez.id == eq.id_elementItem );
					if ( resp ) {
						return true;
					}
				}
			} else if ( e?.sectionId && eq?.sectionId ) {
				resp = e.sectionId == eq.sectionId;
				if ( resp ) {
					return true;
				}
			} else if ( e.type === 'texthtml' && Number( e.displayDetailList ) === 0 ) {
				resp = eq.id_element == e.id;
				if ( resp ) {
					return true;
				}
			} else if ( e.id ) {
				resp = eq.id_element == e.id;
				if ( resp ) {
					return true;
				}
			}
			return false;
		} );
		// push the matched item to the sortedItems array, and exclude the hidden items
		if ( res && res?.hidden !== true ) {
			sortedItems.push( res );
		}
	} );
	for ( let index = 0; index < itemsOrdered.length; index++ ) {
		const element = itemsOrdered[ index ];
		if ( element.type == 'checkbox' ) {
			checkboxToItemsMapping.push( {
				targetIndex: index,
				items: element.elementitems,
			} );
		}
	}
	if ( checkboxToItemsMapping.length ) {
	}

	sortedItemsArray[ calcId ] = sortedItems;
	getAverageUnitPrice( calcId, parseFloat( sccData[ calcId ].total - discountAmount ), sortedItems );

	processItemsForPayPal( {
		items: sortedItems,
		tax: tax_vat,
		comments: null,
		calculator_id: calcId,
		isTaxInclusionEnabled,
		combineItems: isCombinedCheckoutEnabled,
		coupon,
	}, sccData[ calcId ].total );
	sccData[ calcId ].stripeCart = buildStripeData(
		sortedItems,
		isCombinedCheckoutEnabled,
		coupon,
		calcId,
		tax_vat,
		Number( config.stripeIncludeTax ),
	);
	sccData[ calcId ].pdf = buildDetailViewData(
		sortedItems,
		config.formname,
		sccData[ calcId ].total,
		calcId,
		tax_vat,
		taxParcent,
		discountAmount ? ( discountAmount + subTotalBeforeTax ) : subTotalBeforeTax,
		couponData,
		total_converted,
	);
	popList(
		config,
		calcId,
		sortedItems,
		sccData[ calcId ].total,
		discountAmount ? ( discountAmount + subTotalBeforeTax ) : subTotalBeforeTax,
		taxParcent,
		tax_vat,
	);
	// checkConditions(calcId)
}
window.triggerSubmit = triggerSubmit;
function sccCreateLocalDateFromString( dateString ) {
	if ( dateString.includes( '-' ) ) {
		const [ year, month, day ] = dateString.split( '-' );
		return new Date( year, month - 1, day );
	} else if ( dateString.includes( '/' ) ) {
		const [ month, day, year ] = dateString.split( '/' );
		return new Date( year, month - 1, day );
	}
	return new Date( dateString );
}
// convert a date string to a format selected on the PDF settings
function sccFormatStringDate( input, format = 'yyyy-mm-dd' ) {
	let dateObj;
	// If input is a string, attempt to convert it into a Date object
	if ( typeof input === 'string' ) {
		dateObj = sccCreateLocalDateFromString( input );
	} else if ( input instanceof Date ) {
		dateObj = input;
	}

	// Check if dateObj is a valid Date object
	if ( ! ( dateObj instanceof Date ) || isNaN( dateObj.getTime() ) ) {
		//console.error("Invalid Date provided to sccFormatDate.");
		return;
	}
	const day = String( dateObj.getDate() ).padStart( 2, '0' );
	const month = String( dateObj.getMonth() + 1 ).padStart( 2, '0' ); // Months are 0-based
	const year = dateObj.getFullYear();

	switch ( format ) {
		case 'yyyy-mm-dd':
		case 'Y-m-d':
			return `${ year }-${ month }-${ day }`;
		case 'mm-dd-yyyy':
		case 'm-d-Y':
			return `${ month }-${ day }-${ year }`;
		case 'dd-mm-yyyy':
		case 'd-m-Y':
			return `${ day }-${ month }-${ year }`;
		case 'dd/mm/yyyy':
		case 'd/m/Y':
			return `${ day }/${ month }/${ year }`;
		case 'mm/dd/yyyy':
		case 'm/d/Y':
			return `${ month }/${ day }-${ year }`;
		default:
			return `${ year }-${ month }-${ day }`; // Default format to the standard
	}
}

// convert a date string to a standard format yyyy-mm-dd
function sccStandardDateFormat( inputDate, format ) {
	let day, month, year;
	//removing the hours from the date for calculations or conditions
	inputDate = inputDate.split( ' ' )[ 0 ];
	inputDate = inputDate.trimEnd();

	switch ( format ) {
		case 'dd-mm-yyyy':
		case 'd-m-Y':
			[ day, month, year ] = inputDate.split( '-' );
			break;
		case 'mm-dd-yyyy':
		case 'm-d-Y':
			[ month, day, year ] = inputDate.split( '-' );
			break;
		case 'yyyy-mm-dd':
		case 'Y-m-d':
			[ year, month, day ] = inputDate.split( '-' );
			break;
		case 'dd/mm/yyyy':
		case 'd/m/Y':
			[ day, month, year ] = inputDate.split( '/' );
			break;
		case 'mm/dd/yyyy':
		case 'm/d/Y':
			[ month, day, year ] = inputDate.split( '/' );
			break;
		case 'yyyy/mm/dd':
		case 'Y/m/d':
			[ year, month, day ] = inputDate.split( '/' );
			break;
		default:
			console.error( 'Invalid format provided.' );
			return;
	}
	// Validate the parsed date parts
	const dateObj = new Date( year, month - 1, day );
	if ( dateObj.getFullYear() !== parseInt( year ) || dateObj.getMonth() + 1 !== parseInt( month ) || dateObj.getDate() !== parseInt( day ) ) {
		return inputDate;
	}
	// Return in the standard format
	return `${ year }-${ String( month ).padStart( 2, '0' ) }-${ String( day ).padStart( 2, '0' ) }`;
}

function popList( config, calcId, items, total, subtotal, tax_per, tax ) {
	const $containers = document.querySelectorAll( `#scc-detail-${ calcId }` );
	if ( ! $containers.length ) {
		return;
	}
	$containers.forEach( ( $container ) => {
		const { config: sccConfig } = sccData[ calcId ];
		if ( sccConfig.pdf.disableUnitColumn || sccConfig.pdf.disableQtyColumn ) {
			const listHead = $container.querySelectorAll( '.title-float div' );
			listHead.forEach( ( cell, index ) => {
				if ( index == 0 ) {
					return;
				}
				index == 1 && ( sccConfig.pdf.disableUnitColumn ) && cell.classList.add( 'scc-d-none' );
				index == 2 && ( sccConfig.pdf.disableQtyColumn ) && cell.classList.add( 'scc-d-none' );
			} );
		}
		const $rowContainer = $container.querySelector( '.rows-float' );
		$rowContainer.innerHTML = '';
		const $frag = document.createDocumentFragment();
		const processPopList = ( element ) => {
			if ( element.type == 'date' || element.type == 'texthtml' ) {
				return;
			}
			if ( element.type === 'customMath' && element.displayDetailList === false ) {
				return;
			}
			const $divRow = document.createElement( 'div' );
			const $col1 = document.createElement( 'div' );
			const $col2 = document.createElement( 'div' );
			const $col3 = document.createElement( 'div' );
			if ( typeof element.id_element !== 'undefined' ) {
				$divRow.setAttribute( 'data-element-id', element.id_element );
			}
			if ( typeof element.sectionId !== 'undefined' ) {
				$divRow.setAttribute( 'data-section-id', element.sectionId );
			}
			if ( typeof element.id_elementItem !== 'undefined' ) {
				$divRow.setAttribute( 'data-elementItem', element.id_elementItem );
			}
			if (
				( element.id_element &&
					! $container.querySelector( `[data-element-id='${ element.id_element }']` ) ) ||
				( element.sectionId &&
					! $container.querySelector( `[data-section-id='${ element.sectionId }']` ) ) ||
				( element.id_elementItem &&
					! $container.querySelector(
						`[data-elementItem='${ element.id_elementItem }']`,
					) )
			) {
				$col1.innerHTML = element.name;
				if ( element.sectionId ) {
					$col1.innerHTML = element.section;
					$col1.style.fontWeight = 'bold';
				}
				if ( ! sccConfig.pdf.disableUnitColumn ) {
					$col3.textContent = element?.item_total_value
						? getCurrencyText( calcId, element.item_total_value )
						: '';
				}
				$divRow.appendChild( $col1 );
				$divRow.appendChild( $col2 );
				$divRow.appendChild( $col3 );
				$frag.appendChild( $divRow );
			}
		};
		items.forEach( processPopList );
		$rowContainer.appendChild( $frag );
		items.forEach( ( element ) => {
			if (
				$container.querySelector( `[data-elementItem='${ element.id_elementItem }']` )
			) {
				if ( element.type != 'math' && ! sccConfig.pdf.disableQtyColumn ) {
					$container.querySelector(
						`[data-elementItem='${ element.id_elementItem }'] :nth-child(2)`,
					).textContent = element.qtn
						? Number( parseInt( element.qtn ) ).toLocaleString( 'en-US', {
							maximumFractionDigits: 2,
						} )
						: '';
				}
			}
			if ( $container.querySelector( `[data-element-id='${ element.id_element }']` ) ) {
				if ( element.type != 'math' && ! sccConfig.pdf.disableQtyColumn ) {
					$container.querySelector(
						`[data-element-id='${ element.id_element }'] :nth-child(2)`,
					).textContent = element.qtn
						? Number( parseInt( element.qtn ) ).toLocaleString( 'en-US', {
							maximumFractionDigits: 2,
						} )
						: '';
				}
			}
		} );
		const { priceRangeTotalSettings, currency_conversion_mode } = config;
		const isPriceRangeActive = 0 !== Number( priceRangeTotalSettings.rangePercent );
		const $totalContainer = $container.querySelector( '.totals-float' );
		const $Lsubtotal = $totalContainer.querySelector( ':nth-child(1)' );
		const $subtotal = $totalContainer.querySelector( ':nth-child(2)' );
		const $Ltax = $totalContainer.querySelector( ':nth-child(3)' );
		const $tax = $totalContainer.querySelector( ':nth-child(4)' );
		const $tax_per = $totalContainer.querySelector( ':nth-child(3) > span' );
		const $total = $totalContainer.querySelector( ':nth-child(6)' );
		if ( isNaN( tax ) ) {
			$Lsubtotal.style.display = 'none';
			$subtotal.style.display = 'none';
			$Ltax.style.display = 'none';
			$tax.style.display = 'none';
		}
		$subtotal.textContent = sym( config, parseFloat( subtotal ).toFixed( 2 ) );
		$tax.textContent = sym( config, parseFloat( tax ).toFixed( 2 ) );
		$tax_per.textContent = parseFloat( tax_per ).toFixed( 2 );
		if ( isPriceRangeActive ) {
			const { totalTextWithRange, min, max } = sccData[ calcId ]?.priceRanges;
			$total.textContent = totalTextWithRange;
		} else {
			$total.textContent = sym( config, parseFloat( total ).toFixed( 2 ) );
		}
		function sym( sett, number ) {
			return sett.removeCurrency == false
				? currencySymbol( sett.currencyCode ) +
				Number( number ).toLocaleString( 'en-US', { maximumFractionDigits: 2 } )
				: Number( number ).toLocaleString( 'en-US', { maximumFractionDigits: 2 } );
		}
	} );
}

function renderIconDetailedListPDF( f, iconRaw = false ) {
	let sccIconRaw = '';
	//if iconRaw is true, f will return the icon in raw format
	if ( iconRaw == true ) {
		sccIconRaw = f;
	} else if ( f.titleIconConfigArray && f.titleIconConfigArray.length > 0 ) {
		const arrayDecode = JSON.parse( f.titleIconConfigArray );
		const iconType = arrayDecode.type;
		if ( iconType == 'icon-font' ) {
			sccIconRaw = arrayDecode.icon_html.replace( /\\/g, '' ) + ' ';
		} else if ( iconType == 'img' ) {
			sccIconRaw = '<img class="scc-img-icon-pdf" src="' + arrayDecode.image_icon + '" alt="icon" /> ';
		}
	}

	return sccIconRaw;
}
function sccShowPriceHint( priceHintElement ) {
	priceHintElement.classList.add( 'scc-hint-show' );
	priceHintElement.classList.remove( 'scc-hint-hide' );
	setTimeout( () => {
		priceHintElement.classList.remove( 'scc-hint-show' );
		priceHintElement.classList.add( 'scc-hint-hide' );
	}, 3000 );
}

function sccEvalMathExpression( expression ) {
	if ( /[^-()\d/*+. ]/.test( expression ) ) {
		throw new Error( 'Invalid characters in expression.' );
	}
	return new Function( 'return ' + expression )();
}

//This function calculates the average unit price
function getAverageUnitPrice( calcId, total, items ) {
	const unitPriceLabel = document.querySelector( '.scc-unit-price-' + calcId );
	const unitPriceBox = unitPriceLabel?.closest( '.scc-unit-price-box' );
	const counterElement = unitPriceBox?.getAttribute( 'data-unit-price-counter-id' );
	let totalItems = 0; //Count the number of items billed on the form
	let rawTotalItems = 0;
	function getTotalItems( counterElement = false ) {
		items.map( ( e ) => {
			const itemQuantity = e.qtn ? parseFloat( e.qtn ) : 0;
			if ( ! counterElement || counterElement === 'calctotal' ) {
				if ( ! isNaN( itemQuantity ) && isFinite( e.qtn ) ) {
					if ( e.displayDetailList !== false ) {
						totalItems += itemQuantity;
					}
					rawTotalItems += itemQuantity;
					return e.name + " '" + e.type + "' " + e.qtn + ' ' + e.displayDetailList;
				}
			}
			if ( e.id_element === counterElement ) {
				if ( ! isNaN( itemQuantity ) && isFinite( e.qtn ) ) {
					totalItems = itemQuantity;
					rawTotalItems = itemQuantity;
				}
			} else {
				const elementChecker = document.querySelector( '#ssc-elmt-' + counterElement );
				if ( elementChecker && totalItems === 0 ) {
					totalItems = 1;
					rawTotalItems = 1;
				}
			}
			return e.name + " '" + e.type + "' " + e.qtn + ' ' + e.displayDetailList;
		} );
	}
	getTotalItems( counterElement );
	if ( totalItems === 0 && rawTotalItems === 0 ) {
		getTotalItems();
	}
	const calcUnitPrice = ( total !== 0 && totalItems !== 0 ) ? total / totalItems : 0;
	const calcRawUnitPrice = ( total !== 0 && rawTotalItems !== 0 ) ? total / rawTotalItems : 0;
	if ( unitPriceLabel ) {
		if ( total === 0 ) {
			unitPriceLabel.textContent = getCurrencyText( calcId, parseFloat( 0 ) );
		} else if ( totalItems === 0 ) {
			unitPriceLabel.textContent = getCurrencyText( calcId, parseFloat( calcRawUnitPrice ) );
		} else {
			unitPriceLabel.textContent = getCurrencyText( calcId, parseFloat( calcUnitPrice ) );
		}
	}
	return 'Total items: ' + totalItems;
}

//?total formula
function changedVariableMath( el, scheduledCalc = false ) {
	const vmathRoot = el
		.closest( '[id^=ssc-elmt-]' );
	const useZeroAsFallback = vmathRoot.classList.contains( 'scc-use-zero-as-fallback' );
	let Stotal = vmathRoot
		.querySelector( '.totalmath' )
		.getAttribute( 'data-total' );
	const a = [];
	let des = '';
	el.closest( '[id^=ssc-elmt-]' )
		.querySelectorAll( '.slider-styled, input.scc-vmath-input, input.scc-vmath-checkbox, input.scc-vmath-hidden' )
		.forEach( function( e, i ) {
			let price = e.getAttribute( 'data-price' );
			let qtn = e.value;
			let name = e.getAttribute( 'data-name' );
			const sperator = i == 0 ? '' : ',';
			if ( name == 'scc-vmath-element' && ! scheduledCalc ) {
				price = 1;
				qtn = 1;
				const attachedElementId = e.getAttribute( 'data-attached-element' );
				const attachedItemId = e.getAttribute( 'data-attached-element-item' );
				name = `scc-vmath-element_${ attachedElementId }_${ attachedItemId }`;
			}
			if ( name == 'scc-vmath-element' && scheduledCalc ) {
				const currentVmathId = e.getAttribute( 'data-itemid' );
				const calculation = scheduledCalc.find(
					( q ) => q.vmathVarId == currentVmathId,
				);
				price = calculation ? calculation.price : 1;
				qtn = calculation ? calculation.qtn : 1;
				name = calculation ? calculation.name : 'absent';
			}
			if ( name === 'scc-section-total' ) {
				qtn = 1;
				price = 1;
			}
			des = `${ des } ${ sperator } ${ name } x ${ qtn }`;
			const placeholder = `Input${ i + 1 }`;
			const regex = new RegExp( `\\b${ placeholder }\\b`, 'g' );
			if ( Stotal.includes( placeholder ) ) {
				Stotal = Stotal.replace( regex, qtn * price );
			}
			if ( qtn == 0 && ! useZeroAsFallback ) {
				a.push( true );
			}
		} );
	if ( a.includes( true ) ) {
		return 0;
	}
	try {
		return [ sccEvalMathExpression( Stotal ), des ];
	} catch ( e ) {
		return 0;
	}
}
function buildWebhookData( rawItemsData ) {
	const items = rawItemsData[ 0 ].map( ( e ) => {
		const itemId = e?.uniqueId || e.name.toLowerCase().replace( /\s+/g, '_' ) + '_' + e?.id;
		const itemProps = {
			name: e.name,
			id: itemId,
			unit: e.qtn,
			value: e.price,
			unit_price: e.price,
			element_price: e.price,
			item_total_value: e.item_total_value,
		};
		if ( e.type == 'comment' ) {
			itemProps.user_content = e.description;
		}
		if ( e.type == 'slider' ) {
			itemProps.savings = e.savings;
		}
		if ( e.type == 'math' ) {
			itemProps.unit_price = e.price[ 0 ];
			itemProps.element_price = e.price[ 0 ];
			itemProps.value = e.price[ 0 ];
			itemProps.item_total_value = e.price[ 0 ];
			itemProps.calculation_details = e.price[ 1 ];
		}
		if ( e.type == 'date' ) {
			itemProps.date = e.description;
		}
		if ( e.type == 'date range' ) {
			const matches = e.description.match( /From (\d{2}-\d{2}-\d{4}) To (\d{2}-\d{2}-\d{4})/ );
			if ( matches ) {
				itemProps.start_date = matches[ 1 ];
				itemProps.end_date = matches[ 2 ];
			}
		}
		if ( e.type == 'distance' ) {
			itemProps.addressOfFirstInput = e.addressOfFirstInput;
			itemProps.addressOfSecondInput = e.addressOfSecondInput;
			itemProps.distanceUnit = e.distanceUnit;
		}
		return {
			[ e.dropdownTitle ? e.dropdownTitle : e.name ]: itemProps,
		};
	} );
	items.push( rawItemsData[ 1 ] );
	return items;
}
function buildStripeData( cartItems, combineItems, coupon, calcId, tax, includeTaxAmount = 1 ) {
	function getValuePerUnit( e ) {
		// make unit value of quantity modifier sliders to 0
		if ( e?.calculationType == 'quantity_mod' ) {
			return 0;
		}
		// handling variable math
		if ( e?.type == 'math' ) {
			return isNaN( e.price[ 0 ] ) ? 0 : Number( e.price[ 0 ] );
		}
		if ( e?.type == 'customMath' ) {
			return isNaN( e.item_total_value ) ? 0 : Number( e.item_total_value );
		}
		const valuePerUnit = isNaN( e.calculatedValue ) ? ( isNaN( e.price ) ? 0 : Number( e.price ) ) : Number( e.calculatedValue );
		return valuePerUnit;
	}
	if ( combineItems && cartItems.length > 1 ) {
		let combineditems = cartItems.map( ( e ) => {
			const units = e.qtn > 1 ? ' x ' + e.qtn : '';
			return sccCleanHTML( e.name ) + units;
		} );
		let combinedTotal = cartItems.reduce( ( sum, currentItem, index ) => {
			if ( index == 1 ) {
				const sumTotal =
					sum.qtn === null || isNaN( sum.qtn )
						? getValuePerUnit( sum )
						: getValuePerUnit( sum ) * sum.qtn;
				const currentTotal =
					currentItem.qtn === null || isNaN( currentItem.qtn ) || currentItem.type == 'customMath'
						? getValuePerUnit( currentItem )
						: getValuePerUnit( currentItem ) * currentItem.qtn;
				return Number( currentTotal ) + Number( sumTotal );
			}
			const currentTotal =
				currentItem.qtn === null || isNaN( currentItem.qtn ) || currentItem.type == 'customMath'
					? getValuePerUnit( currentItem )
					: getValuePerUnit( currentItem ) * currentItem.qtn;
			return Number( sum ) + Number( currentTotal );
		} );
		if ( includeTaxAmount == 1 && tax > 0 ) {
			combineditems.push( sccGetTranslationByKey( calcId, 'TAX' ) );
			combinedTotal = combinedTotal + ( isNaN( tax ) ? 0 : Number( tax ) );
		}
		combinedTotal = combinedTotal - ( coupon?.discountAmount || 0 );
		combineditems = combineditems.filter( ( item ) => item !== undefined && item !== 'undefined' );
		return [
			{
				name: combineditems.join( ', ' ),
				valuePerUnit: combinedTotal,
				units: 1,
			},
		];
	}
	// filtering out items with 0 value, and 0 units
	const filteredCartItems = cartItems
		.filter( ( e ) => getValuePerUnit( e ) > 0 )
		.filter( ( e ) => e.qtn > 0 || e.type == 'customMath' )
		.filter( Boolean );
	const itemsProcessedForCart = filteredCartItems.map( ( e ) => {
		return {
			name: sccCleanHTML( e.name ),
			valuePerUnit: getValuePerUnit( e ),
			units: e.qtn ? e.qtn : 1,
			type: e.type,
		};
	} );
	if ( includeTaxAmount == 1 && tax > 0 ) {
		itemsProcessedForCart.push( {
			name: sccGetTranslationByKey( calcId, 'TAX' ),
			valuePerUnit: tax,
			units: 1,
		} );
	}
	return itemsProcessedForCart;
}
function buildDetailViewData(
	rawData,
	formname,
	total,
	calcId,
	taxAmount = 0,
	taxParcent = 0,
	subTotalBeforeTax = 0,
	coupon,
	total_converted = null,
) {
	const { pdf: pdfConfig } = sccData[ calcId ].config;
	// remove empty section title from PDF
	for ( let index = 0; index < rawData.length; index++ ) {
		// get the row by index
		const row = rawData[ index ];
		const allowedZeroQtnTypes = [ 'customMath', 'comment', 'texthtml', 'date' ];
		// if quantity is 0, we remove it, unless the type is mentioned in allowedZeroQtnTypes
		if (
			row?.qtn <= 0 &&
			! ( allowedZeroQtnTypes.findIndex( ( e ) => e == row?.type ) >= 0 )
		) {
			delete rawData[ index ];
		}
		// exclude the items with unit value 0.001 from detailed view
		if ( row?.price == 0.001 ) {
			delete rawData[ index ];
		}
		// if last row and a section, we remove it
		if ( index + 1 == rawData.length && row?.section ) {
			delete rawData[ index ];
		}
		// if the section is followed by another section, remove the section
		const nextRow = rawData[ index + 1 ];
		if ( row.hasOwnProperty( 'section' ) && nextRow?.hasOwnProperty( 'section' ) ) {
			delete rawData[ index ];
		}
	}
	// remove empty undefined values from array
	rawData = rawData.filter( ( e ) => typeof e !== 'undefined' );
	const hasTax = taxAmount > 0;
	let subTotalBeforeTaxText = '';
	let taxAmountText = '';
	if ( hasTax ) {
		subTotalBeforeTaxText = getCurrencyText( calcId, subTotalBeforeTax );
		taxAmountText = getCurrencyText( calcId, taxAmount );
	}
	let totalText = getCurrencyText( calcId, total );
	let rows = rawData.map( ( e ) => {
		let total_price = getCurrencyText(
			calcId,
			parseInt( e.qtn ) * parseFloat( e.price ),
		);
		if ( e.type == 'distance' ) {
			total_price = getCurrencyText(
				calcId,
				parseFloat( e.qtn ) * parseFloat( e.price ),
			);
		}
		const unit_price = getCurrencyText( calcId, e.price );
		if ( e?.displayDetailList !== undefined && ! e.displayDetailList ) {
			return;
		}
		if (
			e?.displayDetailList !== undefined &&
			e.displayDetailList &&
			e?.calculationType == undefined
		) {
			if ( e?.type == 'customMath' ) {
				return {
					type: 'custom_math',
					attr: {
						name: e.name,
						total_price: getCurrencyText( calcId, Number( e?.item_total_value || 0 ) ),
						unit: parseInt( e.qtn ),
						show_detailed_list: true,
						unit_price,
						uniqueId: e?.uniqueId,
						element_type: 'custom_math',
						math_type: e.description,
						value: parseFloat( e.price ),
						woo_commerce: e.woo_commerce,
						numeric_value: Number( e?.item_total_value || 0 ),
					},
				};
			}
			if ( e?.type == 'date' ) {
				return {
					type: 'date',
					attr: {
						name: e.name,
						uniqueId: e?.uniqueId,
						description: e.description,
						displayPdf: e.showTitlePdf,
						woo_commerce: e.woo_commerce,
					},
				};
			}
		}
		if ( e?.type == 'comment' ) {
			return {
				type: e.type,
				attr: {
					title: e.name,
					uniqueId: e?.uniqueId,
					comment: e.description,
				},
			};
		}
		if ( e?.type == 'texthtml' && e.showTitlePdf == '0' ) {
			return {
				type: e.type,
				attr: {
					title: e.name,
					uniqueId: e?.uniqueId,
					texthtml:
						e.customCodeText.length > 0 ? e.customCodeText : e.description,
				},
			};
		}
		if ( e?.type == 'texthtml' && e.showTitlePdf == '3' ) {
			return;
		}
		if ( e?.type == 'math' ) {
			return {
				type: 'math',
				attr: {
					name: e.name,
					description: e.description,
					total_price,
					uniqueId: e?.uniqueId,
					unit: parseInt( e.qtn ),
					unit_price,
					value: parseFloat( e.price ),
				},
			};
		}
		if ( e?.section !== undefined ) {
			return {
				section_title: e.section,
				type: 'section_title',
				showSectionTotalOnPdf:
					e?.showSectionTotalOnPdf == 'true' ? true : false,
			};
		}
		if ( e?.calculationType == 'sliding' ) {
			total_price = getCurrencyText( calcId, parseFloat( e.price ) );
			return {
				type: 'slider',
				attr: {
					name: e.name,
					total_price,
					unit: parseInt( e.qtn ),
					unit_price,
					savings: e?.savings || 0,
					uniqueId: e?.uniqueId,
					description: e.description,
					isSlidingScale: true,
					value: parseFloat( e.price ),
					woo_commerce: e.woo_commerce,
					numeric_value: parseFloat( e.price ),
				},
			};
		}
		if ( e?.calculationType == 'bulk' ) {
			return {
				type: 'slider',
				attr: {
					name: e.name,
					total_price,
					unit: parseInt( e.qtn ),
					unit_price,
					savings: e?.savings || 0,
					uniqueId: e?.uniqueId,
					description: e.description,
					value: parseFloat( e.price ),
					woo_commerce: e.woo_commerce,
					numeric_value: parseInt( e.qtn ) * parseFloat( e.price ),
				},
			};
		}
		if ( e?.calculationType == 'bulk-v2' ) {
			total_price = getCurrencyText( calcId, parseFloat( e.price ) );
			return {
				type: 'slider',
				attr: {
					name: e.name,
					total_price,
					unit: parseInt( e.qtn ),
					unit_price,
					savings: e?.savings || 0,
					uniqueId: e?.uniqueId,
					description: e.description,
					value: parseFloat( e.price ),
					woo_commerce: e.woo_commerce,
					numeric_value: parseFloat( e.price ),
				},
			};
		}
		if ( e?.calculationType == 'quantity_mod' ) {
			return {
				type: 'element',
				attr: {
					name: e.name,
					total_price: 0,
					uniqueId: e?.uniqueId,
					woo_commerce: e.woo_commerce,
					unit: parseInt( e.qtn ),
					unit_price: 0,
					isSlidingScale: false,
					isQuantityModifier: true,
					value: 0,
					numeric_value: 0,
				},
			};
		}
		if ( e.dropdownTitle && e.showTitlePdf == '1' ) {
			return {
				elementTitle: e.dropdownTitle,
				type: 'element_dropdown',
				attr: {
					name: e.dropdownTitle + ': ' + e.name,
					description: e.description,
					showDescriptionOnPdf: e.showDescriptionOnPdf,
					total_price,
					uniqueId: e?.uniqueId,
					unit: parseInt( e.qtn ),
					unit_price,
					value: parseFloat( e.price ),
					woo_commerce: e.woo_commerce,
					numeric_value: parseInt( e.qtn ) * parseFloat( e.price ),
				},
			};
		} else if ( e.dropdownTitle && e.showTitlePdf == '0' ) {
			return {
				elementTitle: e.dropdownTitle,
				type: 'element_dropdown',
				attr: {
					name: e.name,
					description: e.description,
					showDescriptionOnPdf: e.showDescriptionOnPdf,
					total_price,
					uniqueId: e?.uniqueId,
					unit: parseInt( e.qtn ),
					unit_price,
					value: parseFloat( e.price ),
					woo_commerce: e.woo_commerce,
					numeric_value: parseInt( e.qtn ) * parseFloat( e.price ),
				},
			};
		} else if ( e?.type == 'date range' ) {
			return {
				type: 'date_range',
				attr: {
					name: e.name,
					description: e.description,
					total_price,
					uniqueId: e?.uniqueId,
					unit: parseInt( e.qtn ),
					unit_price,
					value: parseFloat( e.price ),
					woo_commerce: e.woo_commerce,
					numeric_value: parseInt( e.qtn ) * parseFloat( e.price ),
				},
			};
		} else if ( e?.type == 'distance' ) {
			return {
				type: 'distance',
				attr: {
					name: e.name,
					description: e.description,
					total_price,
					uniqueId: e?.uniqueId,
					unit: parseFloat( e.qtn ),
					unit_price,
					value: parseFloat( e.price ),
					woo_commerce: e.woo_commerce,
					numeric_value: e.qtn * parseFloat( e.price ),
					addressOfFirstInput: e.addressOfFirstInput,
					addressOfSecondInput: e.addressOfSecondInput,
					distanceUnit: e.distanceUnit,
				},
			};
		} else if ( e?.type == 'checkbox' || e?.type == 'qtn-image' ) {
			return {
				type: 'checkbox',
				attr: {
					name: e.name,
					description: e.description,
					uniqueId: e?.uniqueId,
					showDescriptionOnPdf: e.showDescriptionOnPdf,
					show_image_on_pdf: e.show_image_on_pdf,
					image_url: e.image_url,
					total_price,
					unit: e.qtn,
					unit_price,
					value: parseFloat( e.price ),
					woo_commerce: e.woo_commerce,
					numeric_value: e.qtn * parseFloat( e.price ),
				},
			};
		}
		return {
			type: 'element',
			attr: {
				name: e.name,
				total_price,
				uniqueId: e?.uniqueId,
				unit: parseInt( e.qtn ),
				unit_price,
				value: parseFloat( e.price ),
				woo_commerce: e.woo_commerce,
				numeric_value: parseInt( e.qtn ) * parseFloat( e.price ),
			},
		};
	} );
	// remove empty undefined values from array
	rows = rows.filter( ( e ) => typeof e !== 'undefined' );
	for ( let index = 0; index < rows.length; index++ ) {
		// get the row by index
		const row = rows[ index ];
		// if last row and a section, we remove it
		if ( index + 1 == rows.length && row?.section_title ) {
			delete rows[ index ];
		}
		// if the section is followed by another section, remove the section
		const nextRow = rows[ index + 1 ];
		if ( row?.section_title && nextRow?.section_title ) {
			delete rows[ index ];
		}
	}
	/**
	 * if coupon is not empty, add it as a row
	 */
	if ( Object.keys( coupon ).length ) {
		rows.push( coupon );
	}
	if ( hasTax && ! pdfConfig.turnoffTax ) {
		rows.push(
			{
				type: 'subtotal_tax',
				attr: {
					price: subTotalBeforeTaxText,
					title: sccGetTranslationByKey( calcId, 'Subtotal' ),
					numeric_value: subTotalBeforeTax,
				},
			},
			{
				type: 'tax',
				attr: {
					price: taxAmountText,
					title: sccGetTranslationByKey( calcId, 'TAX' ) + ' ' + taxParcent + '%',
					numeric_value: taxAmount,
				},
			},
		);
	}
	// remove empty undefined values from array
	rows = rows.filter( ( e ) => typeof e !== 'undefined' );
	/**
	 * define total text to send to the pdfjson, detect convertion to so we can include it on the total text,
	 * compatibility with old format
	 */
	var { currency_conversion_mode, scc_rate_label_convertion, priceRangeTotalSettings, currencyCode, currency_conversion_selection: convertedCurrencyLabel } = sccData[ calcId ].config;
	const isPriceRangeActive = 0 !== Number( priceRangeTotalSettings.rangePercent );
	if ( currency_conversion_mode == 'auto_detect' && scc_rate_label_convertion != null ) {
		var rate_label = scc_rate_label_convertion.split( '/' );
		var rate = rate_label[ 0 ];
		var label = rate_label[ 1 ];
		var convertedCurrencyLabel = label;
	}
	if ( scc_rate_label_convertion != null ) {
		var rate_label = scc_rate_label_convertion.split( '/' );
		var rate = rate_label[ 0 ];
		var label = rate_label[ 1 ];
		if ( label != currencyCode && total_converted ) {
			if ( ! isPriceRangeActive ) {
				totalText = total_converted
					? totalText +
				`<span class="df-scc-detailview-cc" style="font-size:70%;display:block"> (${ getCurrencyText(
					calcId,
					total_converted,
					convertedCurrencyLabel,
				) }) </span>`
					: totalText;
			}
			if ( isPriceRangeActive ) {
				// Applying range text to the total value
				const rangeAppliedTotal = sccUtils.applyRangeTotal( parseFloat( total ), priceRangeTotalSettings );
				const originalTotalText = getCurrencyText( calcId, parseFloat( total ) );
				const rangeAppliedTotalText = getCurrencyText( calcId, rangeAppliedTotal );
				const totalTextWithRange = total == 0 ? originalTotalText : originalTotalText + ' - ' + rangeAppliedTotalText;
				// Applying range text to the converted total value
				const rangeAppliedConvertedTotal = sccUtils.applyRangeTotal( parseFloat( total_converted ), priceRangeTotalSettings );
				const originalConvertedTotalText = getCurrencyText( calcId, parseFloat( total_converted ), label );
				const rangeAppliedConvertedTotalText = getCurrencyText( calcId, rangeAppliedConvertedTotal, label );

				totalText = total_converted ? totalTextWithRange + `<span class="df-scc-detailview-cc" style="font-size:70%;display:block"> (${ Number( total_converted ) == 0 ? originalConvertedTotalText : originalConvertedTotalText + ' - ' + rangeAppliedConvertedTotalText }) </span>` : totalTextWithRange;
				sccData[ calcId ].priceRanges = { min: sccData[ calcId ].total, max: rangeAppliedTotal, totalTextWithRange: originalTotalText + ' - ' + rangeAppliedTotalText };
			}
		} else {
			totalText = totalText;
		}
	} else if ( isPriceRangeActive ) {
		// Applying range text to the total value
		const rangeAppliedTotal = sccUtils.applyRangeTotal( parseFloat( total ), priceRangeTotalSettings );
		const originalTotalText = getCurrencyText( calcId, parseFloat( total ) );
		const rangeAppliedTotalText = getCurrencyText( calcId, rangeAppliedTotal );
		const totalTextWithRange = total == 0 ? originalTotalText : originalTotalText + ' - ' + rangeAppliedTotalText;
		totalText = totalTextWithRange;
		sccData[ calcId ].priceRanges = { min: sccData[ calcId ].total, max: rangeAppliedTotal, totalTextWithRange: originalTotalText + ' - ' + rangeAppliedTotalText };
	} else {
		totalText = totalText;
	}
	rows.push( {
		type: 'total',
		attr: {
			price: totalText,
			title: sccGetTranslationByKey( calcId, 'Total Price' ),
			numeric_value: total,
		},
	} );
	/**
	 * To add the values of the items inside a section, we take the index value of the sections
	 * and push the items to that index for later calculations against the items
	 */
	const itemsTosectionIndexMapping = [];
	for ( let index = 0; index < rows.length; index++ ) {
		const row = rows[ index ];
		if ( row ) {
			if (
				row.type == 'section_title' &&
				! itemsTosectionIndexMapping[ index ] &&
				row.showSectionTotalOnPdf
			) {
				itemsTosectionIndexMapping[ index ] = [];
			}
			if (
				row.type == 'section_title' &&
				! itemsTosectionIndexMapping[ index ] &&
				! row.showSectionTotalOnPdf
			) {
				itemsTosectionIndexMapping[ index ] = undefined;
			}
			if (
				( [ 'element', 'element_dropdown', 'checkbox', 'custom_math', 'slider', 'date_range', 'distance', 'math' ].includes( row.type ) ) &&
				itemsTosectionIndexMapping.length
			) {
				const sectionIndex =
					itemsTosectionIndexMapping[
						Object.keys( itemsTosectionIndexMapping ).slice( -1 )[ 0 ]
					];
				if ( sectionIndex ) {
					sectionIndex.push( row );
				}
			}
		}
	}
	/**
	 * Here, the items are calculated and the subtotal value is returned against each index
	 */
	const sectionSubTotals = itemsTosectionIndexMapping.map( ( e ) => {
		let subtotal = 0;
		if ( ! e ) {
			return 0;
		}
		e.forEach( ( ee ) => {
			// add up the custom math value
			if ( ee.attr.element_type == 'custom_math' ) {
				subtotal += ee.attr.numeric_value;
			} else {
				subtotal += ee.attr.isSlidingScale
					? ee.attr.value
					: ee.attr.value * ee.attr?.unit;
			}
		} );
		return subtotal;
	} );
	const sectionSubTotalsKeys = Object.keys( sectionSubTotals );
	let numberOfSkippedSubTotals = 0;
	for ( let index = 0; index < sectionSubTotalsKeys.length; index++ ) {
		const element = sectionSubTotals[ index ];
		if ( sectionSubTotalsKeys[ index + 1 ] ) {
			const targetIndex =
				parseInt( sectionSubTotalsKeys[ index + 1 ] ) +
				( index - numberOfSkippedSubTotals );
			const sectionSubTotal = sectionSubTotals[ sectionSubTotalsKeys[ index ] ];
			// if section subtotal is greater than 0 we add it to the row
			if ( sectionSubTotal > 0 ) {
				const clonedRows = rows.map( ( e ) => e );
				rows = [
					...clonedRows.slice( 0, targetIndex ),
					{
						type: 'section_subtotal',
						attr: {
							name: sccGetTranslationByKey( calcId, 'SubTotal' ),
							total_price: getCurrencyText( calcId, sectionSubTotal.toFixed( 2 ) ),
							unit: '',
							unit_price: '',
						},
					},
					...clonedRows.slice( targetIndex ),
				];
			}
			if ( sectionSubTotal <= 0 ) {
				numberOfSkippedSubTotals += 1;
			}
		} else {
			const totalBeforeTaxIndex = rows.findIndex(
				( e ) => e && e.type == 'subtotal_tax',
			);
			const totalIndex = rows.findIndex( ( e ) => e && e.type == 'total' );
			const targetIndex =
				totalBeforeTaxIndex > 0 ? totalBeforeTaxIndex : totalIndex;
			const sectionSubTotal = sectionSubTotals[ sectionSubTotalsKeys[ index ] ];
			// if section subtotal is greater than 0 we add it to the row
			if ( sectionSubTotal > 0 ) {
				const clonedRows = rows.map( ( e ) => e );
				rows = [
					...clonedRows.slice( 0, targetIndex ),
					{
						type: 'section_subtotal',
						attr: {
							name: sccGetTranslationByKey( calcId, 'SubTotal' ),
							total_price: getCurrencyText( calcId, sectionSubTotal.toFixed( 2 ) ),
							unit: '',
							unit_price: '',
						},
					},
					...clonedRows.slice( targetIndex ),
				];
			}
			//
		}
	}
	return {
		banner_image: pdfConfig.bannerImage,
		date: outputDate( pdfConfig.dateFormat ),
		dateFormat: pdfConfig.dateFormat,
		description: sccGetTranslationByKey( calcId, 'Description' ),
		footer: pdfConfig.footer,
		logo_image: pdfConfig.logo,
		pdf_title: pdfConfig.removeTitle ? '' : formname,
		price: sccGetTranslationByKey( calcId, 'Price' ),
		quantity: sccGetTranslationByKey( calcId, 'Quantity' ),
		unit: sccGetTranslationByKey( calcId, 'Unit Price' ),
		object_color: pdfConfig.objectColor,
		hasTax,
		taxAmount,
		taxParcent,
		subTotalBeforeTax,
		rows,
		total,
	};
}
window.buildDetailViewData = buildDetailViewData;
//handles mandatory items
function verifiedMandatoryItems( calcId, triggerElement = null ) {
	const container = document.querySelector( '#scc_form_' + calcId );
	container.querySelectorAll( '.scc-mandatory-msg' ).forEach( function( e ) {
		e.classList.remove( 'scc-d-flex' );
		e.classList.add( 'scc-hidden' );
	} );
	const o = elementsId4Mandatory( calcId );
	const oppo = getNeededIds( calcId );
	function elementsId4Mandatory( ca ) {
		const ee_i = [];
		ee_i[ calcId ] = [];
		const availableFileUploadElements = sccUtils.getCalcWrapper( calcId ).querySelectorAll( '[data-typeelement="file upload"]' );
		if ( availableFileUploadElements.length > 0 ) {
			availableFileUploadElements.forEach( ( element ) => {
				const fileInputNode = element.querySelector( '[type=file]' );
				if ( fileInputNode.files.length >= 1 ) {
					ee_i[ ca ].push( element.getAttribute( 'id' ).replace( 'ssc-elmt-', '' ) );
				}
			} );
		}
		//verify signature elements
		const availableSignatureElements = sccUtils.getCalcWrapper( calcId ).querySelectorAll( '[data-typeelement="signature box"]' );
		if ( availableSignatureElements.length > 0 ) {
			availableSignatureElements.forEach( ( element ) => {
				const signatureCanvas = element.querySelector( 'canvas.signature-box' );
				if ( signatureCanvas.getContext( '2d' ).getImageData( 0, 0, signatureCanvas.width, signatureCanvas.height ).data.some( ( channel ) => channel !== 0 ) ) {
					ee_i[ ca ].push( element.getAttribute( 'id' ).replace( 'ssc-elmt-', '' ) );
				}
			} );
		}
		itemsArray[ calcId ].forEach( ( iar ) => {
			if ( iar.id_element ) {
				ee_i[ ca ].push( iar.id_element );
			}
			if ( iar.element_id ) {
				ee_i[ ca ].push( iar.element_id );
			}
		} );
		return ee_i;
	}
	//returns mandatory ids that arent in array
	function getNeededIds( ca ) {
		const ids = [];
		ids[ ca ] = [];
		mandatoryElements[ ca ].forEach( ( m1 ) => {
			//check if element is visible, if not visible is not mandatory
			const elementNode = document.querySelector( '#ssc-elmt-' + m1 );
			const e =
				document.querySelector( '#ssc-elmt-' + m1 ).style.display == 'none' ||
				elementNode.classList.contains( 'scc-conditional-hidden' ) ||
				elementNode.classList.contains( 'scc-section-hidden' ) ||
				elementNode.classList.contains( 'scc-d-none' );
			if ( o[ ca ].indexOf( m1 ) == -1 && ! e ) {
				ids[ ca ].push( m1 );
			}
		} );
		return ids;
	}
	//mandatories ids not selected
	for ( let i = 0; i < oppo[ calcId ].length; i++ ) {
		const currentElement = document.querySelector( '#ssc-elmt-' + oppo[ calcId ][ i ] );

		const alertDangerElement = currentElement.querySelector( '.scc-mandatory-msg' );
		if ( alertDangerElement ) {
			alertDangerElement.classList.remove( 'scc-hidden' );
			alertDangerElement.classList.add( 'scc-d-flex' );
			sccUtils.vibrationAnimation( alertDangerElement );
		}

		const nextSiblingElement = currentElement.nextElementSibling;
		if ( nextSiblingElement && nextSiblingElement.classList.contains( 'scc-mandatory-msg' ) ) {
			nextSiblingElement.classList.remove( 'scc-hidden' );
			nextSiblingElement.classList.add( 'scc-d-flex' );
			sccUtils.vibrationAnimation( nextSiblingElement );
			const paginationNextButton = triggerElement;
			if ( paginationNextButton ) {
				sccUtils.vibrationAnimation( paginationNextButton );
			}
		}

		if ( i == 0 ) {
			const offsetTop = currentElement.getBoundingClientRect().top + window.pageYOffset;
			window.scroll( {
				top: offsetTop - 50,
				behavior: 'smooth',
			} );
			if ( window.self !== window.top ) {
				window.parent.postMessage( { action: 'scroll', top: ( offsetTop - 50 ) }, '*' );
			}
		}
	}

	if ( oppo[ calcId ].length > 0 ) {
		return false;
	}

	return true;
}
window.verifiedMandatoryItems = verifiedMandatoryItems;

function mandatory_elements() {
	const mandatories = [];
	Object.keys( sccData ).forEach( function( calcId ) {
		mandatories[ calcId ] = [];
		const { sections } = sccData[ calcId ].config;
		sections.forEach( ( s ) => {
			s.subsection.forEach( ( su ) => {
				su.element.forEach( ( e ) => {
					if ( e.mandatory == '1' ) {
						mandatories[ calcId ].push( e.id );
					}
				} );
			} );
		} );
	} );
	return mandatories;
}
/**
 * *CurrencySymbol is used in slider
 * !currently in slider its called statictly and needs to be loaded from wpoptions
 * !should be implemented in total script, currently is static
 * @param currency
 */
function currencySymbol( currency ) {
	const symbol = scc_currencies.find( ( x ) => x.code == currency ).symbol;
	return symbol ? symbol : currency;
}

/**
 * *Price hint
 * @param calcId
 * @param element
 * @param value
 */
function pricehint( calcId, element, value ) {
	const { currencyCode, useCurrencyLetters, tseparator } = sccData[ calcId ].config;
	const o = element.checked;
	const checkboxType = element.getAttribute( 'data-checkboxtype' );
	const symbole = currencySymbol( currencyCode );
	const plus = '+ ';

	if ( o && value == 1 ) {
		let parentElement = element.parentElement.parentElement;
		if ( checkboxType && checkboxType === '9' ) {
			parentElement = element.parentElement;
		}
		let p = parentElement.querySelector( '.price-hint-text' ).textContent;
		p = parseFloat( p.replace( /\D/g, '' ) );

		if ( tseparator === 'comma' ) {
			p = priceCommaStyler( p );
		} else {
			const hasFractions = parseFloat( p ) - parseInt( p ) > 0;
			if ( hasFractions ) {
				p = parseFloat( p ).toLocaleString( navigator.languages[ 0 ], {
					minimumFractionDigits: 2,
				} );
			}
			if ( ! hasFractions ) {
				p = parseFloat( p ).toLocaleString( navigator.languages[ 0 ] );
			}
		}
		const label = parentElement.querySelector( '.price-hint-text' );
		if ( getComputedStyle( label ).display === 'none' ) {
			if ( ! useCurrencyLetters ) {
				label.textContent = plus + symbole + p;
				label.classList.remove( 'scc-hint-hide' );
				setTimeout( () => {
					label.classList.add( 'scc-hint-hide' );
				}, 3000 );
			}
			if ( useCurrencyLetters ) {
				label.textContent = plus + p + ' ' + currencyCode;
				label.classList.remove( 'scc-hint-hide' );
				setTimeout( () => {
					label.style.display = 'none';
					label.classList.add( 'scc-hint-hide' );
				}, 3000 );
			}
		}
	}
}
window.pricehint = pricehint;
/**
 * *Change qnt of quantity input box when the type is set to compact
 * !sends data to totalScript, id and element
 * @param dom
 * @param type
 * @param idElement
 * @param calcId
 */
function changeNumberQuantity( dom, type, idElement, calcId ) {
	switch ( type ) {
		case '-':
			var input = dom.nextElementSibling;
			var isCommaEnabled = input.getAttribute( 'data-enable-commas' ) == '1';
			var val = Number( input.value.replace( /[^0-9.-]+/g, '' ) );
			if ( ! val ) {
				val = 0;
				input.value = isCommaEnabled ? val.toLocaleString( 'en-US' ) : val;
			}
			if ( val > 0 ) {
				val--;
				input.value = isCommaEnabled ? val.toLocaleString( 'en-US' ) : val;
			}
			var maxValue = Number( input.getAttribute( 'max' ) );
			var minValue = Number( input.getAttribute( 'min' ) );
			var addComma = input.getAttribute( 'data-add-comma' ) == '1';
			var currentValue = Number( input.value );
			if ( maxValue && currentValue > maxValue ) {
				input.value = maxValue;
			}
			if ( minValue && currentValue < minValue ) {
				input.value = minValue;
			}
			addComma && dfSccInputBoxAddCommas( input );
			triggerSubmit( 3, input, idElement, 0, calcId );
			break;
		case '+':
			var input = dom.previousElementSibling;
			var isCommaEnabled = input.getAttribute( 'data-enable-commas' ) == '1';
			var val = Number( input.value.replace( /,/g, '' ) );
			if ( ! val ) {
				val = 0;
				input.value = isCommaEnabled ? val.toLocaleString( 'en-US' ) : val;
			}
			if ( val >= 0 ) {
				val++;
				input.value = isCommaEnabled ? val.toLocaleString( 'en-US' ) : val;
			}
			var maxValue = Number( input.getAttribute( 'max' ) );
			var minValue = Number( input.getAttribute( 'min' ) );
			var currentValue = Number( input.value );
			var addComma = input.getAttribute( 'data-add-comma' ) == '1';
			if ( maxValue && currentValue > maxValue ) {
				input.value = maxValue;
			}
			if ( minValue && currentValue < minValue ) {
				input.value = minValue;
			}
			addComma && dfSccInputBoxAddCommas( input );
			triggerSubmit( 3, input, idElement, 0, calcId );
			break;
	}
}
window.changeNumberQuantity = changeNumberQuantity;
/**
 * *Display sign in amounts in slider
 * !needs to be used in total value, currently static
 * @param price
 */
function priceCommaStyler( price ) {
	const o = Object.keys( sccData )[ 0 ];
	const { tseparator } = sccData[ o ].config;
	let isCommaBasedStyling = false;
	if ( tseparator == 'comma' ) {
		isCommaBasedStyling = true;
	}
	price = price + '';
	if ( isCommaBasedStyling ) {
		const dot = price.indexOf( '.' ) === -1 ? false : true;
		let newPrice = '';
		for ( let i = price.split( '' ).length - 1; i >= 0; i-- ) {
			if ( price.indexOf( '-' ) === -1 ) {
				if (
					! dot &&
					( i === price.split( '' ).length - 1 - 3 ||
						i === price.split( '' ).length - 1 - 6 ||
						i === price.split( '' ).length - 1 - 9 )
				) {
					newPrice = ',' + newPrice;
				} else if (
					dot &&
					( i === price.split( '' ).length - 1 - 6 ||
						i === price.split( '' ).length - 1 - 9 ||
						i === price.split( '' ).length - 1 - 12 )
				) {
					newPrice = ',' + newPrice;
				}
				newPrice = price.split( '' )[ i ] + newPrice;
			} else {
				if (
					! dot &&
					( i === price.split( '' ).length - 1 - 4 ||
						i === price.split( '' ).length - 1 - 6 ||
						i === price.split( '' ).length - 1 - 9 )
				) {
					if ( price.split( '' ).length > 4 ) {
						newPrice = ',' + newPrice;
					}
				} else if (
					dot &&
					( i === price.split( '' ).length - 1 - 6 ||
						i === price.split( '' ).length - 1 - 9 ||
						i === price.split( '' ).length - 1 - 12 )
				) {
					if ( price.split( '' ).length > 7 ) {
						newPrice = ',' + newPrice;
					}
				}
				newPrice = price.split( '' )[ i ] + newPrice;
			}
		}
		if (
			newPrice.length >= 3 &&
			( newPrice.substring( newPrice.length - 3, newPrice.length - 1 ) === '.00' ||
				newPrice.substring( newPrice.length - 3, newPrice.length ) === '.00' )
		) {
			newPrice = newPrice.split( '.' )[ 0 ];
		}
		return newPrice;
	}
	const roundedPrice = parseFloat( parseFloat( price ).toFixed( 2 ) );
	return roundedPrice.toLocaleString( navigator.languages[ 0 ] );
}

function getMinimumTotal( calculatorId ) {
	const { minimumTotal, minimumTotalChoose } = sccData[ calculatorId ].config;
	const container = document.getElementById( 'scc_form_' + calculatorId );
	sccCurrencyFormat( calculatorId );
	if ( minimumTotal && parseInt( minimumTotal ) > 0 ) {
		sccUtils.handleMinimumTotal( container, false, minimumTotalChoose, calculatorId );
	} else {
		const minimumMsgElems = container.querySelectorAll( 'span.scc-minimum-msg' );
		minimumMsgElems.forEach( function( elem ) {
			elem.classList.add( 'scc-hidden' ); // Assuming "scc-hidden" sets "display: none"
		} );
		const buttonElems = container.querySelectorAll( '.scc-btn-hndle:not(.scc-pagination-nav)' );
		buttonElems.forEach( function( elem ) {
			elem.classList.remove( 'scc-hidden' ); // Assuming "scc-hidden" sets "display: none"
		} );
	}
}

function getTranslation_( calculatorID ) {
	const { translation } = sccData[ calculatorID ].config;
	const container = document.querySelector( '#scc_form_' + calculatorID );
	const floatingDetailContainer = document.querySelector( '#scc-detail-' + calculatorID );
	const stickyTotalContainer = document.querySelector( `[data-parent-calc-id="${ calculatorID }"]` );

	const dict = {};
	for ( let i = 0; i < translation.length; i++ ) {
		dict[ translation[ i ].key ] = {
			en: translation[ i ].translation,
		};
	}

	if ( document.readyState === 'loading' ) {
		document.addEventListener( 'DOMContentLoaded', translateToggle );
	} else {
		translateToggle();
	}

	function translateToggle() {
		const translatorContainer = new SccTranslate( {
			lang: 'en',
			t: dict,
		} );
		translatorContainer.translateElements( [ container, floatingDetailContainer, stickyTotalContainer ] );

		translation.forEach( ( t ) => {
			if ( t.key === 'Yes' ) {
				translateYes( t.translation );
			}
			if ( t.key === 'No' ) {
				translateNo( t.translation );
			}
		} );

		function translateYes( value ) {
			container.querySelectorAll( '.can-toggle__switch' ).forEach( ( elem ) => {
				elem.setAttribute( 'data-checked', value );
			} );
		}

		function translateNo( value ) {
			container.querySelectorAll( '.can-toggle__switch' ).forEach( ( elem ) => {
				elem.setAttribute( 'data-unchecked', value );
			} );
		}
	}
}

/**
 * *Changes the apearence of checkboxbuttons
 * !currently the color is static, needs to be taken from calculator settings
 * @param element
 */
function changeButtonToogle( element ) {
	const s = element.checked;
	const elementCheckbox = element.getAttribute( 'data-checkboxtype' );
	const elementType = element.type;
	if ( elementCheckbox && elementCheckbox === '6' && elementType === 'radio' ) {
		const container = element.closest( '.scc-simple-button-container' );
		container.querySelectorAll( '.simple_button label' ).forEach( function( e ) {
			e.classList.remove( 'clicked-buttom-checkbox' );
		} );
	}
	if ( s ) {
		element.parentElement.classList.add( 'clicked-buttom-checkbox' );
	} else {
		element.parentElement.classList.remove( 'clicked-buttom-checkbox' );
	}
}
window.changeButtonToogle = changeButtonToogle;
/**
 * Changes the selected / non-selected status of radio toggle switch
 * @param element
 */
function changeRadioToogleSwitch( element ) {
	const checkd = element.checked;
	const container = element.closest( '.scc-radio-toggle-container' );
	container.querySelectorAll( '.scc-radio-toggle-label' ).forEach( function( e ) {
		e.classList.remove( 'scc-clicked-radio-toggle' );
	} );
	if ( checkd ) {
		element.parentElement.classList.add( 'scc-clicked-radio-toggle' );
	} else {
		element.parentElement.classList.remove( 'scc-clicked-radio-toggle' );
	}
}
window.changeRadioToogleSwitch = changeRadioToogleSwitch;
/**user action buttons width */
function actionButtons() {
	window.addEventListener( 'resize', function() {
		resize();
	} );
	resize();
	function resize() {
		const calWraper = document.querySelector( '.calc-wrapper' )?.offsetWidth;
		if ( calWraper < 412 ) {
			document
				.querySelectorAll( '.scc-btn-hndle .scc-usr-act-btns-container' )
				.forEach( function( e ) {
					e.classList.remove( 'auto' );
				} );
		} else {
			document
				.querySelectorAll( '.scc-btn-hndle .scc-usr-act-btns-container' )
				.forEach( function( e ) {
					e.classList.add( 'auto' );
				} );
		}
	}
}
window.actionButtons = actionButtons;

/**
 * *Setting up the tooltips
 * @param calcId
 */
function sccSetupTooltips( calcId ) {
	const calcWrapper = document.querySelector( '#scc_form_' + calcId );
	const tooltips = calcWrapper.querySelectorAll( '.scc-tooltip2' );
	const tooltipTemplateNode = document.querySelector( '#scc-tooltip' );
	tooltips.forEach( ( tooltip ) => {
		sccUtils.registerTooltips( tooltip, tooltipTemplateNode, calcId );
	} );
}
window.sccSetupTooltips = sccSetupTooltips;
/**
 * *Added admin top menu
 */
// self executing function
( function() {
	// wait for the document to be loaded
	document.addEventListener( 'DOMContentLoaded', function() {
		const o = document
			.querySelector( '[id^=scc_form_]' )
			?.getAttribute( 'id' )
			.split( '_' )[ 2 ];
		if ( o ) {
			const cont = document.getElementById( 'wp-admin-bar-root-default' );
			const li = document.createElement( 'li' );
			li.setAttribute( 'id', 'wp-admin-bar-scc-edit-calculator' );
			const a = document.createElement( 'a' );
			a.classList.add( 'ab-item' );
			a.innerHTML = 'Edit Stylish Cost Calculator';
			a.style.cursor = 'pointer';
			li.appendChild( a );
			cont?.appendChild( li );
			li.addEventListener( 'click', function() {
				const o = document
					.querySelector( '[id^=scc_form_]' )
					?.getAttribute( 'id' )
					.split( '_' )[ 2 ];
				window.location.href = url.urlcalculator + o;
			} );
		}
	} );
}() );

function sccShowCalculator( calcId = null ) {
	document.querySelectorAll( '.scc-glass-filter' ).forEach( ( node ) => {
		node.classList.add( 'scc-d-none' );
	} );

	const sccDashboard = document.querySelector( '#settings-tabs-wrapper' );
	const sccElementorChecker = document.querySelector( '.elementor-editor-active' );
	if ( sccDashboard || sccElementorChecker ) {
		const sccGlassFilter = document.querySelector( '.scc-glass-filter' );
		if ( sccGlassFilter ) {
			sccGlassFilter.classList.add( 'scc-d-none' );
		}
	}
}

function sccCurrencyFormat( calcId ) {
	if ( typeof scc_currencies !== 'undefined' ) {
		const calculators = document.querySelectorAll( '.calc-wrapper' );
		calculators.forEach( ( calculator ) => {
			const valueFormats = calculator.querySelectorAll( '.scc-currency-format-' + calcId );
			// Convert NodeList to an array
			const nodesArray = Array.from( valueFormats );
			// Now loop through the static array
			nodesArray.forEach( ( node ) => {
				const originalText = node.innerHTML;
				if ( isNaN( parseFloat( originalText ) ) ) {
					return;
				}
				node.innerHTML = getCurrencyText( calcId, parseFloat( originalText ) );
			} );
		} );
	}
}

function sortButtonsByPriority( formId ) {
	const buttonGroup = document.querySelector( '#scc_form_' + formId + ' .scc-cta-btns-group' );
	document.querySelectorAll( '#scc_form_' + formId + ' .scc-secondary-btn-container' ).forEach( ( node ) => {
		buttonGroup.appendChild( node );
	} );
}
window.sortButtonsByPriority = sortButtonsByPriority;

function sccClearButtonPlugin( pluginConfig, calcId ) {
	const btnLabel = sccGetTranslationByKey( calcId, 'Clear' );
	const defaultConfig = {
		clear: btnLabel,
	};

	// the config object used to configure this instance of the plugin
	const config = Object.assign( {}, defaultConfig, pluginConfig );

	return function( fp ) {
		let clearButton;

		function onReady() {
			clearButton = fp._createElement( 'div', 'scc-flatpickr-clear ' + config.class, config.clear );
			clearButton.classList.add( 'trn' );
			clearButton.setAttribute( 'data-trn-key', 'Clear' );
			clearButton.addEventListener( 'click', fp.clear );

			fp.calendarContainer.appendChild( clearButton );
		}

		return {
			onReady,
		};
	};
}
function sccCloseButtonPlugin( pluginConfig, calcId ) {
	const btnLabel = sccGetTranslationByKey( calcId, 'Close' );
	const defaultConfig = {
		label: btnLabel,
	};

	const config = Object.assign( {}, defaultConfig, pluginConfig );

	return function( fp ) {
		let closeButton;

		function onReady() {
			closeButton = fp._createElement( 'div', 'scc-flatpickr-close', config.label );
			closeButton.classList.add( 'trn' );
			closeButton.setAttribute( 'data-trn-key', 'Close' );
			closeButton.addEventListener( 'click', function() {
				fp.close();
			} );

			fp.calendarContainer.appendChild( closeButton );
		}

		return {
			onReady,
		};
	};
}

function sccFlatpickrFormatDate( originalDateFormat ) {
	return originalDateFormat
		.replace( /dd/g, 'd' )
		.replace( /mm/g, 'm' )
		.replace( /yyyy/g, 'Y' )
		.replace( /YY/g, 'y' );
}

function sccFlatpickrInit( formId ) {
	const sccDatePickers = document.querySelectorAll( '.scc-datepicker' );
	sccDatePickers.forEach( ( e ) => {
		let dateFormat = sccData[ formId ]?.config?.pdf?.dateFormat ?? 'yyyy-mm-dd';

		dateFormat = sccFlatpickrFormatDate( dateFormat );
		let minDate = ( e.getAttribute( 'min' ) === 'today' ) ? e.getAttribute( 'min' ) : sccFormatStringDate( e.getAttribute( 'min' ), dateFormat );
		const maxDate = ( e.getAttribute( 'max' ) === 'today' ) ? e.getAttribute( 'max' ) : sccFormatStringDate( e.getAttribute( 'max' ), dateFormat );

		const defaultValue = e.value;
		const disabledDate = e.getAttribute( 'data-disabled-dates' );
		const disablePastDays = Number( e.getAttribute( 'data-disable-past-days' ) );
		const enableLimitDays = e.getAttribute( 'data-enable_limit_days' );
		let includeDays = e.getAttribute( 'data-include-days' );
		const dayToNumber = {
			Sun: 0,
			Mon: 1,
			Tue: 2,
			Wed: 3,
			Thu: 4,
			Fri: 5,
			Sat: 6,
		};

		if ( includeDays ) {
			includeDays = includeDays.split( ', ' ).map( ( day ) => dayToNumber[ day ] );
		}

		if ( disablePastDays ) {
			minDate = 'today';
		}

		const rawDisabledDatesArray = disabledDate.split( ', ' );
		const formattedDisabledDatesArray = rawDisabledDatesArray.map( ( dateStr ) => sccFormatStringDate( dateStr, dateFormat ) );

		if ( enableLimitDays && includeDays ) {
			formattedDisabledDatesArray.push( function( date ) {
				return ! includeDays.includes( date.getDay() );
			} );
		}

		const lang = ( ( navigator.language || navigator.userLanguage ) || '' ).substr( 0, 2 );
		const timepicker = e.nextElementSibling;
		// Validate the parsed date parts to fit with flatpicker format
		const picker = new flatpickr( e, {
			dateFormat,
			locale: lang,
			minDate,
        	maxDate,
			disable: formattedDisabledDatesArray, //disabledDatesArray,
			plugins: [ sccCloseButtonPlugin( {}, formId ), sccClearButtonPlugin( {}, formId ) ],
			disableMobile: true,
			onChange: ( selectedDates, dateStr, instance ) => {
				e.value = dateStr;
				const formId = e.getAttribute( 'data-formid' );
				const elementId = e.getAttribute( 'data-elementid' );
				const elementType = parseInt( e.getAttribute( 'data-elementtype' ) );

				if ( timepicker && timepicker.classList.contains( 'time-input' ) ) {
					timepicker.value = '';
					// reset the tomselect in timepicker
					timepicker?.tomselect && timepicker.tomselect.clear();
				}

				triggerSubmit( elementType, e, elementId, 0, formId );
			},
		} );
		e.readOnly = true;
		if ( defaultValue ) {
			picker.setDate( defaultValue, true, 'Y-m-d' );
		}
		picker.calendarContainer.classList.add( 'scc-flatpickr-' + formId );

		e.addEventListener( 'sccMinChanged', function() {
			try {
				const newMin = sccFormatStringDate( e.getAttribute( 'min' ), dateFormat );
				if ( typeof picker !== 'undefined' ) {
					picker.set( 'minDate', newMin );
				}
			} catch ( error ) {
				//console.log( error );
			}
		} );
		if ( timepicker && timepicker.classList.contains( 'time-input' ) ) {
			timepicker.addEventListener( 'change', function( { target } ) {
				const timeValue = target.value;
				const formId = e.getAttribute( 'data-formid' );
				const elementId = e.getAttribute( 'data-elementid' );
				const elementType = parseInt( e.getAttribute( 'data-elementtype' ) );

				triggerSubmit( elementType, e, elementId, 0, formId, { isTime: true, timeValue } );
			} );
		}
	} );
}
window.sccFlatpickrInit = sccFlatpickrInit;

function sccFlatpickrModalInit( formId ) {
	const sccDatePickers = document.querySelectorAll( '.scc-datepicker-modal' );
	sccDatePickers.forEach( ( e ) => {
		const originalDateFormat = sccData[ formId ]?.config?.pdf?.dateFormat ?? 'yyyy-mm-dd';
		const dateFormat = sccFlatpickrFormatDate( originalDateFormat );

		const defaultValue = e.value;

		const lang = ( navigator.language || navigator.userLanguage ).substr( 0, 2 );
		// Validate the parsed date parts to fit with flatpicker format

		const picker = new flatpickr( e, {
			dateFormat,
			locale: lang,
			plugins: [ sccCloseButtonPlugin( {}, formId ), sccClearButtonPlugin( {}, formId ) ],
			disableMobile: true,
			onChange: ( selectedDates, dateStr, instance ) => {
				e.value = dateStr;
			},
		} );
		e.readOnly = true;
		if ( defaultValue ) {
			picker.setDate( defaultValue, true, 'Y-m-d' );
		}
		//add placeholder to element
		e.setAttribute( 'placeholder', originalDateFormat );

		const timepicker = e.nextElementSibling;
		if ( timepicker && timepicker.classList.contains( 'time-input' ) ) {
			timepicker.addEventListener( 'change', function( { target } ) {
				const timeValue = target.value;
				const formId = e.getAttribute( 'data-formid' );
				const elementId = e.getAttribute( 'data-elementid' );
				const elementType = parseInt( e.getAttribute( 'data-elementtype' ) );

				triggerSubmit( elementType, e, elementId, 0, formId, { isTime: true, timeValue } );
			} );
		}

		picker.calendarContainer.classList.add( 'scc-flatpickr-' + formId );
	} );
}
window.sccFlatpickrModalInit = sccFlatpickrModalInit;

window.sccInitTimePicker = ( formId ) => {
	const sccTimePickers = document.querySelectorAll( '.time-input' );
	sccTimePickers.forEach( ( e ) => {
		const { timeFormat, startTwelveHourHour, startTwelveHourMinutes, startAmPm,
			endTwelveHourHour, endTwelveHourMinutes, timeInterval, endAmPm, startTwentyFourHourHour,
			startTwentyFourHourMinutes, endTwentyFourHourHour, endTwentyFourHourMinutes,
			elementId, elementType, formId,
			twelveHourHour, twelveHourMinutes, twentyFourHourHour, twentyFourHourMinutes } = e.dataset;
		function generateTimeArray24h( startHour, startMinutes, endHour, endMinutes, intervalMinutes ) {
			const times = [];
			let currentHour = parseInt( startHour );
			let currentMinutes = parseInt( startMinutes );
			const endTotalMinutes = parseInt( endHour ) * 60 + parseInt( endMinutes );

			while ( ( currentHour * 60 + currentMinutes ) <= endTotalMinutes ) {
			  times.push( `${ String( currentHour ).padStart( 2, '0' ) }:${ String( currentMinutes ).padStart( 2, '0' ) }` );
			  currentMinutes += intervalMinutes;
			  if ( currentMinutes >= 60 ) {
					currentHour += Math.floor( currentMinutes / 60 );
					currentMinutes %= 60;
			  }
			}
			return times;
		  }

		  function convertTo24HourFormat( hour, minutes, amPm ) {
			hour = parseInt( hour );
			minutes = parseInt( minutes );
			if ( amPm === 'PM' && hour !== 12 ) {
			  hour += 12;
			}
			if ( amPm === 'AM' && hour === 12 ) {
			  hour = 0;
			}
			return { hour, minutes };
		  }

		  function generateTimeArray12h( startHour, startMinutes, startAmPm, endHour, endMinutes, endAmPm, intervalMinutes ) {
			const times = [];
			let { hour: currentHour, minutes: currentMinutes } = convertTo24HourFormat( startHour, startMinutes, startAmPm );
			const { hour: endHour24, minutes: endMinutes24 } = convertTo24HourFormat( endHour, endMinutes, endAmPm );
			const endTotalMinutes = endHour24 * 60 + endMinutes24;

			while ( ( currentHour * 60 + currentMinutes ) <= endTotalMinutes ) {
			  const displayHour = currentHour % 12 === 0 ? 12 : currentHour % 12;
			  const displayAmPm = currentHour < 12 || currentHour === 24 ? 'AM' : 'PM';
			  times.push( `${ displayHour }:${ String( currentMinutes ).padStart( 2, '0' ) } ${ displayAmPm }` );
			  currentMinutes += intervalMinutes;
			  if ( currentMinutes >= 60 ) {
					currentHour += Math.floor( currentMinutes / 60 );
					currentMinutes %= 60;
			  }
			}

			return times;
		  }

		  const timeIntervalMinutes = parseInt( timeInterval );

		  if ( timeFormat === '24h' ) {
			const times24h = generateTimeArray24h(
			  startTwentyFourHourHour,
			  startTwentyFourHourMinutes,
			  endTwentyFourHourHour,
			  endTwentyFourHourMinutes,
			  timeIntervalMinutes,
			);
			const options = times24h.map( ( time ) => ( { value: time, text: time } ) );
			const twelveHourTomSelect = new TomSelect( e, {
				options,
				maxItems: null,
				create: false,
				maxItems: 1,
				maxOptions: 'null',
				hideSelected: true,
				controlInput: false,
			} );
			twelveHourTomSelect.on( 'item_add', function() {
				twelveHourTomSelect.blur();
			} );
		  } else if ( timeFormat === '12h' ) {
			const times12h = generateTimeArray12h(
			  startTwelveHourHour,
			  startTwelveHourMinutes,
			  startAmPm,
			  endTwelveHourHour,
			  endTwelveHourMinutes,
			  endAmPm,
			  timeIntervalMinutes,
			);
			const options = times12h.map( ( time ) => ( { value: time, text: time } ) );
			const twentyFourHourTomSelect = new TomSelect( e, {
				options,
				maxItems: null,
				create: false,
				maxItems: 1,
				maxOptions: 'null',
				hideSelected: true,
				controlInput: false,
			} );
			twentyFourHourTomSelect.on( 'item_add', function() {
				twentyFourHourTomSelect.blur();
			} );
		  }
	} );
};

function sccToggleCheckbox( elm, idElm, selectionType ) {
	if ( selectionType != '1' ) {
		document.querySelectorAll( '#ssc-elmt-' + idElm + ' input' ).forEach( function( checkbox ) {
			if ( checkbox !== elm ) {
				checkbox.checked = false;
			}
		} );
		document.querySelectorAll( '#ssc-elmt-' + idElm + ' input[type="radio"]' ).forEach( function( checkbox ) {
			if ( checkbox !== elm ) {
				checkbox.checked = false;
			}
		} );
	}
}
window.sccToggleCheckbox = sccToggleCheckbox;

