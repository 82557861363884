class SccTranslate {
	constructor( options = {} ) {
		this.settings = {
			css: 'trn',
			lang: 'en',
			t: {},
		};

		Object.assign( this.settings, options );

		if ( this.settings.css.charAt( 0 ) !== '.' ) {
			this.settings.css = '.' + this.settings.css;
		}

		this.t = this.settings.t;
		this.translateElements( document.querySelectorAll( this.settings.css ) );
	}

	lang( l ) {
		if ( l ) {
			this.settings.lang = l;
			this.translateElements( document.querySelectorAll( this.settings.css ) ); // translate everything
		}

		return this.settings.lang;
	}

	get( index ) {
		let res = index;
		let translated = false;

		try {
			const matchedIndexCaseInsensitive = Object.keys( this.t ).find( ( key ) => key.toLowerCase() === index.toLowerCase() );
			res = this.t[ matchedIndexCaseInsensitive ][ this.settings.lang ];
			translated = true;
		} catch ( err ) {
			return { string: ( index ), translated }; // not found, return index
		}

		return { string: ( res ? res : index ), translated };
	}

	translateElements( elements ) {
		elements.forEach( ( element ) => {
			if ( element ) {
				const trn_key = element.getAttribute( 'data-trn-key' );

				if ( trn_key ) {
					let { string, translated } = this.get( trn_key );
					element.innerHTML = string;
					if ( translated ) {
						element.setAttribute( 'data-translated', 'true' );
					}
				}
			}
		} );
	}
}
export default SccTranslate;
